import httpClient from "@api/http";
import { BodyEntity } from "@entity/body.entity";

class BodyRepository {
  async findAllByUserId(userId: string): Promise<BodyEntity[]> {
    const response = await httpClient.get(`/body/admin?userId=${userId}`);
    return response.data;
  }

  async findAllByUserIdAndDateBetween(
    userId: string,
    startDate: string,
    endDate: string
  ): Promise<BodyEntity[]> {
    const response = await httpClient.get(
      `/body/admin/${userId}?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  }
}

export default new BodyRepository();
