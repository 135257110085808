import { Role } from "@entity/user.entity";

export function makeRankByRole(role: Role) {
  switch (role) {
    case Role.ADMIN:
      return "관리자";
    case Role.COACH:
      return "코치";
    default:
      return "멤버";
  }
}
