export const BANNER_INFO = [
  {
    COLUMN: "순번",
    WIDTH: 25,
  },
  {
    COLUMN: "제목",
    WIDTH: 75,
  },
];
