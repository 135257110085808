import RowBox from "@component/common/RowBox";
import SmallText from "@component/common/SmallText";
import Text from "@component/common/Text";
import { useValue } from "@hook/useValue";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ChallengeHistoryAdminDto } from "@entity/challenge.entity";
import { formatDateToYYYYMMDD } from "@util/date";

export default function SignUpHistoryComponent({
  data,
}: {
  data: ChallengeHistoryAdminDto;
}) {
  const { value, onChangeValue } = useValue(false);
  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "4px",
        marginTop: "12px",
        borderBottom: "1px solid #F5F5F5",

        display: "flex",
        flexDirection: "column",
      }}
    >
      <SmallText>
        {formatDateToYYYYMMDD(data.startDate, "-")} ~{" "}
        {formatDateToYYYYMMDD(data.endDate, "-")}
      </SmallText>
      <RowBox
        style={{ marginBottom: "16px" }}
        justifyContent="space-between"
        height="auto"
        marginTop={8}
      >
        <Text width="auto">{data.title}</Text>
        {value ? (
          <KeyboardArrowUpIcon
            onClick={() => onChangeValue(!value)}
            sx={{
              fontSize: 16,
              color: "#323232",
              cursor: "pointer",
              margin: 0,
              padding: 0,
            }}
          />
        ) : (
          <KeyboardArrowDownIcon
            onClick={() => onChangeValue(!value)}
            sx={{
              fontSize: 16,
              color: "#323232",
              cursor: "pointer",
              margin: 0,
              padding: 0,
            }}
          />
        )}
      </RowBox>
      {value && (
        <>
          <HistoryRanking
            title="중간 순위"
            content={data.midRank ? `${data.midRank}등` : null}
          />
          <HistoryRanking
            title="최종 순위"
            content={data.midRank ? `${data.finalRank}등` : null}
          />
          <HistoryRanking
            title="중간 점수"
            content={data.midRank ? `${data.midScore}점` : null}
          />
          <HistoryRanking
            title="최종 점수"
            content={data.midRank ? `${data.finalScore}점` : null}
          />
        </>
      )}
    </div>
  );
}

function HistoryRanking({
  title,
  content,
}: {
  title: string;
  content: string | null;
}) {
  return (
    <>
      <RowBox justifyContent="space-between" height="auto" marginTop={4}>
        <SmallText>{title}</SmallText>
        <SmallText>{content ? content : "-"}</SmallText>
      </RowBox>
    </>
  );
}
