export const CHALLENGE_PROVE_INFO = [
  {
    COLUMN: "참여자명",
    WIDTH: 15,
  },
  {
    COLUMN: "회원번호",
    WIDTH: 15,
  },
  {
    COLUMN: "현재 상태",
    WIDTH: 15,
  },
  {
    COLUMN: "미션 제출 시점",
    WIDTH: 15,
  },
  {
    COLUMN: "공감수/댓글수",
    WIDTH: 20,
  },
  {
    COLUMN: "체중/근육량/체지방량/체지방률",
    WIDTH: 20,
  },
];
