import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function MemberProfile({ src }: { src?: string }) {
  return (
    <>
      {src && src.length > 0 ? (
        <img
          src={src}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            backgroundColor: "#222222",
          }}
          alt="profile"
        />
      ) : (
        <AccountCircleIcon
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            color: "#eeeeee",
          }}
        />
      )}
    </>
  );
}
