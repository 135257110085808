import TableColumn from "@component/common/TableColumn";
import TableHeader from "@component/common/TableHeader";

export default function SignUpColumn() {
  return (
    <TableHeader>
      {["이름", "회원번호", "성별", "생년월일", "등급", "담당코치"].map(
        (item, index) => {
          return (
            <TableColumn key={index} width={14.5}>
              {item}
            </TableColumn>
          );
        }
      )}
    </TableHeader>
  );
}
