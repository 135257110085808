import React from "react";
import Popper from "@mui/material/Popper";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import PopperText from "@component/common/PopperText";
import { useSetRecoilState } from "recoil";
import { usePopper } from "@hook/usePopper";
import {
  ChallengeParticipantModal,
  challengeParticipantModal,
  selectedChallengeParticipant,
} from "@store/atom/challenge-participant";
import { ChallengeParticipationAdminDto } from "@interface/challenge-participant";

export default function ChallengeParticipantPopper({
  data,
}: {
  data: ChallengeParticipationAdminDto;
}) {
  const { anchorEl, setAnchorEl, open, id, handleClick } = usePopper();
  const setChallengeParticipantModal = useSetRecoilState(
    challengeParticipantModal
  );
  const setChallengeParticipant = useSetRecoilState(
    selectedChallengeParticipant
  );

  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClick(event);
    if (open) {
      setChallengeParticipantModal(null);
      setChallengeParticipant(null);
    }
  };

  const onMemberClick = () => {
    setAnchorEl(null);
    setChallengeParticipant(data);
    setChallengeParticipantModal(ChallengeParticipantModal.MEMBER);
  };

  const onMissionClick = () => {
    setAnchorEl(null);
    setChallengeParticipant(data);
    setChallengeParticipantModal(ChallengeParticipantModal.MISSION);
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleModalClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <PopperText onClick={onMemberClick}>회원정보 확인</PopperText>
        <PopperText onClick={onMissionClick}>상세 미션 참여 내역</PopperText>
      </Popper>
    </div>
  );
}
