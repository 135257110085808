import { useLocation } from "react-router-dom";
import Container from "@component/common/Container";
import useAuth from "@hook/useAuth";
import { useEffect } from "react";
import ChallengeMissionColumn from "@component/challenge-mission/ChallengeMissionColumn";
import ChallengeMissionOption from "@component/challenge-mission/ChallengeMissionOption";
import ChallengeMissionContent from "@component/challenge-mission/ChallengeMissionContent";

export default function ChallengeMission() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title");
  const { checkAccessToken } = useAuth();

  useEffect(() => {
    checkAccessToken();
  }, []);
  return (
    <>
      <Container
        title={title || "챌린지 미션 관리"}
        option={<ChallengeMissionOption />}
        children={<ChallengeMissionContent />}
        column={<ChallengeMissionColumn />}
      />
    </>
  );
}
