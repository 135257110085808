import ButtonText from "@component/common/ButtonText";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import { ModalTitle } from "@component/common/ModalText";
import RowBox from "@component/common/RowBox";
import { StandardImage } from "@component/common/StandardImage";
import StandardTextArea from "@component/common/StandardTextArea";
import { FoodCategory, FoodEntity } from "@entity/food.entity";
import { selectedFoodData } from "@store/atom/signUp";
import { formatDateToYYYYMMDD } from "@util/date";
import { useRecoilState } from "recoil";

export default function SignUpFoodModal() {
  const [food, setFood] = useRecoilState<FoodEntity | null>(selectedFoodData);
  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>
          {formatDateToYYYYMMDD(food?.historyDate, "-")}{" "}
          {determineFoodCategory(food?.category)} 상세
        </ModalTitle>
        <ModalComponent
          marginBottom={32}
          description="메모 내용"
          component={
            <StandardTextArea disabled value={food?.memo} height={120} />
          }
        />
        <ModalComponent
          marginBottom={32}
          description="사진"
          component={
            <RowBox justifyContent="flex-start">
              {food
                ? food.urls.length > 0
                  ? food?.urls.map((image, index) => {
                      return (
                        <StandardImage
                          onClick={() => {
                            var newTab = window.open();
                            if (newTab) {
                              newTab.document.body.innerHTML = `<img src="${image.url}" height="100%" />`;
                            }
                          }}
                          key={index}
                          src={image.url}
                          alt="image"
                        />
                      );
                    })
                  : "사진이 없습니다."
                : "사진이 없습니다."}
            </RowBox>
          }
        />
        <RowBox height="auto">
          <ButtonText
            onClick={() => {
              setFood(null);
            }}
            color="#FFFFFF"
            backgroundColor="#84C64A"
          >
            닫기
          </ButtonText>
        </RowBox>
      </ModalBox>
    </>
  );
}

function determineFoodCategory(category: FoodCategory | undefined) {
  switch (category) {
    case FoodCategory.BREAKFAST:
      return "아침";
    case FoodCategory.LUNCH:
      return "점심";
    case FoodCategory.DINNER:
      return "저녁";
    case FoodCategory.SNACK:
      return "간식";
    case FoodCategory.WATER:
      return "수분";
    case FoodCategory.NUTRIENT:
      return "영양제품";
    default:
      return "기타";
  }
}
