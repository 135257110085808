import { SignUpColumn } from "@interface/signup";
import { makeNumberIntoString } from "@util/make-number-into-string";

export const SIGN_UP_INFO_COLUMN = {
  BODY: [
    { id: "date", label: "일자", minWidth: 100 },
    {
      id: "weight",
      label: "체중",
      minWidth: 100,
      format: (value: number) => makeNumberIntoString(value, "kg"),
    },
    {
      id: "muscle",
      label: "근육량",
      minWidth: 100,
      format: (value: number) => makeNumberIntoString(value, "kg"),
    },
    {
      id: "fat",
      label: "체지방량",
      minWidth: 100,
      format: (value: number) => makeNumberIntoString(value, "kg"),
    },
    {
      id: "fatRate",
      label: "체지방률",
      minWidth: 100,
      format: (value: number) => makeNumberIntoString(value, "%"),
    },
  ] as SignUpColumn[],
  MEAL: [
    { id: "date", label: "일자", minWidth: 100 },
    { id: "breakfast", label: "아침", minWidth: 100 },
    { id: "lunch", label: "점심", minWidth: 100 },
    { id: "dinner", label: "저녁", minWidth: 100 },
    { id: "snack", label: "간식", minWidth: 100 },
    { id: "water", label: "수분", minWidth: 100 },
    { id: "nutrient", label: "영양제품", minWidth: 100 },
  ],
  ACTIVITY: [
    { id: "date", label: "일자", minWidth: 100 },
    { id: "result", label: "활동 결과", minWidth: 50 },
    { id: "name", label: "활동 이름", minWidth: 100 },
    { id: "time", label: "활동 시간", minWidth: 100 },
    { id: "memo", label: "활동 메모", minWidth: 200 },
  ],
};
