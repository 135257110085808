import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import PopperText from "@component/common/PopperText";
import userRepository from "@api/user/user.repository";
import { Role } from "@entity/user.entity";
import { useRecoilState } from "recoil";
import { approvePopper } from "@store/atom/approve";
import { usePopper } from "@hook/usePopper";

export default function ApprovePopper({ userId }: { userId: string }) {
  const { anchorEl, setAnchorEl, open, id, handleClick } = usePopper();
  const [popper, setPopper] = useRecoilState(approvePopper);

  const onSignUpApprove = async () => {
    await userRepository.changeUserRole(userId, Role.USER_APPROVED);
    setAnchorEl(null);
    setPopper(!popper);
  };

  const onSignUpDelete = async () => {
    await userRepository.deleteUser(userId);
    setAnchorEl(null);
    setPopper(!popper);
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <PopperText onClick={onSignUpApprove}>가입 승인</PopperText>
        <PopperText onClick={onSignUpDelete}>삭제하기</PopperText>
      </Popper>
    </div>
  );
}
