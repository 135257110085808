import ButtonText from "@component/common/ButtonText";
import ModalBox from "@component/common/ModalBox";
import RowBox from "@component/common/RowBox";
import styled from "styled-components";

interface BannerDeleteModalProps {
  onClosed: () => void;
  onDeleted: () => void;
}

export default function BannerDeleteModal({
  onClosed,
  onDeleted,
}: BannerDeleteModalProps) {
  return (
    <ModalBox width={480}>
      <Text>배너를 삭제하시겠습니까?</Text>
      <RowBox
        height="auto"
        style={{ justifyContent: "flex-end" }}
        marginTop={24}
      >
        <ButtonText
          onClick={onClosed}
          color="#8D8D8D"
          backgroundColor="#F5F5F5"
        >
          취소
        </ButtonText>
        <ButtonText
          onClick={onDeleted}
          color="#FFFFFF"
          backgroundColor="#84C64A"
        >
          삭제
        </ButtonText>
      </RowBox>
    </ModalBox>
  );
}

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #323232;
`;
