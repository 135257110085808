import httpClient from "@api/http";
import { CommentEntity } from "@entity/comment.entity";

class CommentRepository {
  async createComment(
    communityId: string,
    content: string
  ): Promise<CommentEntity> {
    return await httpClient.post("/comment/admin", {
      communityId,
      content,
    });
  }

  async putComment(
    communityId: string,
    content: string
  ): Promise<CommentEntity> {
    return await httpClient.put(`/comment/admin`, {
      communityId,
      content,
    });
  }
}

export default new CommentRepository();
