import { CommentEntity } from "./comment.entity";

export enum CommunityType {
  NONE = "NONE",
  TOTAL = "TOTAL",
  HOT = "HOT",
  TODAY_MAGAZINE = "TODAY_MAGAZINE",
  DIET_STORY = "DIET_STORY",
  SUCCESS_STORY = "SUCCESS_STORY",
  NOTICE = "NOTICE",
  NUTRIENT_STORY = "NUTRIENT_STORY",
  QNA = "QNA",
}

export interface CommunityData {
  id: string;
  username: string;
  memberCode: number;
  title: string;
  content: string;
  communityType: CommunityType;
  likeCount?: number;
  scrapCount?: number;
  pictures: string[];
  comments: CommentEntity[];
  createdDate: Date;
}

export interface PutCommunityData {
  title: string;
  content: string;
  communityType: CommunityType;
  pictures: any[];
}
