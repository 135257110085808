import TableBody from "@component/common/TableBody";
import TableRow from "@component/common/TableRow";
import TableText from "@component/common/TableText";
import challengeProveRepository from "@api/challenge/challenge-prove.repository";
import ChallengeProvePopper from "./ChallengeProvePopper";

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ChallengeProveListByMissionAdminResponseDto } from "@interface/challenge-prove";
import { CHALLENGE_PROVE_INFO } from "@constant/CHALLENGE_PROVE_INFO";
import { formatDateToYYYYMMDDHHMM } from "@util/date";
import { useRecoilValue } from "recoil";
import { selectedProve } from "@store/atom/challenge-prove";

export default function ChallengeProveContent() {
  const location = useLocation();
  const missionId = location.pathname ? location.pathname.split("/")[3] : null;
  const proveId = useRecoilValue(selectedProve);
  const [proves, setProves] = useState<
    ChallengeProveListByMissionAdminResponseDto[]
  >([]);

  useEffect(() => {
    if (missionId) {
      challengeProveRepository
        .findAllChallengeProveListByAdmin(missionId)
        .then((res) => {
          setProves(res);
        });
    }
  }, [proveId]);
  return (
    <>
      <TableBody>
        {proves.map((prove, i) => {
          return (
            <TableRow
              key={i}
              children={CHALLENGE_PROVE_INFO.map(({ COLUMN, WIDTH }) => {
                return (
                  <TableText key={COLUMN} width={WIDTH}>
                    {findProveValue(prove, COLUMN)}
                  </TableText>
                );
              })}
              popper={<ChallengeProvePopper data={prove} />}
            />
          );
        })}
      </TableBody>
    </>
  );
}

function findProveValue(
  data: ChallengeProveListByMissionAdminResponseDto,
  column: string
) {
  const { info, name, memberCode, status, provedDate } = data;
  switch (column) {
    case "참여자명":
      return name;
    case "회원번호":
      return memberCode.toString().padStart(8, "0");
    case "현재 상태":
      return status;
    case "미션 제출 시점":
      return formatDateToYYYYMMDDHHMM(provedDate);
    case "공감수/댓글수":
      return info.likeCount + "/" + info.commentCount;
    case "체중/근육량/체지방량/체지방률":
      if (!info.weight || !info.muscle || !info.fat || !info.fatRate) {
        return "-";
      }
      return (
        Number(info.weight)?.toFixed(1) +
        "/" +
        Number(info.muscle)?.toFixed(1) +
        "/" +
        Number(info.fat)?.toFixed(1) +
        "/" +
        Number(info.fatRate)?.toFixed(1)
      );
    default:
      return "0";
  }
}
