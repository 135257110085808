import styled from "styled-components";

export const ModalTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #323232;

  margin-bottom: 32px;
`;

export const ModalDescription = styled.p`
  font-size: 12px;
  color: #666666;
  margin-bottom: 8px;
`;
