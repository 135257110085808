import ModalComponent from "@component/common/ModalComponent";
import RowBox from "@component/common/RowBox";
import StandardInput from "@component/common/StandardInput";
import { ChallengeMission } from "@interface/challenge";
import { Box } from "@mui/material";

export default function ChallengeMissionComponent({
  index,
  mission,
  onMissionDelete,
  onMissionChange,
}: {
  index: number;
  mission: ChallengeMission;
  onMissionDelete: (index: number) => void;
  onMissionChange: (
    index: number,
    key: keyof ChallengeMission,
    value: string
  ) => void;
}) {
  return (
    <div
      style={{
        width: "100%",

        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <button
        onClick={() => onMissionDelete(index)}
        style={{
          color: "#F50000",
          fontSize: "12px",
          marginTop: "16px",
          outline: "none",
          padding: "0px",
        }}
      >
        삭제
      </button>
      <ModalComponent
        marginTop={12}
        description="미션 제목"
        component={
          <StandardInput
            name="title"
            value={mission.title}
            onChange={(e) => onMissionChange(index, "title", e.target.value)}
          />
        }
      />
      <ModalComponent
        marginTop={12}
        description="미션 내용"
        component={
          <StandardInput
            name="description"
            value={mission.description}
            onChange={(e) => onMissionChange(index, "description", e.target.value)}
          />
        }
      />
      <ModalComponent
        marginTop={12}
        description="미션 진행 기간"
        component={
          <RowBox height="auto" justifyContent="space-between">
            <StandardInput
              type="datetime-local"
              name="startDate"
              value={mission.startDate}
              onChange={(e) =>
                onMissionChange(index, "startDate", e.target.value)
              }
            />
            <Box
              sx={{
                width: "12px",
                margin: "8px",
              }}
            >
              ~
            </Box>
            <StandardInput
              type="datetime-local"
              name="endDate"
              value={mission.endDate}
              onChange={(e) =>
                onMissionChange(index, "endDate", e.target.value)
              }
            />
          </RowBox>
        }
      />
    </div>
  );
}
