import ApproveColumn from "@component/approve/ApproveColumn";
import ApproveContent from "@component/approve/ApproveContent";
import ApproveOption from "@component/approve/ApproveOption";
import Container from "@component/common/Container";
import useAuth from "@hook/useAuth";
import { useEffect } from "react";

export default function Approve() {
  const { checkAccessToken } = useAuth();

  useEffect(() => {
    checkAccessToken();
  }, []);

  return (
    <Container
      title="가입 승인"
      children={<ApproveContent />}
      option={<ApproveOption />}
      column={<ApproveColumn />}
    />
  );
}
