export function formatDateToYYYYMMDD(
  date: Date | string | null | undefined,
  split = "/"
) {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    date = new Date(date);
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0 based, so +1 and pad with 0
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}${split}${month}${split}${day}`;
}

export function formatDateToYYYYMMDDHHMM(date?: Date, split = "/") {
  if (!date) {
    return "";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0 based, so +1 and pad with 0
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}${split}${month}${split}${day} ${hours}:${minutes}`;
}

export function formatDateToYYYYMMDDTHHMM(
  date: Date,
  split = "-"
) {
  if (!date) {
    return "";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0 based, so +1 and pad with 0
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}${split}${month}${split}${day}T${hours}:${minutes}`;
}

export function diffInMinutes(start: Date, end: Date) {
  const diffInMilliseconds = Math.abs(start.getTime() - end.getTime());
  return Math.floor(diffInMilliseconds / 1000 / 60);
}
