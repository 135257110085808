import httpClient from "@api/http";
import { Role, UpdateUserAdminDto, UserEntity } from "@entity/user.entity";

class UserRepository {
  async getAllUsers(): Promise<UserEntity[]> {
    const response = await httpClient.get("/user/admin");
    return response.data;
  }

  async getUnapprovedUsers(): Promise<UserEntity[]> {
    const response = await httpClient.get("/user/admin/approve");
    return response.data;
  }

  async changeUserRole(id: string, role: Role): Promise<string> {
    return await httpClient.patch(`/user/admin/approve/${id}`, { role });
  }

  async deleteUser(id: string): Promise<string> {
    return await httpClient.delete(`/user/admin/${id}`);
  }

  async changeUserPassword(id: string, password: string): Promise<string> {
    return await httpClient.patch(`/user/admin/password`, {
      userId: id, password: password
    });
  }

  async searchUserByKeyword(keyword: string): Promise<UserEntity[]> {
    const response = await httpClient.get("/user/admin/search", {
      params: {
        name: keyword,
      },
    });
    return response.data;
  }

  async changeUserInformation(data: UpdateUserAdminDto) {
    await httpClient.patch("/user/admin/update", data);
  }
}

export default new UserRepository();
