import { useEffect, useState } from "react";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import MultiButton from "@component/common/MultiButton";
import StandardTextArea from "@component/common/StandardTextArea";
import Text from "@component/common/Text";
import useRecoilModal from "@hook/useRecoilModal";
import { ModalTitle } from "@component/common/ModalText";
import { useInput } from "@hook/useInput";
import { Box } from "@mui/material";
import { challengeParticipantMessageModal } from "@store/atom/challenge-participant";
import { formatDateToYYYYMMDD } from "@util/date";
import notificationRepository from "@api/notification/notification.repository";
import RowBox from "@component/common/RowBox";
import ButtonText from "@component/common/ButtonText";
import { useLocation } from "react-router-dom";
import { NotificationEntity } from "@entity/notification.entity";

export default function ChallengeParticipantMessageModal() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const challengeId = searchParams.get("id");

  const [notification, setNotification, _] = useInput("");
  const { closeModal } = useRecoilModal(challengeParticipantMessageModal);
  const [messages, setMessages] = useState<NotificationEntity[]>([]);

  useEffect(() => {
    if (notification.length === 0) {
      notificationRepository
        .getTotalNotificationsByChallengeId(challengeId)
        .then((res) => {
          setMessages(res);
        });
    }
  }, [notification]);

  const onSaved = async () => {
    if (challengeId && notification.length > 0) {
      await notificationRepository.sendNotificationToChallengeUsers(
        challengeId,
        notification
      );
      setNotification("");
    }
  };
  return (
    <>
      <ModalBox width={480}>
        <ModalTitle style={{ marginBottom: 16 }}>
          전체 참여자 메시지 보내기
        </ModalTitle>
        <Box
          sx={{
            marginBottom: "16px",
            padding: "12px 0px",
            border: "1px solid #d5d5d5",
            width: "100%",
            height: "200px",
            maxHeight: "200px",
            overflowX: "auto",
          }}
        >
          {messages.map(({ createdDate, message }, index) => {
            return (
              <ModalComponent
                key={index}
                marginBottom={index === messages.length - 1 ? null : 20}
                description={formatDateToYYYYMMDD(new Date(createdDate))}
                component={<Text>{message}</Text>}
              />
            );
          })}
        </Box>
        <StandardTextArea
          placeholder="메시지를 입력하세요."
          value={notification}
          onChange={(e) => {
            setNotification(e.target.value);
          }}
          height={120}
        />
        <RowBox marginTop={16} height="80px">
          <ButtonText
            onClick={closeModal}
            color="#8D8D8D"
            backgroundColor="#F5F5F5"
          >
            이전
          </ButtonText>
          <ButtonText
            onClick={onSaved}
            color="#FFFFFF"
            backgroundColor="#84C64A"
          >
            보내기
          </ButtonText>
        </RowBox>
      </ModalBox>
    </>
  );
}
