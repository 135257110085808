import { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { SignUpColumn } from "@interface/signup";
import { useValue } from "@hook/useValue";
import { useSetRecoilState } from "recoil";
import { selectedFoodData } from "@store/atom/signUp";
import { FoodEntity } from "@entity/food.entity";

interface SignUpTableProps {
  columns: SignUpColumn[];
  rows: any[];
}

export default function SignUpTable({ columns, rows }: SignUpTableProps) {
  const food = ["breakfast", "lunch", "dinner", "snack", "water", "nutrient"];
  const setFood = useSetRecoilState(selectedFoodData);
  const {
    value: page,
    setValue: setPage,
    onChangeValue: handleChangePage,
  } = useValue<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "none",
        marginTop: "16px",
      }}
    >
      <TableContainer sx={{ height: 440, maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{
                    minWidth: column.minWidth,
                    fontSize: "14px",
                    color: "#666666",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (food.includes(column.id)) {
                        return (
                          <TableCell
                            onClick={() => {
                              setFood(row[column.id] as FoodEntity);
                            }}
                            sx={{
                              fontSize: "14px",
                              color: "#666666",
                            }}
                            key={column.id}
                            align="left"
                          >
                            {value ? "O" : "-"}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            color: "#666666",
                          }}
                          key={column.id}
                          align="left"
                        >
                          {value
                            ? column.format && typeof value === "number"
                              ? column.format(value)
                              : value
                            : "-"}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          fontSize: "18px",
          color: "#666666",
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => handleChangePage(newPage)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
