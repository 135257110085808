import OptionBox from "@component/common/OptionBox";
import SearchBar from "@component/common/SearchBar";
import { useInput } from "@hook/useInput";
import { ChallengeModal, challengeInfoModal } from "@store/atom/challenge";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

export default function ChallengeOption() {
  const setChallengeInfoModal = useSetRecoilState(challengeInfoModal);
  const [search, setSearch, onChangeSearch] = useInput("");

  const onClickSearch = () => {
    // userRepository.searchUserByKeyword(search).then((res) => {
    //   setUserList(res);
    //   setSearch("");
    // });
  };

  const onOpenModal = () => {
    setChallengeInfoModal(ChallengeModal.CREATE);
  };
  return (
    <OptionBox>
      <SearchBar
        value={search}
        placeholder="챌린지명을 입력해주세요."
        onChangeValue={onChangeSearch}
        onClickSearch={onClickSearch}
      />
      <SendMessageButton onClick={onOpenModal}>개설하기</SendMessageButton>
    </OptionBox>
  );
}

const SendMessageButton = styled.button`
  width: 120px;
  height: 40px;

  background-color: #ffffff;
  border: 1px solid #84c64a;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  color: #84c64a;

  cursor: pointer;
  margin-left: 20px;
`;
