import httpClient from "@api/http";
import { NotificationEntity } from "@entity/notification.entity";

class NotificationRepository {
  async sendNotificationToUser(
    userId: string,
    message: string
  ): Promise<NotificationEntity> {
    const response = await httpClient.post("/notification", {
      userId,
      message,
      triggerDate: new Date(),
    });
    return response.data;
  }

  async sendNotificationToAll(message: string): Promise<NotificationEntity> {
    const response = await httpClient.post("/notification/admin", {
      message,
    });
    return response.data;
  }

  async sendNotificationToChallengeUsers(
    challengeId: string,
    message: string
  ): Promise<NotificationEntity> {
    const response = await httpClient.post("/notification/admin/challenge", {
      challengeId,
      message,
    });
    return response.data;
  }

  async getNotificationList(userId: string): Promise<NotificationEntity[]> {
    const response = await httpClient.get("/notification", {
      params: {
        userId,
      },
    });
    return response.data;
  }

  async getTotalNotificationsByChallengeId(
    challengeId: string | null = null
  ): Promise<NotificationEntity[]> {
    try {
      if (challengeId) {
        const response = await httpClient.get(
          `/notification/admin?challenge-id=${challengeId}`
        );
        return response.data;
      }
      const response = await httpClient.get("/notification/admin");
      return response.data;
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}

export default new NotificationRepository();
