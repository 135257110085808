import userRepository from "@api/user/user.repository";
import FilterButton from "@component/common/FilterButton";
import OptionBox from "@component/common/OptionBox";
import SearchBar from "@component/common/SearchBar";
import { useInput } from "@hook/useInput";
import useRecoilModal from "@hook/useRecoilModal";
import {
  signUpTotalMessageModal,
  signUpMemberSelectModal,
  userListData,
} from "@store/atom/signUp";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

export default function SignUpOption() {
  const setUserList = useSetRecoilState(userListData);
  const [search, setSearch, onChangeSearch] = useInput("");

  const { openModal: openSignUpTotalMessageModal } = useRecoilModal(
    signUpTotalMessageModal
  );
  const { openModal: openMemberSelectModal } = useRecoilModal(
    signUpMemberSelectModal
  );
  const onClickSearch = () => {
    userRepository.searchUserByKeyword(search).then((res) => {
      setUserList(res);
      setSearch("");
    });
  };
  return (
    <OptionBox>
      <SearchBar
        value={search}
        placeholder="이름을 입력해주세요."
        onChangeValue={onChangeSearch}
        onClickSearch={onClickSearch}
      />
      <SendMessageButton onClick={openSignUpTotalMessageModal}>
        메세지 보내기
      </SendMessageButton>
      <FilterButton onClickFilter={openMemberSelectModal} />
    </OptionBox>
  );
}

const SendMessageButton = styled.button`
  width: 120px;
  height: 40px;

  background-color: #ffffff;
  border: 1px solid #84c64a;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  color: #84c64a;

  cursor: pointer;
  margin-left: 20px;
`;
