import httpClient from "@api/http";
import axios from "axios";

class ChallengePictureRepository {
  async addChallengePicture(challengeId: string, fileName: string) {
    const response = await httpClient.post(
      `/challenge/admin/${challengeId}/picture`,
      { fileName }
    );

    return response.data;
  }

  async deleteChallengePictures(challengeId: string, keys: string[]) {
    const response = await httpClient.delete(
      `/challenge/admin/${challengeId}/picture`,
      {
        data: {
          challengeId,
          keys,
        },
      }
    );

    return response.data;
  }

  async uploadChallengePicturePresignedUrl(url: string, picture: File) {
    try {
      await axios.put(url, picture, {
        headers: {
          "Content-Type": picture.type,
          Accept: "*/*",
        },
      });
      console.log("File uploaded successfully");
    } catch (error) {
      console.error("File upload failed:", error);
    }
  }
}

export default new ChallengePictureRepository();
