import React from "react";
import styled from "styled-components";

type MenuProps = {
  children: React.ReactNode;
};

export default function MenuContainer({ children }: MenuProps) {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  width: 255px;
  height: 100vh;
  padding: 2vh 0px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;
