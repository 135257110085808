import { CommunityData } from "@entity/community.entity";
import { atom } from "recoil";

export const qnaDetailModal = atom({
  key: "qnaDetailModal",
  default: false,
});

export const qnaDeleteModal = atom({
  key: "qnaDeleteModal",
  default: false,
});

export const qnaListData = atom<CommunityData[]>({
  key: "qnaListData",
  default: [],
});

export const qnaOneData = atom<CommunityData | null>({
  key: "qnaOneData",
  default: null,
});
