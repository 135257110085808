import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface DropdownProps {
  options: string[];
  value: string;
  onChange: (e: SelectChangeEvent) => void;
}

export default function Dropdown({ options, value, onChange }: DropdownProps) {
  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <FormControl fullWidth>
        <Select
          sx={{
            fontSize: "12px",
            height: "40px",
          }}
          value={value}
          onChange={onChange}
        >
          {options.map((option) => (
            <MenuItem sx={{ fontSize: "12px" }} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
