import ModalBox from "@component/common/ModalBox";
import challengeProveRepository from "@api/challenge/challenge-prove.repository";
import ModalComponent from "@component/common/ModalComponent";
import RowBox from "@component/common/RowBox";
import ButtonText from "@component/common/ButtonText";
import StandardTextArea from "@component/common/StandardTextArea";
import { ModalDescription, ModalTitle } from "@component/common/ModalText";
import {
  ChallengeProveModal,
  challengeProveModal,
  challengeProveCommentDeleteData,
  selectedProve,
} from "@store/atom/challenge-prove";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { ChallengeProveInfoAdminResponseDto } from "@interface/challenge-prove";
import { StandardImage } from "@component/common/StandardImage";

export function ChallengeProveManageModal() {
  const [proveId, setProveId] = useRecoilState(selectedProve);
  const [data, setData] = useState<ChallengeProveInfoAdminResponseDto | null>(
    null
  );
  const setProveModal = useSetRecoilState(challengeProveModal);
  const setChallengeProveCommentData = useSetRecoilState(
    challengeProveCommentDeleteData
  );
  useEffect(() => {
    if (proveId) {
      challengeProveRepository
        .findChallengeProveInfoAdminResponseDtobyId(proveId)
        .then((res) => {
          setData(res);
        });
    }
  }, [proveId]);
  return (
    <ModalBox width={480}>
      <ModalTitle>제출 확인</ModalTitle>
      <ModalComponent
        description="피드 내용"
        component={
          <StandardTextArea
            disabled
            height={100}
            name="memo"
            value={data?.memo ?? ""}
          />
        }
      />
      <ModalComponent
        marginTop={20}
        marginBottom={12}
        description="피드 사진"
        component={
          <RowBox height="auto" justifyContent="flex-start">
            {!data || data.urls.length === 0 ? (
              <p>제출하지 않았습니다.</p>
            ) : (
              data.urls.map((url, index) => {
                return <StandardImage key={index} src={url} alt="image" />;
              })
            )}
          </RowBox>
        }
      />
      {data?.comments.map((comment, index) => {
        let description = `댓글${index + 1} (작성자 : ${
          comment.name
        } / 신고수: ${comment.alertEmails ? comment.alertEmails.length : 0}건)`;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              marginTop: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "4px",
              }}
            >
              <ModalDescription>{description}</ModalDescription>
              <p
                onClick={() => {
                  setChallengeProveCommentData({
                    challengeProveId: data.id,
                    challengeProveCommentId: comment.id,
                  });
                  setProveModal(ChallengeProveModal.PROVE_COMMENT_DELETE);
                }}
                style={{
                  color: "#F50000",
                  cursor: "pointer",
                }}
              >
                삭제
              </p>
            </div>
            <StandardTextArea disabled height={50} value={comment.content} />
          </div>
        );
      })}
      <RowBox height="auto">
        <ButtonText
          style={{ marginTop: "32px" }}
          onClick={() => {
            setProveModal(null);
            setProveId(null);
          }}
          color="#FFFFFF"
          backgroundColor="#84C64A"
        >
          확인
        </ButtonText>
      </RowBox>
    </ModalBox>
  );
}
