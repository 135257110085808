import bannerRepository from "@api/banner/banner.repository";
import BannerColumn from "@component/banner/BannerColumn";
import BannerContent from "@component/banner/BannerContent";
import BannerDeleteModal from "@component/banner/BannerDeleteModal";
import BannerModal from "@component/banner/BannerModal";
import Container from "@component/common/Container";
import useAuth from "@hook/useAuth";
import { Modal } from "@mui/material";
import {
  bannerDeleteModal,
  bannerRegisterModal,
  bannerUpdateModal,
  bannerOneData,
} from "@store/atom/banner";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

export default function Banner() {
  const { checkAccessToken } = useAuth();

  useEffect(() => {
    checkAccessToken();
  }, []);

  const [banner, setBanner] = useRecoilState(bannerOneData);
  const [registerModal, setRegisterModal] = useRecoilState(bannerRegisterModal);
  const onRegisterCancel = () => {
    setRegisterModal(false);
    setBanner(null);
  };
  const onRegisterSaved = async (order: number, title: string, file: any) => {
    if (title.length > 0 && order > 0) {
      await bannerRepository.createBanner(order, title, file);
    }

    setRegisterModal(false);
    setBanner(null);
  };

  const [updateModal, setUpdateModal] = useRecoilState(bannerUpdateModal);
  const onUpdateCancel = () => {
    setUpdateModal(false);
    setBanner(null);
  };
  const onUpdateSaved = async (order: number, title: string) => {
    if (banner && title.length > 0 && order > 0) {
      await bannerRepository.updateOrderAndBanner({
        id: banner.id,
        order,
        title,
      });
    }

    setUpdateModal(false);
    setBanner(null);
  };

  const [bannerDelete, setBannerDelete] = useRecoilState(bannerDeleteModal);
  const onDeleteModalClose = () => {
    setBannerDelete(false);
    setBanner(null);
  };
  const onDeleteBanner = async () => {
    if (banner) {
      await bannerRepository.deleteBannerById(banner.id);
    }

    setBannerDelete(false);
    setBanner(null);
  };

  return (
    <>
      <Container
        title="배너 관리"
        children={<BannerContent />}
        column={<BannerColumn />}
      />
      <Modal open={registerModal} onClose={onRegisterCancel}>
        <BannerModal
          title="배너 등록"
          data={banner}
          onCanceled={onRegisterCancel}
          onSaved={onRegisterSaved}
        />
      </Modal>
      <Modal open={updateModal} onClose={onUpdateCancel}>
        <BannerModal
          title="배너 수정"
          data={banner}
          onCanceled={onUpdateCancel}
          onSaved={onUpdateSaved}
        />
      </Modal>
      <Modal open={bannerDelete} onClose={onDeleteModalClose}>
        <BannerDeleteModal
          onClosed={onDeleteModalClose}
          onDeleted={onDeleteBanner}
        />
      </Modal>
    </>
  );
}
