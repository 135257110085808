import styled from "@emotion/styled";
import TableText from "@component/common/TableText";
import TableRow from "@component/common/TableRow";
import ApprovePopper from "./ApprovePopper";
import SignUpProfile from "@component/signup/SignUpProfile";

interface ApproveValueProps {
  userId: string;
  name?: string;
  email?: string;
  gender?: string;
  birth?: string;
  phone?: string;
  address?: string;
  memberCode?: number;
  reference: string | null;
}

export default function ApproveValue({
  userId,
  name,
  email,
  gender,
  birth,
  phone,
  address,
  reference,
}: ApproveValueProps) {
  return (
    <>
      <TableRow
        children={
          <ApproveValueBox>
            <SignUpProfile name={name} />
            <TableText width={18}>{email}</TableText>
            <TableText width={10}>{gender}</TableText>
            <TableText width={15}>{birth}</TableText>
            <TableText width={18}>{address}</TableText>
            <TableText width={14}>{reference?.padStart(8, "0")}</TableText>
          </ApproveValueBox>
        }
        popper={<ApprovePopper userId={userId} />}
      />
    </>
  );
}

const ApproveValueBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  padding: 14px 0px;

  & + & {
    border-top: 0.5px solid #e5e5e5;
  }
`;
