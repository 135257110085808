import notificationRepository from "@api/notification/notification.repository";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import { ModalTitle } from "@component/common/ModalText";
import MultiButton from "@component/common/MultiButton";
import StandardTextArea from "@component/common/StandardTextArea";
import Text from "@component/common/Text";
import { NotificationEntity } from "@entity/notification.entity";
import { useInput } from "@hook/useInput";
import useRecoilModal from "@hook/useRecoilModal";
import { Box } from "@mui/material";
import { signUpTotalMessageModal } from "@store/atom/signUp";
import { formatDateToYYYYMMDD } from "@util/date";
import { useEffect, useState } from "react";

export default function SignUpTotalMessageModal() {
  const [notification, setNotification, _] = useInput("");
  const { closeModal } = useRecoilModal(signUpTotalMessageModal);
  const [messages, setMessages] = useState<NotificationEntity[]>([]);

  useEffect(() => {
    if (notification.length === 0) {
      notificationRepository
        .getTotalNotificationsByChallengeId()
        .then((res) => {
          setMessages(res);
        });
    }
  }, [notification]);

  const onSaved = async () => {
    if (notification.length > 0) {
      await notificationRepository.sendNotificationToAll(notification);
      setNotification("");
    }
  };
  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>전체 메시지 보내기</ModalTitle>
        <Box
          sx={{ width: "100%", height: 250, maxHeight: 250, overflowX: "auto" }}
        >
          {messages.map(({ createdDate, message }, index) => {
            return (
              <ModalComponent
                key={index}
                marginBottom={index === messages.length - 1 ? null : 20}
                description={formatDateToYYYYMMDD(new Date(createdDate))}
                component={<Text>{message}</Text>}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "5px",
            backgroundColor: "#F5F5F5",
            margin: "24px 0px",
          }}
        />
        <StandardTextArea
          placeholder="메시지를 입력하세요."
          value={notification}
          onChange={(e) => {
            setNotification(e.target.value);
          }}
          height={80}
        />
        <MultiButton
          sub="취소"
          main="보내기"
          marginTop={32}
          onSubClick={() => {
            closeModal();
          }}
          onMainClick={onSaved}
        />
      </ModalBox>
    </>
  );
}
