import styled from "styled-components";

const TableColumn = styled.th<{ width: number }>`
  width: ${(props) => props.width}%;
  font-size: 14px;
  font-weight: 400;

  color: #666666;
  text-align: left;
`;

export default TableColumn;
