import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import { ModalTitle } from "@component/common/ModalText";
import MultiButton from "@component/common/MultiButton";
import RowBox from "@component/common/RowBox";
import StandardTextArea from "@component/common/StandardTextArea";
import Text from "@component/common/Text";
import useRecoilModal from "@hook/useRecoilModal";
import { Box } from "@mui/material";
import {
  selectedUserListData,
  signUpMemberSelectModal,
  signUpMessageModal,
} from "@store/atom/signUp";
import { useRecoilValue, useSetRecoilState } from "recoil";
import MemberSelect from "./MemberSelect";
import { formatDateToYYYYMMDD } from "@util/date";
import { useInput } from "@hook/useInput";
import notificationRepository from "@api/notification/notification.repository";
import { useState } from "react";

interface Message {
  date: string;
  message: string;
}

export default function SignUpMessageModal() {
  const [notification, setNotification, _] = useInput("");
  const { closeModal } = useRecoilModal(signUpMessageModal);
  const setSignUpMemberSelectModal = useSetRecoilState(signUpMemberSelectModal);
  const selectedMembers = useRecoilValue(selectedUserListData);
  const [messages, setMessages] = useState<Message[]>([]);

  const onSaved = async () => {
    if (selectedMembers.length > 0 && notification.length > 0) {
      await Promise.all(
        selectedMembers.map(async (member) => {
          await notificationRepository.sendNotificationToUser(
            member.id,
            notification
          );
        })
      );
      const newMessage: Message = {
        date: new Date().toString(),
        message: notification,
      };
      setMessages([...messages, newMessage]);
      setNotification("");
    }
  };
  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>메시지 보내기</ModalTitle>
        <RowBox justifyContent="flex-start" marginTop={12} height="auto">
          {selectedMembers.map((member, index) => {
            return (
              <MemberSelect
                key={index}
                id={member.id}
                name={member.name}
                profileImage={member.profileImage}
              />
            );
          })}
        </RowBox>
        <Box
          sx={{
            margin: "24px 0px",
            padding: "12px 0px",
            border: "1px solid #f5f5f5",
            width: "100%",
            height: "150px",
            maxHeight: "150px",
            overflowX: "auto",
          }}
        >
          {messages.map(({ date, message }, index) => {
            return (
              <ModalComponent
                key={index}
                marginBottom={index === messages.length - 1 ? null : 20}
                description={formatDateToYYYYMMDD(new Date(date))}
                component={<Text>{message}</Text>}
              />
            );
          })}
        </Box>
        <StandardTextArea
          placeholder="메시지를 입력하세요."
          value={notification}
          onChange={(e) => {
            setNotification(e.target.value);
          }}
          height={80}
        />
        <MultiButton
          sub="이전"
          main="보내기"
          onSubClick={() => {
            closeModal();
            setSignUpMemberSelectModal(true);
          }}
          onMainClick={onSaved}
          marginTop={32}
        />
      </ModalBox>
    </>
  );
}
