import styled from "styled-components";

export default function Table({ children }: { children: React.ReactNode }) {
  return (
    <>
      <TableBox>{children}</TableBox>
    </>
  );
}

const TableBox = styled.table`
  width: 100%;
  height: 95%;

  padding-bottom: 20px;
  overflow-y: scroll;
`;
