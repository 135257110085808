import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import PopperText from "@component/common/PopperText";
import { useRecoilState, useSetRecoilState } from "recoil";
import { qnaDeleteModal, qnaDetailModal, qnaOneData } from "@store/atom/qna";
import { QNAValueProps } from "@interface/qna";
import { usePopper } from "@hook/usePopper";

export default function QNAPopper({ qna }: QNAValueProps) {
  const { anchorEl, setAnchorEl, open, id, handleClick } = usePopper();

  const setQNA = useSetRecoilState(qnaOneData);
  const [qnaDetail, setQNADetail] = useRecoilState(qnaDetailModal);
  const [qnaDelete, setQNADelete] = useRecoilState(qnaDeleteModal);

  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClick(event);
    if (open) {
      setQNADetail(false);
      setQNADelete(false);
      setQNA(null);
    }
  };

  const handleQNADetailModal = () => {
    setQNADetail(!qnaDetail);
    setAnchorEl(null);
    setQNA(qna);
  };

  const handleQNADeleteModal = () => {
    setQNADelete(!qnaDelete);
    setAnchorEl(null);
    setQNA(qna);
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleModalClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <PopperText onClick={handleQNADetailModal}>상세보기 및 답변</PopperText>
        <PopperText onClick={handleQNADeleteModal}>게시물 삭제</PopperText>
      </Popper>
    </div>
  );
}
