import { useState, useCallback, Dispatch, SetStateAction } from "react";

export function useInput<T>(
  initialValue: T
): [
  T,
  Dispatch<SetStateAction<T>>,
  (e?: React.ChangeEvent<HTMLInputElement>) => void
] {
  const [value, setValue] = useState<typeof initialValue>(initialValue);
  const onChangeValue = useCallback((e: any) => {
    setValue(e.target.value);
  }, []);

  return [value, setValue, onChangeValue];
}
