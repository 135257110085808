import AuthRepository from "@api/auth/auth.repository";
import userContextRepository from "@api/user/user.context.repository";
import Logo from "@asset/icon/Logo";
import { useInput } from "@hook/useInput";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Login() {
  const navigate = useNavigate();
  const auth = new AuthRepository();
  const [email, setEmail, onEmailChange] = useInput("");
  const [password, setPassword, onPasswordChange] = useInput("");

  const onLogin = async () => {
    const accessToken = await auth.signIn(email, password);
    userContextRepository.saveAccessToken(accessToken);
    navigate("/");
    setEmail("");
    setPassword("");
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",

          backgroundColor: "#FFFFFF",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "12px",
            left: "0px",
          }}
        >
          <Logo />
        </div>
        <Column>
          <Title>로그인</Title>
          <Column>
            <InputText>이메일</InputText>
            <InputBox value={email} onChange={onEmailChange} type="email" />
          </Column>
          <Column style={{ marginTop: "16px" }}>
            <InputText>비밀번호</InputText>
            <InputBox
              value={password}
              onChange={onPasswordChange}
              type="password"
            />
          </Column>
          <LoginButton onClick={onLogin}>로그인</LoginButton>
        </Column>
      </div>
    </>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background-color: #ffffff;
`;

const Title = styled.p`
  font-size: 18px;
  color: #323232;
  font-weight: 500;

  margin-bottom: 20px;
`;

const InputText = styled.p`
  font-size: 12px;
  color: #666666;
`;

const InputBox = styled.input`
  width: 320px;

  padding: 12px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;

  outline: none;

  margin-top: 8px;
`;

const LoginButton = styled.button`
  width: 320px;
  height: 48px;

  background-color: #84c64a;
  color: #ffffff;

  padding: 12px;
  border-radius: 20px;
  border: none;

  outline: none;

  margin-top: 24px;
`;
