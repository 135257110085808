import { Box } from "@mui/material";

import ModalBox from "@component/common/ModalBox";
import { ModalTitle } from "@component/common/ModalText";
import RowBox from "@component/common/RowBox";
import CHALLENGE_STATUS from "@constant/CHALLENGE_STATUS";
import SignUpHistoryComponent from "../SignUpHistoryComponent";
import TabText from "@component/common/TabText";
import { useValue } from "@hook/useValue";
import { useRecoilValue } from "recoil";
import { selectedUserData } from "@store/atom/signUp";
import { UserEntity } from "@entity/user.entity";
import { useEffect, useState } from "react";
import {
  ChallengeHistoryAdminDto,
  ChallengeStatus,
} from "@entity/challenge.entity";
import SmallText from "@component/common/SmallText";
import challengeParticipationRepository from "@api/challenge/challenge-participation.repository";

export default function SignUpChallengeHistoryModal() {
  const user = useRecoilValue<UserEntity | null>(selectedUserData);
  const { value, onChangeValue } = useValue<string>(CHALLENGE_STATUS.ONGOING);
  const [challenges, setChallenges] = useState<ChallengeHistoryAdminDto[]>([]);
  useEffect(() => {
    if (user) {
      if (value === CHALLENGE_STATUS.ONGOING) {
        challengeParticipationRepository
          .findChallengeHistoryByUserAndStatus(user.id, ChallengeStatus.ONGOING)
          .then((res) => {
            setChallenges(res);
          });
      } else {
        challengeParticipationRepository
          .findChallengeHistoryByUserAndStatus(
            user.id,
            ChallengeStatus.FINISHED
          )
          .then((res) => {
            setChallenges(res);
          });
      }
    }
  }, [user, value]);
  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>챌린지 참여 기록</ModalTitle>
        <RowBox
          style={{ borderBottom: "1px solid #F5F5F5" }}
          justifyContent="flex-start"
          marginTop={0}
          height="auto"
        >
          <TabText
            onClick={() => onChangeValue(CHALLENGE_STATUS.ONGOING)}
            active={value === CHALLENGE_STATUS.ONGOING}
          >
            진행 중인 챌린지
          </TabText>
          <TabText
            onClick={() => onChangeValue(CHALLENGE_STATUS.COMPLETED)}
            active={value === CHALLENGE_STATUS.COMPLETED}
          >
            완료한 챌린지
          </TabText>
        </RowBox>
        <Box
          sx={{
            width: "100%",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          {challenges.length > 0 ? (
            challenges.map((challenge) => {
              return <SignUpHistoryComponent data={challenge} />;
            })
          ) : (
            <div style={{ marginTop: 12 }}>
              <SmallText>챌린지가 없습니다.</SmallText>
            </div>
          )}
        </Box>
      </ModalBox>
    </>
  );
}
