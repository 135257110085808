import TableBody from "@component/common/TableBody";
import ChallengeValue from "./ChallengeValue";
import { useEffect } from "react";
import challengeRepository from "@api/challenge/challenge.repository";
import { useValue } from "@hook/useValue";
import { ChallengeAdminDto } from "@interface/challenge";
import { useRecoilValue } from "recoil";
import { challengeInfoModal } from "@store/atom/challenge";

export default function ChallengeContent() {
  const challengeModal = useRecoilValue(challengeInfoModal);
  const { value: challenges, setValue: setChallenges } = useValue<
    ChallengeAdminDto[]
  >([]);

  useEffect(() => {
    challengeRepository.findAllChallenges().then((res) => {
      setChallenges(res);
    });
  }, [challengeModal]);

  return (
    <>
      <TableBody>
        {challenges.map((challenge, index) => {
          return <ChallengeValue key={index} data={challenge} />;
        })}
      </TableBody>
    </>
  );
}
