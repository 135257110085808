import { ChallengeEntity } from "@entity/challenge.entity";
import { ChallengeAdminDto } from "@interface/challenge";
import { atom } from "recoil";

export enum ChallengeModal {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

export const challengeInfoModal = atom<ChallengeModal | null>({
  key: "challengeInfoModal",
  default: null,
});

export const selectedChallenge = atom<ChallengeAdminDto | null>({
  key: "selectedChallenge",
  default: null,
});
