import httpClient from "@api/http";
import { UserActivityEntity } from "@entity/activity.entity";

class ActivityRepository {
  async findAllByUserId(userId: string): Promise<UserActivityEntity[]> {
    const response = await httpClient.get(
      `/userActivity/admin?userId=${userId}`
    );
    return response.data;
  }

  async findAllByUserIdAndDateBetween(
    userId: string,
    startDate: string,
    endDate: string
  ): Promise<UserActivityEntity[]> {
    const response = await httpClient.get(
      `/userActivity/admin/${userId}?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  }
}

export default new ActivityRepository();
