import ChallengeColumn from "@component/challenge/ChallengeColumn";
import ChallengeContent from "@component/challenge/ChallengeContent";
import ChallengeOption from "@component/challenge/ChallengeOption";
import ChallengeInfoModal from "@component/challenge/modal/ChallengeInfoModal";
import Container from "@component/common/Container";
import useAuth from "@hook/useAuth";
import { Modal } from "@mui/material";
import { ChallengeModal, challengeInfoModal } from "@store/atom/challenge";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

export default function Challenge() {
  const { checkAccessToken } = useAuth();
  const [challengeInfo, setChallengeInfo] =
    useRecoilState<ChallengeModal | null>(challengeInfoModal);

  useEffect(() => {
    checkAccessToken();
  }, []);
  return (
    <>
      <Container
        title="챌린지 관리"
        option={<ChallengeOption />}
        children={<ChallengeContent />}
        column={<ChallengeColumn />}
      />
      <Modal
        open={challengeInfo !== null}
        onClose={() => setChallengeInfo(null)}
      >
        <ChallengeInfoModal />
      </Modal>
    </>
  );
}
