import Dropdown from "@component/common/Dropdown";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import useRecoilModal from "@hook/useRecoilModal";
import MultiButton from "@component/common/MultiButton";
import RowBox from "@component/common/RowBox";
import StandardInput from "@component/common/StandardInput";
import { ModalTitle } from "@component/common/ModalText";
import {
  GenderType,
  Role,
  UpdateUserAdminDto,
  UserEntity,
} from "@entity/user.entity";
import { useDictInput } from "@hook/useDictInput";
import { Box, SelectChangeEvent } from "@mui/material";
import { selectedUserData, signUpUpdateModal } from "@store/atom/signUp";
import { formatDateToYYYYMMDD } from "@util/date";
import { makeNumIntoDigits } from "@util/number";
import { useRecoilState } from "recoil";
import userRepository from "@api/user/user.repository";

export default function SignUpMemberUpdateModal() {
  const [selectMember, setSelectMember] = useRecoilState<UserEntity | null>(
    selectedUserData
  );
  const { closeModal } = useRecoilModal(signUpUpdateModal);

  const [data, setData, onChange] = useDictInput(
    selectMember ?? {
      id: "",
      name: "",
      email: "",
      birthDate: null,
      gender: GenderType.NONE,
      phoneNumber: "",
      role: Role.USER,
      memberNumber: "",
      coach: "",
      height: "",
      memberCode: "",
      address: "",
      detailAddress: "",
      reference: "",
    }
  );

  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>회원정보 수정</ModalTitle>
        <RowBox height="auto" justifyContent="space-between">
          <ModalComponent
            description="이름"
            component={
              <StandardInput
                name="name"
                value={data.name}
                onChange={onChange}
              />
            }
          />
          <Box sx={{ width: "12px" }} />
          <ModalComponent
            description="이메일 주소"
            component={
              <StandardInput
                disabled
                name="email"
                value={data.email}
                onChange={onChange}
              />
            }
          />
        </RowBox>
        <RowBox marginTop={20} height="auto" justifyContent="space-between">
          <ModalComponent
            description="생년월일"
            component={
              <StandardInput
                disabled
                name="birthDate"
                value={data?.birthDate ? data.birthDate : ""}
                onChange={onChange}
              />
            }
          />
          <Box sx={{ width: "12px" }} />
          <ModalComponent
            description="성별"
            component={
              <Dropdown
                value={data.gender}
                onChange={(e: SelectChangeEvent) => {
                  setData({ ...data, gender: e.target.value as GenderType });
                }}
                options={Object.keys(GenderType)}
              />
            }
          />
        </RowBox>
        <RowBox marginTop={20} height="auto" justifyContent="space-between">
          <ModalComponent description="" component={<></>} />
          <Box sx={{ width: "12px" }} />
          <ModalComponent
            description="등급"
            component={
              <Dropdown
                value={data.role}
                onChange={(e: SelectChangeEvent) => {
                  setData({ ...data, role: e.target.value as Role });
                }}
                options={Object.keys(Role)}
              />
            }
          />
        </RowBox>
        <RowBox marginTop={20} height="auto" justifyContent="space-between">
          <ModalComponent
            description="회원번호"
            component={
              <StandardInput
                disabled
                name="memberCode"
                value={makeNumIntoDigits(Number(data.memberCode), 8)}
              />
            }
          />
          <Box sx={{ width: "12px" }} />
          <ModalComponent
            description="담당코치"
            component={
              <StandardInput
                disabled
                name="coach"
                value={data.coach ?? ""}
                onChange={onChange}
              />
            }
          />
        </RowBox>
        <RowBox marginTop={20} height="auto" justifyContent="space-between">
          <ModalComponent
            description="신장"
            component={
              <StandardInput
                name="height"
                value={data.height}
                onChange={onChange}
              />
            }
          />
          <Box sx={{ width: "12px" }} />
          <ModalComponent
            description="담당코치 회원번호"
            component={
              <StandardInput
                disabled
                name="reference"
                value={data.reference ?? ""}
                onChange={onChange}
              />
            }
          />
        </RowBox>
        <RowBox marginTop={20} height="auto">
          <ModalComponent
            description="주소"
            component={
              <StandardInput
                disabled
                name="address"
                value={data.address + " " + data.detailAddress}
                onChange={onChange}
              />
            }
          />
        </RowBox>
        <MultiButton
          sub="취소"
          main="저장"
          marginTop={32}
          onSubClick={closeModal}
          onMainClick={() => {
            userRepository
              .changeUserInformation(
                new UpdateUserAdminDto({ ...data, height: Number(data.height) })
              )
              .then(() => {
                setSelectMember(null);
              });
            closeModal();
          }}
        />
      </ModalBox>
    </>
  );
}
