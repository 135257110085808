import TableRow from "@component/common/TableRow";
import TableText from "@component/common/TableText";
import PostPopper from "./PostPopper";
import { POST_INFO } from "@constant/POST_INFO";
import { PostValueProps } from "@interface/post";
import { formatDateToYYYYMMDD } from "@util/date";
import { CommunityData } from "@entity/community.entity";

export default function PostValue({ post }: PostValueProps) {
  return (
    <TableRow
      children={POST_INFO.map(({ WIDTH }, index) => {
        return (
          <TableText width={WIDTH}>{PostTableContent(index, post)}</TableText>
        );
      })}
      popper={<PostPopper post={post} />}
    />
  );
}

function PostTableContent(
  index: number,
  { communityType, title, likeCount, scrapCount, createdDate }: CommunityData
) {
  switch (index) {
    case 0:
      return communityType;
    case 1:
      return title;
    case 2:
      return `${likeCount ?? 0} / ${scrapCount ?? 0}`;
    case 3:
      return formatDateToYYYYMMDD(new Date(createdDate));
    default:
      return null;
  }
}
