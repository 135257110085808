import { useState, useCallback, Dispatch, SetStateAction } from "react";

export function useValue<T>(init: T): {
  value: T;
  setValue: Dispatch<SetStateAction<T>>;
  onChangeValue: (newValue: T) => void;
} {
  const [value, setValue] = useState<typeof init>(init);
  const onChangeValue = useCallback((newValue: T) => {
    setValue(newValue);
  }, []);
  return { value, setValue, onChangeValue };
}
