import TableRow from "@component/common/TableRow";
import TableText from "@component/common/TableText";
import { BANNER_INFO } from "@constant/BANNER_INFO";
import BannerPopper from "./BannerPopper";
import { BannerEntity } from "@entity/banner.entity";

interface BannerValueProps {
  banner: BannerEntity;
}

export default function BannerValue({ banner }: BannerValueProps) {
  return (
    <TableRow
      children={BANNER_INFO.map(({ WIDTH }, i) => {
        const content = i === 0 ? banner.order : banner.title;
        return (
          <TableText key={i} width={WIDTH}>
            {content}
          </TableText>
        );
      })}
      popper={<BannerPopper banner={banner} />}
    />
  );
}
