import RowBox from "@component/common/RowBox";
import Text from "@component/common/Text";
import MemberProfile from "./MemberProfile";

interface MemberProps {
  checked: boolean;
  src?: string;
  name: string;
  onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Member({ checked, src, name, onChecked }: MemberProps) {
  return (
    <RowBox
      style={{
        borderTop: "1px solid #F5F5F5",
        borderBottom: "1px solid #F5F5F5",
        padding: "12px 0px",
        overflow: "hidden",
        minHeight: "64px",
      }}
      justifyContent="flex-start"
      marginTop={0}
      height="64px"
    >
      <input
        onChange={onChecked}
        checked={checked}
        style={{
          width: "18px",
          height: "18px",
          marginRight: "12px",
        }}
        type="checkbox"
      />
      <MemberProfile src={src} />
      <Text width="auto" style={{ marginLeft: "12px" }}>
        {name}
      </Text>
    </RowBox>
  );
}
