import OptionBox from "@component/common/OptionBox";
import SearchBar from "@component/common/SearchBar";
import { useInput } from "@hook/useInput";

export default function ApproveOption() {
  const [search, setSearch, onChangeSearch] = useInput("");

  const onClickSearch = () => {
    setSearch("");
  };
  return (
    <OptionBox>
      <SearchBar
        value={search}
        placeholder="이메일을 입력해주세요."
        onChangeValue={onChangeSearch}
        onClickSearch={onClickSearch}
      />
    </OptionBox>
  );
}
