import TableColumn from "@component/common/TableColumn";
import TableHeader from "@component/common/TableHeader";
import { CHALLENGE_INFO } from "@constant/CHALLENGE_INFO";

export default function ChallengeColumn() {
  return (
    <TableHeader>
      {CHALLENGE_INFO.map(({ WIDTH, COLUMN }, index) => {
        return (
          <TableColumn key={index} width={WIDTH}>
            {COLUMN}
          </TableColumn>
        );
      })}
    </TableHeader>
  );
}
