import challengeMissionRepository from "@api/challenge/challenge-mission.repository";
import TableBody from "@component/common/TableBody";
import TableRow from "@component/common/TableRow";
import TableText from "@component/common/TableText";
import { CHALLENGE_MISSION_INFO } from "@constant/CHALLENGE_MISSION_INFO";
import { ChallengeMissionListByChallengeAdminDto } from "@interface/challenge";
import { formatDateToYYYYMMDDHHMM } from "@util/date";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ChallengeMissionContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title");
  const id = searchParams.get("id");
  const [missions, setMissions] = useState<
    ChallengeMissionListByChallengeAdminDto[]
  >([]);

  const onClick = (missionId: string, missionTitle: string) => {
    navigate(
      `/challenge/mission/${missionId}?title=${title} > ${missionTitle}`
    );
  };

  useEffect(() => {
    if (id) {
      challengeMissionRepository
        .findChallengeMissionByChallengeId(id)
        .then((res) => {
          setMissions(res);
        });
    }
  }, []);
  return (
    <>
      <TableBody>
        {missions.map((mission, i) => {
          let title =
            mission.title +
            `(${formatDateToYYYYMMDDHHMM(
              mission.startDate,
              "-"
            )} ~ ${formatDateToYYYYMMDDHHMM(mission.endDate, "-")})`;
          return (
            <TableRow
              onClick={() => onClick(mission.missionId, title)}
              key={i}
              children={CHALLENGE_MISSION_INFO.map(({ COLUMN, WIDTH }) => {
                return (
                  <TableText key={COLUMN} width={WIDTH}>
                    {findMissionValue(mission, COLUMN)}
                  </TableText>
                );
              })}
              popper={null}
            />
          );
        })}
      </TableBody>
    </>
  );
}

function findMissionValue(
  data: ChallengeMissionListByChallengeAdminDto,
  column: string
) {
  switch (column) {
    case "제목":
      return data.title;
    case "내용":
      return data.description;
    case "진행 기간":
      return `${formatDateToYYYYMMDDHHMM(
        data.startDate,
        "-"
      )} ~ ${formatDateToYYYYMMDDHHMM(data.endDate, "-")}`;
    case "제출 완료":
      return `${data.submittedCount}건`;
    case "승인 완료":
      return `${data.checkedCount}건`;
    default:
      return "";
  }
}
