import Container from "@component/common/Container";
import QNAColumn from "@component/qna/QNAColumn";
import QNAContent from "@component/qna/QNAContent";
import QNADeleteModal from "@component/qna/QNADeleteModal";
import QNADetailModal from "@component/qna/QNADetailModal";
import useAuth from "@hook/useAuth";
import { Modal } from "@mui/material";
import { qnaDeleteModal, qnaDetailModal } from "@store/atom/qna";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

export default function QNA() {
  const { checkAccessToken } = useAuth();

  useEffect(() => {
    checkAccessToken();
  }, []);

  const [qnaDetail, setQNADetail] = useRecoilState(qnaDetailModal);
  const [qnaDelete, setQnaDelete] = useRecoilState(qnaDeleteModal);
  return (
    <>
      <Container
        title="Q&A 관리"
        children={<QNAContent />}
        column={<QNAColumn />}
      />
      <Modal open={qnaDetail} onClose={() => setQNADetail(false)}>
        <QNADetailModal />
      </Modal>
      <Modal open={qnaDelete} onClose={() => setQnaDelete(false)}>
        <QNADeleteModal />
      </Modal>
    </>
  );
}
