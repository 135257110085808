import { BannerEntity } from "@entity/banner.entity";
import { atom } from "recoil";

export const bannerRegisterModal = atom({
  key: "bannerRegisterModal",
  default: false,
});

export const bannerUpdateModal = atom({
  key: "bannerUpdateModal",
  default: false,
});

export const bannerDeleteModal = atom({
  key: "bannerDeleteModal",
  default: false,
});

export const bannerOneData = atom<BannerEntity | null>({
  key: "bannerOneData",
  default: null,
});

export const bannerListData = atom<BannerEntity[]>({
  key: "bannerListData",
  default: [],
});
