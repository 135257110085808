import challengeProveRepository from "@api/challenge/challenge-prove.repository";
import ButtonText from "@component/common/ButtonText";
import ModalBox from "@component/common/ModalBox";
import RowBox from "@component/common/RowBox";
import {
  challengeProveModal,
  challengeProveCommentDeleteData,
} from "@store/atom/challenge-prove";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";

export function ChallengeProveCommentDeleteModal() {
  const [proveCommentData, setChallengeProveCommentData] = useRecoilState(
    challengeProveCommentDeleteData
  );
  const setDeleteModal = useSetRecoilState(challengeProveModal);
  const closeModal = () => {
    setDeleteModal(null);
    setChallengeProveCommentData(null);
  };

  const onDelete = async () => {
    if (proveCommentData) {
      await challengeProveRepository.deleteCommentById(proveCommentData);
      setChallengeProveCommentData(null);
    }

    setDeleteModal(null);
  };

  return (
    <ModalBox width={480}>
      <Text>게시물을 삭제하시겠습니까?</Text>
      <RowBox
        height="auto"
        style={{ justifyContent: "flex-end" }}
        marginTop={24}
      >
        <ButtonText
          onClick={closeModal}
          color="#8D8D8D"
          backgroundColor="#F5F5F5"
        >
          취소
        </ButtonText>
        <ButtonText
          onClick={onDelete}
          color="#FFFFFF"
          backgroundColor="#84C64A"
        >
          삭제
        </ButtonText>
      </RowBox>
    </ModalBox>
  );
}

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #323232;
`;
