export const CHALLENGE_INFO = [
  {
    COLUMN: "챌린지명",
    WIDTH: 20,
  },
  {
    COLUMN: "결제 가능 기간",
    WIDTH: 20,
  },
  {
    COLUMN: "챌린지 진행 기간",
    WIDTH: 20,
  },
  {
    COLUMN: "참여자 수",
    WIDTH: 20,
  },
  {
    COLUMN: "가격",
    WIDTH: 20,
  },
];
