export const POST_INFO = [
  {
    COLUMN: "게시판명",
    WIDTH: 20,
  },
  {
    COLUMN: "제목",
    WIDTH: 40,
  },
  {
    COLUMN: "좋아요/스크랩",
    WIDTH: 20,
  },
  {
    COLUMN: "작성일시",
    WIDTH: 20,
  },
];
