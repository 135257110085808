import httpClient from "@api/http";
import { ChallengeStatus } from "@entity/challenge.entity";
import { ChallengeParticipationAdminDto } from "@interface/challenge-participant";

class ChallengeParticipationRepository {
  async findAllByChallengeId(
    challengeId: string
  ): Promise<ChallengeParticipationAdminDto[]> {
    const response = await httpClient.get(
      `/challengeParticipation/admin/${challengeId}`
    );
    return response.data;
  }

  async downloadChallengeParticipations(challengeId: string) {
    try {
      const response = await httpClient.get(
        `/challengeParticipation/admin/download?challengeId=${challengeId}`,
        {
          responseType: "blob", // Important
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "participations.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }

  async findChallengeHistoryByUserAndStatus(
    userId: string,
    status: ChallengeStatus
  ) {
    const response = await httpClient.get(
      `/challengeParticipation/admin/user?userId=${userId}&status=${status}`
    );
    return response.data;
  }
}

export default new ChallengeParticipationRepository();
