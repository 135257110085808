import styled from "styled-components";

const OptionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 10%;
`;

export default OptionBox;
