import { CommunityData } from "@entity/community.entity";
import { atom } from "recoil";

export const postModal = atom({
  key: "postModal",
  default: false,
});

export const postDeleteModal = atom({
  key: "postDeleteModal",
  default: false,
});

export const postOneData = atom<CommunityData | null>({
  key: "postOneData",
  default: null,
});

export const postListData = atom<CommunityData[]>({
  key: "postListData",
  default: [],
});
