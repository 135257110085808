import styled from "styled-components";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

interface SearchBarProps {
  value: string;
  placeholder: string;
  onChangeValue: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  onClickSearch?: () => void;
}

export default function SearchBar({
  value,
  placeholder,
  onChangeValue,
  onClickSearch,
}: SearchBarProps) {
  return (
    <SearchBarBox>
      <SearchBarInput
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
      />
      <SearchOutlinedIcon
        onClick={onClickSearch}
        sx={{
          fontSize: 20,
          color: "#808080",
          cursor: "pointer",
          margin: 0,
          padding: 0,
        }}
      />
    </SearchBarBox>
  );
}

const SearchBarBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 40px;

  padding: 0px 12px;
  background-color: #fbfbfb;
`;

const SearchBarInput = styled.input`
  width: 100%;
  height: 40px;

  background-color: #fbfbfb;
  border: none;
  outline: none;
`;
