import MenuIcon from "@asset/icon/MenuIcon";
import styled from "styled-components";

export default function MenuBox({ text, isActive, onClick }: MenuTextProps) {
  return (
    <Box isActive={isActive}>
      <MenuIcon isActive={isActive} />
      <MenuText isActive={isActive} onClick={onClick}>
        {text}
      </MenuText>
    </Box>
  );
}

const Box = styled.div<{ isActive: boolean }>`
  padding: 12px 32px;
  background-color: ${({ isActive }) =>
    isActive ? "rgba(132, 198, 74, 0.2)" : "#ffffff"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const MenuText = styled.button<{ isActive: boolean }>`
  font-size: 16px;
  color: ${({ isActive }) => (isActive ? "green" : "gray")};
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
`;

interface MenuTextProps {
  text: string;
  isActive: boolean;
  onClick?: () => void;
}
