import PlusButton from "@asset/icon/PlusButton";
import TableBody from "@component/common/TableBody";
import PostValue from "./PostValue";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  postDeleteModal,
  postModal,
  postListData,
  postOneData,
} from "@store/atom/post";
import communityRepository from "@api/community/community.repository";
import { useEffect } from "react";

export default function PostContent() {
  const post = useRecoilValue(postOneData);
  const postDelete = useRecoilValue(postDeleteModal);
  const postView = useRecoilValue(postModal);
  const [postList, setPostList] = useRecoilState(postListData);

  useEffect(() => {
    if (!postDelete && !postView) {
      communityRepository.getCommunityList().then((res) => {
        setPostList(res);
      });
    }
  }, [post, postDelete, postView]);
  return (
    <>
      <TableBody>
        {postList.map((post, index) => {
          return <PostValue key={index} post={post} />;
        })}
      </TableBody>
      <PlusButton />
    </>
  );
}
