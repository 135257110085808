import Container from "@component/common/Container";
import PostColumn from "@component/post/PostColumn";
import PostContent from "@component/post/PostContent";
import PostDeleteModal from "@component/post/PostDeleteModal";
import PostModal from "@component/post/PostModal";
import useAuth from "@hook/useAuth";
import { Modal } from "@mui/material";
import { postDeleteModal, postModal } from "@store/atom/post";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

export default function Post() {
  const { checkAccessToken } = useAuth();

  useEffect(() => {
    checkAccessToken();
  }, []);

  const [open, setOpen] = useRecoilState(postModal);
  const [postDelete, setPostDelete] = useRecoilState(postDeleteModal);
  return (
    <>
      <Container
        title="게시글 관리"
        children={<PostContent />}
        column={<PostColumn />}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <PostModal title="글 수정하기" />
      </Modal>
      <Modal open={postDelete} onClose={() => setPostDelete(false)}>
        <PostDeleteModal />
      </Modal>
    </>
  );
}
