export const CHALLENGE_PARTICIPANT_INFO = [
  {
    COLUMN: "참여자명",
    WIDTH: 20,
  },
  {
    COLUMN: "회원번호",
    WIDTH: 20,
  },
  {
    COLUMN: "미션 달성률",
    WIDTH: 20,
  },
  {
    COLUMN: "중간 순위/최종 순위",
    WIDTH: 20,
  },
  {
    COLUMN: "중간 점수/최종 점수",
    WIDTH: 20,
  },
];
