export interface UserActivityEntity {
  id: string;
  userId: string;
  name: string;
  memo: string | null;
  pictureUrl: string | null;
  startDate: Date | null;
  endDate: Date | null;
  isCompleted: boolean;
  historyDate: Date;
  type: ActivityType;
  createdDate: Date;
  updatedDate: Date;
}

export enum ActivityType {
  NORMAL = "NORMAL",
  CHECK_NORMAL = "CHECK_NORMAL",
  CHECK_ATTENDANCE = "CHECK_ATTENDANCE",
}
