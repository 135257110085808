import styled from "styled-components";

const ButtonText = styled.button<{ color: string; backgroundColor: string }>`
  width: 120px;

  padding: 8px 0;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;

  & + & {
    margin-left: 8px;
  }
`;

export default ButtonText;
