import ModalBox from "@component/common/ModalBox";
import challengeProveRepository from "@api/challenge/challenge-prove.repository";
import ModalComponent from "@component/common/ModalComponent";
import RowBox from "@component/common/RowBox";
import Dropdown from "@component/common/Dropdown";
import StandardTextArea from "@component/common/StandardTextArea";
import StandardInput from "@component/common/StandardInput";
import MultiButton from "@component/common/MultiButton";
import { ModalTitle } from "@component/common/ModalText";
import { SelectChangeEvent } from "@mui/material";
import {
  challengeProveModal,
  selectedProve,
} from "@store/atom/challenge-prove";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import {
  ChallengeProveInfoAdminResponseDto,
  UpdateChallengeProveRequestAdminDto,
} from "@interface/challenge-prove";
import { ChallengeProveStatus, MissionType } from "@entity/challenge.entity";
import { StandardImage, StandardVideo } from "@component/common/StandardImage";

export function ChallengeProveCheckModal() {
  const [proveId, setProveId] = useRecoilState(selectedProve);
  const [data, setData] = useState<ChallengeProveInfoAdminResponseDto | null>(
    null
  );
  const setProveModal = useSetRecoilState(challengeProveModal);

  useEffect(() => {
    if (proveId) {
      challengeProveRepository
        .findChallengeProveInfoAdminResponseDtobyId(proveId)
        .then((res) => {
          setData(res);
        });
    }
  }, [proveId]);
  return (
    <ModalBox style={{ maxHeight: "90vh", overflowY: "scroll" }} width={480}>
      <ModalTitle>제출 확인</ModalTitle>
      {data ? (
        <>
          <ModalComponent
            minHeight={60}
            description="미션 상태"
            component={
              <Dropdown
                value={data?.status ?? ""}
                onChange={(e: SelectChangeEvent) => {
                  setData({
                    ...data,
                    status: e.target.value as ChallengeProveStatus,
                  });
                }}
                options={Object.keys(ChallengeProveStatus)}
              />
            }
          />
          {data.status === ChallengeProveStatus.REJECTED ? (
            <>
              <ModalComponent
                minHeight={60}
                marginTop={20}
                description="반려 사유"
                component={
                  <StandardTextArea
                    height={100}
                    name="reason"
                    value={data.reason ?? ""}
                    onChange={(e) => {
                      setData({
                        ...data,
                        reason: e.target.value,
                      });
                    }}
                  />
                }
              />
            </>
          ) : (
            <></>
          )}
          <ModalComponent
            marginTop={20}
            minHeight={180}
            description="인증 사진"
            component={
              <RowBox height="auto" justifyContent="flex-start">
                {data.urls.length === 0 ? (
                  <p>제출하지 않았습니다.</p>
                ) : (
                  data.urls.map((url, index) => {
                    const extension = url.split("?")[0].split(".").pop() ?? "";
                    if (["jpeg", "jpg", "png", "heic"].includes(extension)) {
                      return (
                        <a href={url} target="_blank">
                          {" "}
                          <StandardImage key={index} src={url} />
                        </a>
                      );
                    } else if (
                      ["mp4", "webm", "ogg", "MOV", "mov"].includes(extension)
                    ) {
                      return (
                        <a href={url} target="_blank">
                          {" "}
                          <StandardVideo key={index} src={url} />
                        </a>
                      );
                    }
                  })
                )}
              </RowBox>
            }
          />
          <ModalComponent
            minHeight={120}
            marginTop={20}
            description="메모 내용"
            component={
              <StandardTextArea
                disabled
                height={100}
                name="memo"
                value={data.memo ?? ""}
              />
            }
          />
          {data.missionType === MissionType.MISSION ? (
            <>
              {data.activities.length > 0 ? (
                <ModalComponent
                  marginTop={20}
                  minHeight={60}
                  description="활동 내용"
                  component={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {data.activities.map((activity, index) => {
                        return (
                          <StandardInput
                            key={index}
                            disabled
                            name="activity"
                            value={activity}
                            width={400}
                            height={40}
                          />
                        );
                      })}
                    </div>
                  }
                />
              ) : (
                <></>
              )}
              <ModalComponent
                marginTop={20}
                minHeight={60}
                description="가산점 입력"
                component={
                  <StandardInput
                    style={{ marginTop: "4px" }}
                    name="additionalScore"
                    value={data.additionalScore ?? 0}
                    onChange={(e) => {
                      setData({
                        ...data,
                        additionalScore: e.target.value,
                      });
                    }}
                  />
                }
              />
            </>
          ) : (
            <>
              <ModalComponent
                marginTop={20}
                minHeight={200}
                description="신체기록"
                component={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      minHeight: "160px",
                    }}
                  >
                    <StandardInput
                      disabled
                      name="weight"
                      value={data.weight ?? 0}
                      width={400}
                      height={40}
                    />
                    <StandardInput
                      disabled
                      style={{ marginTop: "4px" }}
                      name="muscle"
                      value={data.muscle ?? 0}
                      width={400}
                      height={40}
                    />
                    <StandardInput
                      disabled
                      style={{ marginTop: "4px" }}
                      name="fat"
                      value={data.fat ?? 0}
                      width={400}
                      height={40}
                    />
                    <StandardInput
                      disabled
                      style={{ marginTop: "4px" }}
                      name="fatRate"
                      value={data.fatRate ?? 0}
                      width={400}
                      height={40}
                    />
                  </div>
                }
              />
              <ModalComponent
                marginTop={20}
                minHeight={60}
                description="기본 점수"
                component={
                  <StandardInput
                    disabled
                    name="basicScore"
                    value={data.basicScore ?? 0}
                    width={400}
                    height={40}
                  />
                }
              />
              <ModalComponent
                marginTop={20}
                minHeight={60}
                description="지연 조정"
                component={
                  <StandardInput
                    disabled
                    name="delayScore"
                    value={data.delayScore ?? 0}
                    width={400}
                    height={40}
                  />
                }
              />
              <ModalComponent
                marginTop={20}
                minHeight={60}
                description="기타 조정"
                component={
                  <StandardInput
                    onChange={(e) => {
                      setData({
                        ...data,
                        etcScore: e.target.value,
                      });
                    }}
                    name="etcScore"
                    value={data.etcScore.toString() ?? "0"}
                    width={400}
                    height={40}
                  />
                }
              />
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <MultiButton
        sub="취소"
        main="저장"
        marginTop={32}
        onSubClick={() => {
          setProveModal(null);
          setProveId(null);
        }}
        onMainClick={() => {
          if (data) {
            const { id, status, reason, additionalScore, etcScore } = data;
            challengeProveRepository.updateChallengeProveInfoAdminResponseDto(
              new UpdateChallengeProveRequestAdminDto({
                id,
                status,
                reason,
                additionalScore: makeStringIntoNum(additionalScore.toString()),
                etcScore: makeStringIntoNum(etcScore.toString()),
              })
            );
          }
          setProveModal(null);
          setProveId(null);
        }}
      />
    </ModalBox>
  );
}

function makeStringIntoNum(str: string) {
  return str.includes("-") ? Number(str.replace("-", "")) * -1 : Number(str);
}
