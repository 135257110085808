export const CHALLENGE_MISSION_INFO = [
  {
    COLUMN: "제목",
    WIDTH: 20,
  },
  {
    COLUMN: "내용",
    WIDTH: 30,
  },
  {
    COLUMN: "진행 기간",
    WIDTH: 30,
  },
  {
    COLUMN: "제출 완료",
    WIDTH: 10,
  },
  {
    COLUMN: "승인 완료",
    WIDTH: 10,
  },
];
