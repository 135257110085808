import communityRepository from "@api/community/community.repository";
import ButtonText from "@component/common/ButtonText";
import ModalBox from "@component/common/ModalBox";
import RowBox from "@component/common/RowBox";
import { qnaDeleteModal, qnaOneData } from "@store/atom/qna";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";

export default function QNADeleteModal() {
  const [qna, setQNA] = useRecoilState(qnaOneData);
  const setDeleteModal = useSetRecoilState(qnaDeleteModal);
  const closeModal = () => {
    setDeleteModal(false);
    setQNA(null);
  };

  const onDelete = async () => {
    if (qna) {
      await communityRepository.deleteById(qna.id);
      setQNA(null);
    }

    setDeleteModal(false);
  };
  return (
    <ModalBox width={480}>
      <Text>게시물을 삭제하시겠습니까?</Text>
      <RowBox
        height="auto"
        style={{ justifyContent: "flex-end" }}
        marginTop={24}
      >
        <ButtonText
          onClick={closeModal}
          color="#8D8D8D"
          backgroundColor="#F5F5F5"
        >
          취소
        </ButtonText>
        <ButtonText
          onClick={onDelete}
          color="#FFFFFF"
          backgroundColor="#84C64A"
        >
          삭제
        </ButtonText>
      </RowBox>
    </ModalBox>
  );
}

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #323232;
`;
