import TableColumn from "@component/common/TableColumn";
import TableHeader from "@component/common/TableHeader";
import { BANNER_INFO } from "@constant/BANNER_INFO";

export default function BannerColumn() {
  return (
    <TableHeader>
      {BANNER_INFO.map(({ COLUMN, WIDTH }, index) => {
        return (
          <TableColumn key={index} width={WIDTH}>
            {COLUMN}
          </TableColumn>
        );
      })}
    </TableHeader>
  );
}
