export const QNA_INFO = [
  {
    COLUMN: "답변 여부",
    WIDTH: 20,
  },
  {
    COLUMN: "제목",
    WIDTH: 20,
  },
  {
    COLUMN: "작성자",
    WIDTH: 20,
  },
  {
    COLUMN: "회원번호",
    WIDTH: 20,
  },
  {
    COLUMN: "작성일시",
    WIDTH: 20,
  },
];
