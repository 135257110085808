export const APPROVE_INFO = [
  {
    COLUMN: "이름",
    WIDTH: 15,
  },
  {
    COLUMN: "이메일",
    WIDTH: 18,
  },

  {
    COLUMN: "성별",
    WIDTH: 10,
  },
  {
    COLUMN: "생년월일",
    WIDTH: 15,
  },
  {
    COLUMN: "주소",
    WIDTH: 18,
  },
  {
    COLUMN: "입력한 추천인 코드",
    WIDTH: 14,
  },
];
