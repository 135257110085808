import ModalBox from "@component/common/ModalBox";
import { ModalTitle } from "@component/common/ModalText";
import MultiButton from "@component/common/MultiButton";
import SearchBar from "@component/common/SearchBar";
import { useInput } from "@hook/useInput";
import useRecoilModal from "@hook/useRecoilModal";
import { MemberData } from "@interface/user";
import {
  selectedUserListData,
  signUpMemberSelectModal,
  signUpMessageModal,
} from "@store/atom/signUp";
import { useEffect, useState } from "react";

import MemberListBox from "./MemberListBox";
import Member from "./Member";
import RowBox from "@component/common/RowBox";
import MemberSelect from "./MemberSelect";
import userRepository from "@api/user/user.repository";
import { useRecoilState, useSetRecoilState } from "recoil";

export default function SignUpMemberSelectModal() {
  const { closeModal } = useRecoilModal(signUpMemberSelectModal);
  const [memberSearch, setMemberSearch, onChangeMemberSearch] = useInput("");
  const [members, setMembers] = useState<MemberData[]>([]);
  const [selectedMembers, setSelectedMembers] =
    useRecoilState(selectedUserListData);
  const setSignUpMessageModal = useSetRecoilState(signUpMessageModal);

  const onClickSearch = () => {
    userRepository.searchUserByKeyword(memberSearch).then((res) => {
      const newMembers = res.map((user) => {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          profileImage: user.profileImage,
          checked: false,
        };
      });
      setMembers(newMembers);
      setMemberSearch("");
    });
  };

  useEffect(() => {
    userRepository.getAllUsers().then((res) => {
      const newMembers = res.map((user) => {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          profileImage: user.profileImage,
          checked: false,
        };
      });
      setMembers(newMembers);
    });
  }, []);

  const onChangeMember = (id: string, checked: boolean) => {
    const index = members.findIndex((member) => member.id === id);
    setMembers((prev) => {
      const newMembers = [...prev];
      newMembers[index].checked = checked;
      return newMembers;
    });

    if (checked) {
      const exist = selectedMembers.find((member) => member.id === id);
      if (!exist) {
        setSelectedMembers((prev) => {
          const newMembers = [...prev];
          newMembers.push(Object.assign({}, members[index]));
          return newMembers;
        });
      }
    } else {
      setSelectedMembers((prev) => {
        return prev.filter((member) => member.id !== id);
      });
    }
  };

  return (
    <ModalBox width={480}>
      <ModalTitle>회원 선택</ModalTitle>
      <SearchBar
        value={memberSearch}
        placeholder="이름 검색"
        onChangeValue={onChangeMemberSearch}
        onClickSearch={onClickSearch}
      />
      <RowBox justifyContent="flex-start" marginTop={12} height="auto">
        {selectedMembers.map((member, index) => {
          return (
            <MemberSelect
              key={index}
              id={member.id}
              name={member.name}
              profileImage={member.profileImage}
            />
          );
        })}
      </RowBox>
      <MemberListBox>
        {members.map(({ checked, id, name, profileImage }, index) => {
          return (
            <Member
              key={index}
              checked={checked}
              name={name}
              src={profileImage}
              onChecked={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeMember(id, e.target.checked)
              }
            />
          );
        })}
      </MemberListBox>
      <MultiButton
        sub="취소"
        main="다음"
        onSubClick={() => {
          closeModal();
          setSelectedMembers([]);
        }}
        onMainClick={() => {
          closeModal();
          setSignUpMessageModal(true);
        }}
        marginTop={32}
      />
    </ModalBox>
  );
}
