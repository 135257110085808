import styled from "styled-components";

const PopperText = styled.div`
  cursor: pointer;

  width: 160px;
  padding: 8px;
  border: 0.5px solid #f5f5f5;
  background-color: white;
  font-size: 13px;
  font-weight: normal;

  &:hover {
    background-color: #f5faf1;
  }
`;

export default PopperText;
