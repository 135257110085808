import axios from "axios";
import userContextRepository from "./user/user.context.repository";
import { globalConfig } from "@config/globalConfig";

export const KEY_ACCESS_TOKEN = "accessToken";

const httpClient = axios.create({
  baseURL: globalConfig.baseURL,
});

httpClient.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${userContextRepository.getAccessToken()}`;
  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status) {
      if (error.response.status === 401) {
        userContextRepository.removeAccessToken();
        window.location.replace("/login"); // replace 사용
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
