import OptionBox from "@component/common/OptionBox";
import SearchBar from "@component/common/SearchBar";
import { useInput } from "@hook/useInput";

export default function ChallengeProveOption() {
  const [search, setSearch, onChangeSearch] = useInput("");
  const onClickSearch = () => {};
  const onOpenModal = () => {};
  return (
    <OptionBox>
      <SearchBar
        value={search}
        placeholder="챌린지 후기의 제목 및 내용을 입력해주세요"
        onChangeValue={onChangeSearch}
        onClickSearch={onClickSearch}
      />
    </OptionBox>
  );
}
