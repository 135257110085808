import { faker } from "@faker-js/faker";

export enum ChallengeStatus {
  BEFORE = "BEFORE",
  RECRUITMENT = "RECRUITMENT",
  ONGOING = "ONGOING",
  FINISHED = "FINISHED",
}

export enum ChallengeType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum MissionType {
  MISSION = "MISSION", // 미션
  CHECK = "CHECK", // 점검
  CHECK_FIRST = "CHECK_FIRST", // 점검(최초)
  CHECK_MIDDLE_1 = "CHECK_MIDDLE_1", // 점검(중간1)
  CHECK_MIDDLE_2 = "CHECK_MIDDLE_2", // 점검(중간2)
  CHECK_LAST_1 = "CHECK_LAST_1", // 점검(최종1)
  CHECK_LAST_2 = "CHECK_LAST_2", // 점검(최종2)
}

export enum ChallengeProveStatus {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  FAIL = "FAIL",
  SUCCESS = "SUCCESS",
  NOT_STARTED = "NOT_STARTED",
}

export interface ChallengeEntity {
  id: string;
  userId: string;
  title: string;
  description: string;
  content: string;
  proveMethod: string;
  price: number;
  startDate: Date;
  endDate: Date;
  paymentStartDate: Date;
  paymentEndDate: Date;
  isPackage: boolean;
  status: ChallengeStatus;
  challengeType: ChallengeType;
  notification: boolean;
  createdDate: Date;
  updatedDate: Date;
}

export interface ChallengeMissionEntity {
  id: string;
  challengeId: string;
  title: string;
  description: string | null;
  proveMethod: string | null;
  startDate: Date;
  endDate: Date;
  missionType: MissionType;
  createdDate: Date;
  updatedDate: Date;
}

export function createRandomChallenge(): ChallengeEntity {
  return {
    id: faker.datatype.uuid(),
    userId: faker.datatype.uuid(),
    title: faker.lorem.sentence(),
    description: faker.lorem.paragraph(),
    content: faker.lorem.paragraph(),
    proveMethod: faker.lorem.paragraph(),
    price: faker.datatype.number(),
    startDate: faker.date.future(),
    endDate: faker.date.future(),
    paymentStartDate: faker.date.future(),
    paymentEndDate: faker.date.future(),
    isPackage: faker.datatype.boolean(),
    status: faker.helpers.enumValue(ChallengeStatus),
    challengeType: faker.helpers.enumValue(ChallengeType),
    notification: faker.datatype.boolean(),
    createdDate: faker.date.past(),
    updatedDate: faker.date.past(),
  };
}

export class ChallengeHistoryAdminDto {
  constructor(data: ChallengeHistoryAdminDto) {
    this.title = data.title;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.midRank = data.midRank;
    this.midScore = data.midScore;
    this.finalRank = data.finalRank;
    this.finalScore = data.finalScore;
  }

  title: string;
  startDate: Date;
  endDate: Date;
  midRank: number;
  midScore: number;
  finalRank: number;
  finalScore: number;
}
