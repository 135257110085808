import { useNavigate } from "react-router-dom";
import Menu from "@component/menu/Menu";
import userContextRepository from "@api/user/user.context.repository";
import styled from "styled-components";
import { Avatar } from "@mui/material";
import Body from "./Body";
import Title from "./Title";
import Table from "./Table";
import TableBoard from "./TableBoard";

export default function Container({ title, children, column, option }: Props) {
  const navigate = useNavigate();
  const logout = async () => {
    userContextRepository.removeAccessToken();
    navigate("/login");
  };
  return (
    <Main>
      <Menu />
      <Body>
        <Row>
          <Title>{title}</Title>
          <Row style={{ width: "auto" }}>
            <LogoutButton onClick={logout}>로그아웃</LogoutButton>
            <Avatar>Coach</Avatar>
          </Row>
        </Row>
        <TableBoard>
          {option}
          <Table>
            {column}
            {children}
          </Table>
        </TableBoard>
      </Body>
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: row;

  width: 100vw;
  height: 100vh;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const LogoutButton = styled.button`
  width: 100px;
  height: 40px;

  background-color: #ffffff;
  border: 1px solid #84c64a;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  color: #84c64a;

  cursor: pointer;
  margin-right: 20px;
`;

interface Props {
  title: string;
  children: React.ReactNode;
  column?: React.ReactNode;
  option?: React.ReactNode;
}
