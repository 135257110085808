import styled from "styled-components";

export default function Body({ children }: Props) {
  return <Box>{children}</Box>;
}

const Box = styled.div`
  width: calc(100% - 255px);

  padding: 32px;
  background-color: #f5f5f5;
`;

interface Props {
  children: React.ReactNode;
}
