import httpClient from "@api/http";
import {
  ChallengeMissionListByChallengeAdminDto,
  UpdateChallengeMissionRequestDto,
} from "@interface/challenge";

class ChallengeMissionRepository {
  async updateChallengeMission(data: UpdateChallengeMissionRequestDto[]) {
    const { data: responseData } = await httpClient.put(
      `/challengeMission/admin`,
      { requests: data }
    );

    return responseData;
  }

  async deleteChallengeMission(id: string) {
    const { data: responseData } = await httpClient.delete(
      `/challengeMission/admin/${id}`
    );

    return responseData;
  }

  async findMissionStatusByChallengeIdAndUserId(
    challengeId: string,
    userId: string
  ) {
    const { data: responseData } = await httpClient.get(
      `/challengeMission/admin?challengeId=${challengeId}&userId=${userId}`
    );

    return responseData;
  }

  async findChallengeMissionByChallengeId(
    challengeId: string
  ): Promise<ChallengeMissionListByChallengeAdminDto[]> {
    const { data: responseData } = await httpClient.get(
      `/challengeMission/admin/all?challengeId=${challengeId}`
    );

    return responseData.map(
      (data: any) =>
        new ChallengeMissionListByChallengeAdminDto({
          ...data,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
        })
    );
  }
}

export default new ChallengeMissionRepository();
