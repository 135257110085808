import { useLocation } from "react-router-dom";
import Container from "@component/common/Container";
import useAuth from "@hook/useAuth";
import { Modal } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import ChallengeParticipantOption from "@component/challenge-participant/ChallengeParticipantOption";
import ChallengeParticipantColumn from "@component/challenge-participant/ChallengeParticipantColumn";
import ChallengeParticipantContent from "@component/challenge-participant/ChallengeParticipantContent";
import {
  challengeParticipantMessageModal,
  ChallengeParticipantModal,
  challengeParticipantModal,
} from "@store/atom/challenge-participant";
import { ChallengeParticipantMemberModal } from "@component/challenge-participant/modal/ChallengeParticipantMemberModal";
import { ChallengeParticipantMissionModal } from "@component/challenge-participant/modal/ChallengeParticipantMissionModal";
import useRecoilModal from "@hook/useRecoilModal";
import ChallengeParticipantMessageModal from "@component/challenge-participant/modal/ChallengeParticipantMessageModal";

export default function ChallengeParticipant() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title");
  const { checkAccessToken } = useAuth();
  const [challengeParticipant, setChallengeParticipant] = useRecoilState(
    challengeParticipantModal
  );
  const { modal: messageModal, closeModal: closeMessageModal } = useRecoilModal(
    challengeParticipantMessageModal
  );

  useEffect(() => {
    checkAccessToken();
  }, []);
  return (
    <>
      <Container
        title={title || "챌린지 참가자 관리"}
        option={<ChallengeParticipantOption />}
        children={<ChallengeParticipantContent />}
        column={<ChallengeParticipantColumn />}
      />
      <Modal open={messageModal} onClose={closeMessageModal}>
        <ChallengeParticipantMessageModal />
      </Modal>
      <Modal
        open={challengeParticipant === ChallengeParticipantModal.MEMBER}
        onClose={() => setChallengeParticipant(null)}
      >
        <ChallengeParticipantMemberModal />
      </Modal>
      <Modal
        open={challengeParticipant === ChallengeParticipantModal.MISSION}
        onClose={() => setChallengeParticipant(null)}
      >
        <ChallengeParticipantMissionModal />
      </Modal>
    </>
  );
}
