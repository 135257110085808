import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import PopperText from "@component/common/PopperText";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  selectedUserData,
  signUpHistoryModal,
  signUpInfoModal,
  signUpOneMessageModal,
  signUpUpdateModal,
  signUpModifyPasswordModal,
} from "@store/atom/signUp";
import { UserEntity } from "@entity/user.entity";
import { usePopper } from "@hook/usePopper";

export default function SignUpPopper({ user }: { user: UserEntity }) {
  const { anchorEl, setAnchorEl, open, id, handleClick } = usePopper();

  const setSelectedUser = useSetRecoilState<UserEntity | null>(
    selectedUserData
  );
  const [update, setUpdate] = useRecoilState(signUpUpdateModal);
  const [info, setInfo] = useRecoilState(signUpInfoModal);
  const [history, setHistory] = useRecoilState(signUpHistoryModal);
  const [oneMessage, setOneMessage] = useRecoilState(signUpOneMessageModal);
  const [modifyPassword, setModifyPassword] = useRecoilState(
    signUpModifyPasswordModal
  );

  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClick(event);
    if (open) {
      setUpdate(false);
      setInfo(false);
      setHistory(false);
      setOneMessage(false);
      setModifyPassword(false);
    }
  };

  const handleSelectUser = (modal: boolean) => {
    if (modal) {
      setSelectedUser(user);
    } else {
      setSelectedUser(null);
    }
  };

  const handleUpdateModal = () => {
    handleSelectUser(!update);
    setUpdate(!update);
    setAnchorEl(null);
  };

  const handleInfoModal = () => {
    handleSelectUser(!info);
    setInfo(!info);
    setAnchorEl(null);
  };

  const handleHistoryModal = () => {
    handleSelectUser(!history);
    setHistory(!history);
    setAnchorEl(null);
  };

  const handleOneMessageModal = () => {
    handleSelectUser(!oneMessage);
    setOneMessage(!oneMessage);
    setAnchorEl(null);
  };

  const handleModifyPasswordModal = () => {
    handleSelectUser(!modifyPassword);
    setModifyPassword(!modifyPassword);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleModalClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <PopperText onClick={handleUpdateModal}>회원정보 수정</PopperText>
        <PopperText onClick={handleInfoModal}>건강정보</PopperText>
        <PopperText onClick={handleHistoryModal}>챌린지 참여 기록</PopperText>
        <PopperText onClick={handleOneMessageModal}>메시지 보내기</PopperText>
        <PopperText onClick={handleModifyPasswordModal}>
          비밀번호 재설정
        </PopperText>
      </Popper>
    </div>
  );
}
