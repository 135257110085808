import styled from "styled-components";

const TableText = styled.td<{ width: number }>`
  font-size: 14px;
  color: #323232;

  width: ${(props) => props.width}%;
  text-align: left;
`;

export default TableText;
