import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import PopperText from "@component/common/PopperText";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  bannerDeleteModal,
  bannerOneData,
  bannerUpdateModal,
} from "@store/atom/banner";
import { BannerEntity } from "@entity/banner.entity";
import { usePopper } from "@hook/usePopper";

interface BannerPopperProps {
  banner: BannerEntity;
}

export default function BannerPopper({ banner }: BannerPopperProps) {
  const { anchorEl, setAnchorEl, open, id, handleClick } = usePopper();

  const setBanner = useSetRecoilState(bannerOneData);
  const [bannerUpdate, setBannerUpdate] = useRecoilState(bannerUpdateModal);
  const [bannerDelete, setBannerDelete] = useRecoilState(bannerDeleteModal);

  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClick(event);
    if (open) {
      setBannerUpdate(false);
      setBannerDelete(false);
    }
  };

  const handleUpdateBannerModal = () => {
    setBannerUpdate(!bannerUpdate);
    setAnchorEl(null);
    setBanner(banner);
  };

  const handleDeleteBannerModal = () => {
    setBannerDelete(!bannerDelete);
    setAnchorEl(null);
    setBanner(banner);
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleModalClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <PopperText onClick={handleUpdateBannerModal}>수정</PopperText>
        <PopperText onClick={handleDeleteBannerModal}>삭제</PopperText>
      </Popper>
    </div>
  );
}
