import { useState, useCallback, Dispatch, SetStateAction } from "react";

export function useDictInput<Dict>(
  initialValue: Dict
): [
  Dict,
  Dispatch<SetStateAction<Dict>>,
  (e: React.ChangeEvent<HTMLInputElement>) => void
] {
  const [value, setValue] = useState<typeof initialValue>(initialValue);
  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, [e.target.name]: e.target.value });
    },
    [value]
  );

  return [value, setValue, onChangeValue];
}
