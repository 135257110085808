import styled from "styled-components";

const ModalBox = styled.div<{ width: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  width: ${(props) => props.width}px;
  padding: 40px 32px;
  background-color: #fff;
  border-radius: 8px;

  overflow-y: auto;
  overflow-x: hidden;
`;

export default ModalBox;
