import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface SignUpProfileProps {
  image?: string;
  name?: string;
}

export default function SignUpProfile({ image, name }: SignUpProfileProps) {
  return (
    <div
      style={{
        width: "14.5%",

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {image ? (
        <img
          style={{
            width: "40px",
            height: "40px",
            maxWidth: "40px",
            maxHeight: "40px",
            borderRadius: "20px",
            marginRight: "12px",
          }}
          src={image}
          alt="profile"
        />
      ) : (
        <AccountCircleIcon
          style={{
            width: "40px",
            height: "40px",
            maxWidth: "40px",
            maxHeight: "40px",
            borderRadius: "20px",
            color: "#eeeeee",
            marginRight: "12px",
          }}
        />
      )}
      <span
        style={{
          fontSize: "14px",
          color: "#323232",
        }}
      >
        {name}
      </span>
    </div>
  );
}
