import styled from "styled-components";

const StandardInput = styled.input<{ height?: number | null }>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : 40)}px;
  padding: 0 12px;

  border: 1px solid #e5e5e5;
  border-radius: 4px;

  font-size: 12px;
  color: #323232;
`;

export default StandardInput;
