import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import StandardInput from "@component/common/StandardInput";
import RowBox from "@component/common/RowBox";
import ButtonText from "@component/common/ButtonText";
import useRecoilModal from "@hook/useRecoilModal";
import { ModalTitle } from "@component/common/ModalText";
import { GenderType, Role, UserEntity } from "@entity/user.entity";
import { useDictInput } from "@hook/useDictInput";
import { useState } from "react";
import {
  signUpModifyPasswordModal,
  selectedUserData,
} from "@store/atom/signUp";
import { useRecoilState } from "recoil";
import userRepository from "@api/user/user.repository";

export default function SignUpModifyPasswordModal() {
  const { closeModal } = useRecoilModal(signUpModifyPasswordModal);
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [selectMember, setSelectMember] = useRecoilState<UserEntity | null>(
    selectedUserData
  );

  function isPassword(password: string): boolean {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return passwordRegex.test(password);
  }

  const [user, setUser, onUserChange] = useDictInput(
    selectMember ?? {
      id: "",
      name: "",
      email: "",
      birthDate: null,
      gender: GenderType.NONE,
      phoneNumber: "",
      role: Role.USER,
      memberNumber: "",
      coach: "",
      height: "",
      memberCode: "",
      address: "",
      detailAddress: "",
      reference: "",
    }
  );

  const updateUserPassword = () => {
    if (isPassword(password) && password === checkPassword) {
      userRepository.changeUserPassword(user.id, password).then(() => {
        closeModal()
      })
      
    }
  };

  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>비밀번호 재설정</ModalTitle>
        <ModalComponent
          marginBottom={isPassword(password) ? 32 : 8}
          description="새 비밀번호"
          component={
            <StandardInput
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          }
        />
        {isPassword(password) ? (
          <></>
        ) : (
          <p
            style={{
              color: "red",
              marginBottom: 24,
            }}
          >
            유효하지 않은 비밀번호 입니다.
          </p>
        )}
        <ModalComponent
          marginBottom={password !== checkPassword ? 8 : 32}
          description="새 비밀번호 확인"
          component={
            <StandardInput
              name="checkPassword"
              value={checkPassword}
              onChange={(e) => {
                setCheckPassword(e.target.value);
              }}
            />
          }
        />
        {password !== checkPassword ? (
          <p
            style={{
              color: "red",
              marginBottom: 24,
            }}
          >
            비밀번호가 다릅니다.
          </p>
        ) : (
          <></>
        )}
        <RowBox height="auto" marginTop={0}>
          <ButtonText
            onClick={closeModal}
            color="#8D8D8D"
            backgroundColor="#F5F5F5"
          >
            취소
          </ButtonText>
          <ButtonText
            onClick={updateUserPassword}
            color="#FFFFFF"
            backgroundColor="#84C64A"
          >
            저장
          </ButtonText>
        </RowBox>
      </ModalBox>
    </>
  );
}
