import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface DateCalendarProps {
  label: string;
  value?: string | null;
  onChange?: (value: string | null) => void;
}

export default function DateCalendar({
  label,
  value,
  onChange,
}: DateCalendarProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          value={value}
          onChange={onChange}
          sx={{ width: "190px", fontSize: "14px" }}
          label={label}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
