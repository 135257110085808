import userContextRepository from "@api/user/user.context.repository";
import { useNavigate } from "react-router-dom";

function useAuth() {
  const navigate = useNavigate();

  const checkAccessToken = () => {
    const accessToken = userContextRepository.getAccessToken();
    if (accessToken === null) {
      userContextRepository.removeAccessToken();
      navigate("/login");
    }
  };

  return {
    checkAccessToken,
  };
}

export default useAuth;
