import { useRef, useState } from "react";

export default function useImageInput() {
  const [images, setImages] = useState<File[]>([]);
  const fileInput = useRef<HTMLInputElement>(null);
  const onFileUpload = () => {
    fileInput.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const uploadImages = [...images, ...Array.from(e.target.files)];
    setImages(uploadImages);
  };
  return { images, setImages, fileInput, onFileUpload, handleFileChange };
}
