import { useState } from "react";

export function usePopper() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? "popper" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement> | null) => {
    if (!event) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return {
    anchorEl,
    setAnchorEl,
    open,
    id,
    handleClick,
  };
}
