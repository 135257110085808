import {
  ChallengeEntity,
  ChallengeMissionEntity,
  MissionType,
} from "@entity/challenge.entity";

export interface ChallengeMission {
  id: string | null;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  missionType: string;
}

export interface ChallengeCreate {
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  paymentStartDate: Date;
  paymentEndDate: Date;
  price: number;
  missions: ChallengeMissionCreate[];
}

export interface ChallengeMissionCreate {
  id: string | null;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  missionType: MissionType;
}

export class ChallengeAdminDto {
  constructor({
    challenge,
    missions,
    participationNum,
    pictures,
  }: {
    challenge: ChallengeEntity;
    missions: ChallengeMissionEntity[];
    participationNum: number;
    pictures: { key: string; url: string }[];
  }) {
    this.challenge = challenge;
    this.missions = missions;
    this.participationNum = participationNum;
    this.pictures = pictures;
  }

  challenge: ChallengeEntity;
  missions: ChallengeMissionEntity[];
  participationNum: number;
  pictures: {
    key: string;
    url: string;
  }[];
}

export class UpdateChallengeDto {
  constructor(data: UpdateChallengeDto) {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.price = data.price;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.paymentStartDate = data.paymentStartDate;
    this.paymentEndDate = data.paymentEndDate;
  }

  id: string;
  title: string;
  description: string;
  price: number;
  startDate: Date;
  endDate: Date;
  paymentStartDate: Date;
  paymentEndDate: Date;
}

export class UpdateChallengeMissionRequestDto {
  constructor(data: UpdateChallengeMissionRequestDto) {
    this.id = data.id;
    this.challengeId = data.challengeId;
    this.title = data.title;
    this.description = data.description;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.missionType = data.missionType;
  }

  id: string | null;
  challengeId: string;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  missionType: MissionType;
}

export class ChallengeMissionListByChallengeAdminDto {
  constructor({
    missionId,
    title,
    description,
    startDate,
    endDate,
    submittedCount,
    checkedCount,
    missionType,
  }: ChallengeMissionListByChallengeAdminDto) {
    this.missionId = missionId;
    this.title = title;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.submittedCount = submittedCount;
    this.checkedCount = checkedCount;
    this.missionType = missionType;
  }

  missionId: string;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  submittedCount: number;
  checkedCount: number;
  missionType: MissionType;
}

export class ChallengeHistoryDto {
  constructor({
    id,
    title,
    startDate,
    endDate,
    middleRank,
    finalRank,
    middleScore,
    finalScore,
  }: ChallengeHistoryDto) {
    this.id = id;
    this.title = title;
    this.startDate = startDate;
    this.endDate = endDate;
    this.middleRank = middleRank;
    this.finalRank = finalRank;
    this.middleScore = middleScore;
    this.finalScore = finalScore;
  }

  id: string;
  title: string;
  startDate: Date;
  endDate: Date;
  middleRank: string;
  finalRank: string;
  middleScore: number;
  finalScore: number;
}
