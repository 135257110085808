import TableRow from "@component/common/TableRow";
import TableText from "@component/common/TableText";
import { CHALLENGE_INFO } from "@constant/CHALLENGE_INFO";
import ChallengePopper from "./ChallengePopper";
import { formatDateToYYYYMMDD } from "@util/date";
import { ChallengeAdminDto } from "@interface/challenge";

export default function ChallengeValue({ data }: { data: ChallengeAdminDto }) {
  return (
    <TableRow
      children={CHALLENGE_INFO.map(({ WIDTH }, i) => {
        return (
          <TableText key={i} width={WIDTH}>
            {makeIntoData(data, i)}
          </TableText>
        );
      })}
      popper={<ChallengePopper data={data} />}
    />
  );
}

function makeIntoData(data: ChallengeAdminDto, index: number) {
  const {
    challenge: {
      title,
      paymentEndDate,
      paymentStartDate,
      price,
      startDate,
      endDate,
    },
    participationNum,
  } = data;
  switch (index) {
    case 0:
      return title;
    case 1:
      return `${formatDateToYYYYMMDD(
        paymentStartDate
      )} ~ ${formatDateToYYYYMMDD(paymentEndDate)}`;
    case 2:
      return `${formatDateToYYYYMMDD(startDate)} ~ ${formatDateToYYYYMMDD(
        endDate
      )}`;
    case 3:
      return participationNum;
    case 4:
      return price;
    default:
      return null;
  }
}
