import styled from "@emotion/styled";
import SignUpProfile from "./SignUpProfile";
import TableText from "@component/common/TableText";
import TableRow from "@component/common/TableRow";
import SignUpPopper from "./SignUpPopper";
import { GenderType, Role, UserEntity } from "@entity/user.entity";
import { formatDateToYYYYMMDD } from "@util/date";
import { makeRankByRole } from "@util/make-rank-by-role";
import { makeNumIntoDigits } from "@util/number";

interface SignUpValueProps {
  user: UserEntity;
}

export default function SignUpValue({ user }: SignUpValueProps) {
  const { profileImage, name, memberCode, gender, birthDate, role, coach } =
    user;
  return (
    <>
      <TableRow
        children={
          <SignUpValueBox>
            <SignUpProfile image={profileImage} name={name} />
            <TableText width={14.5}>
              {makeNumIntoDigits(memberCode, 8)}
            </TableText>
            <TableText width={14.5}>
              {gender === GenderType.MAN ? "남성" : "여성"}
            </TableText>
            <TableText width={14.5}>{birthDate ? birthDate : ""}</TableText>
            <TableText width={14.5}>{makeRankByRole(role as Role)}</TableText>
            <TableText width={14.5}>{coach ?? ""}</TableText>
          </SignUpValueBox>
        }
        popper={<SignUpPopper user={user} />}
      />
    </>
  );
}

const SignUpValueBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  padding: 14px 0px;

  & + & {
    border-top: 0.5px solid #e5e5e5;
  }
`;
