import active from "./active-menu.png";
import inactive from "./inactive-menu.png";

export default function MenuIcon({ isActive }: { isActive: boolean }) {
  const path = isActive ? active : inactive;
  return (
    <img
      src={path}
      alt="menu"
      width={16}
      style={{
        marginRight: 24,
      }}
    />
  );
}
