import { QueryClientProvider } from "react-query";
import { queryClient } from "./query";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Banner from "@page/Banner";
import Approve from "@page/Approve";
import SignUp from "@page/SignUp";
import QNA from "@page/QNA";
import Post from "@page/Post";
import Challenge from "@page/Challenge";
import Login from "@page/Login";
import ChallengeParticipant from "@page/ChallengeParticipant";
import ChallengeMission from "@page/ChallengeMission";
import ChallengeProve from "@page/ChallengeProve";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Approve />} />
            <Route path="/login" element={<Login />} />
            <Route path="/approve" element={<Approve />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/qna" element={<QNA />} />
            <Route path="/post" element={<Post />} />
            <Route path="/challenge">
              <Route path="" element={<Challenge />} />
              <Route path="participant" element={<ChallengeParticipant />} />
              <Route path="mission">
                <Route path="" element={<ChallengeMission />} />
                <Route path=":id" element={<ChallengeProve />} />
              </Route>
            </Route>
            <Route path="/banner" element={<Banner />} />
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
