import Container from "@component/common/Container";
import SignUpChallengeHistoryModal from "@component/signup/modal/SignUpChallengeHistoryModal";
import SignUpColumn from "@component/signup/SignUpColumn";
import SignUpContent from "@component/signup/SignUpContent";
import SignUpHealthModal from "@component/signup/modal/SignUpHealthModal";
import SignUpMemberSelectModal from "@component/signup/modal/SignUpMemberSelectModal";
import SignUpMemberUpdateModal from "@component/signup/modal/SignUpMemberUpdateModal";
import SignUpMessageModal from "@component/signup/modal/SignUpMessageModal";
import SignUpOneMessageModal from "@component/signup/modal/SignUpOneMessageModal";
import SignUpFoodModal from "@component/signup/modal/SignUpFoodModal";
import SignUpModifyPasswordModal from "@component/signup/modal/SignUpModifyPasswordModal";
import SignUpOption from "@component/signup/SignUpOption";
import useAuth from "@hook/useAuth";
import useRecoilModal from "@hook/useRecoilModal";
import { Modal } from "@mui/material";
import {
  selectedFoodData,
  selectedUserListData,
  signUpHistoryModal,
  signUpInfoModal,
  signUpMemberSelectModal,
  signUpMessageModal,
  signUpOneMessageModal,
  signUpTotalMessageModal,
  signUpUpdateModal,
  signUpModifyPasswordModal,
} from "@store/atom/signUp";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import SignUpTotalMessageModal from "@component/signup/modal/SignUpTotalMessageModal";

export default function SignUp() {
  const { checkAccessToken } = useAuth();

  useEffect(() => {
    checkAccessToken();
  }, []);

  const selectedMembers = useSetRecoilState(selectedUserListData);
  const { modal: update, closeModal: closeUpdate } =
    useRecoilModal(signUpUpdateModal);
  const { modal: message, closeModal: closeMessage } =
    useRecoilModal(signUpMessageModal);
  const { modal: challenge, closeModal: closeChallenge } =
    useRecoilModal(signUpHistoryModal);
  const { modal: health, closeModal: closeHealth } =
    useRecoilModal(signUpInfoModal);
  const { modal: memberSelect, closeModal: closeMemberSelect } = useRecoilModal(
    signUpMemberSelectModal
  );
  const { modal: oneMessage, closeModal: closeOneMessage } = useRecoilModal(
    signUpOneMessageModal
  );
  const { modal: modifyPassword, closeModal: closeModifyPassword } =
    useRecoilModal(signUpModifyPasswordModal);
  const {
    modal: totalMessageModal,
    closeModal: closeTotalMessageModal,
  } = useRecoilModal(signUpTotalMessageModal);

  const [foodModal, setFoodModal] = useRecoilState(selectedFoodData);
  return (
    <>
      <Container
        title="회원 관리"
        option={<SignUpOption />}
        children={<SignUpContent />}
        column={<SignUpColumn />}
      />
      <Modal open={totalMessageModal} onClose={closeTotalMessageModal}>
        <SignUpTotalMessageModal />
      </Modal>
      <Modal open={update} onClose={closeUpdate}>
        <SignUpMemberUpdateModal />
      </Modal>
      <Modal
        open={message}
        onClose={() => {
          closeMessage();
          selectedMembers([]);
        }}
      >
        <SignUpMessageModal />
      </Modal>
      <Modal open={challenge} onClose={closeChallenge}>
        <SignUpChallengeHistoryModal />
      </Modal>
      <Modal open={health} onClose={closeHealth}>
        <SignUpHealthModal />
      </Modal>
      <Modal
        open={memberSelect}
        onClose={() => {
          closeMemberSelect();
          selectedMembers([]);
        }}
      >
        <SignUpMemberSelectModal />
      </Modal>
      <Modal
        open={oneMessage}
        onClose={() => {
          closeOneMessage();
          selectedMembers([]);
        }}
      >
        <SignUpOneMessageModal />
      </Modal>
      <Modal open={modifyPassword} onClose={closeModifyPassword}>
        <SignUpModifyPasswordModal />
      </Modal>
      <Modal
        open={!!foodModal}
        onClose={() => {
          setFoodModal(null);
        }}
      >
        <SignUpFoodModal />
      </Modal>
    </>
  );
}
