import styled from "styled-components";

const Text = styled.span<{ width?: string | null; bold?: boolean | null }>`
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: #323232;

  width: ${(props) => (props.width ? props.width : "100%")};
  text-align: left;
  overflow: none;
`;

export const SmallNormalText = styled.p`
  font-size: 10px;
  font-weight: normal;
  color: #323232;
`;

export default Text;
