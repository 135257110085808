import TableBody from "@component/common/TableBody";
import QNAValue from "./QNAValue";
import { useEffect } from "react";
import communityRepository from "@api/community/community.repository";
import {
  qnaDeleteModal,
  qnaDetailModal,
  qnaListData,
  qnaOneData,
} from "@store/atom/qna";
import { useRecoilState, useRecoilValue } from "recoil";

export default function QNAContent() {
  const qna = useRecoilValue(qnaOneData);
  const qnaDelete = useRecoilValue(qnaDeleteModal);
  const qnaDetail = useRecoilValue(qnaDetailModal);
  const [qnaList, setQnaList] = useRecoilState(qnaListData);

  useEffect(() => {
    if (!qnaDelete && !qnaDetail) {
      communityRepository.getQnaList().then((res) => {
        setQnaList(res);
      });
    }
  }, [qna, qnaDelete, qnaDetail]);
  return (
    <>
      <TableBody>
        {qnaList.map((qna, index) => {
          return <QNAValue key={index} qna={qna} />;
        })}
      </TableBody>
    </>
  );
}
