import TableBody from "@component/common/TableBody";
import SignUpValue from "./SignUpValue";
import { useEffect } from "react";
import userRepository from "@api/user/user.repository";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedUserData, userListData } from "@store/atom/signUp";
import { UserEntity } from "@entity/user.entity";

export default function SignUpContent() {
  const selectMember = useRecoilValue<UserEntity | null>(selectedUserData);
  const [userList, setUserList] = useRecoilState(userListData);
  useEffect(() => {
    userRepository.getAllUsers().then((res) => {
      setUserList(res);
    });
  }, [selectMember]);
  return (
    <>
      <TableBody>
        {userList.map((user) => {
          return <SignUpValue user={user} />;
        })}
      </TableBody>
    </>
  );
}
