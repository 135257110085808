import httpClient from "@api/http";
import { CommunityData, PutCommunityData } from "@entity/community.entity";

class CommunityRepository {
  async getQnaList(): Promise<CommunityData[]> {
    const response = await httpClient.get("/community/all/admin/qna");
    return response.data;
  }

  async getCommunityList(): Promise<CommunityData[]> {
    const response = await httpClient.get("/community/all/admin/type");
    return response.data;
  }

  async createCommunity(data: PutCommunityData) {
    const formData = new FormData();
    formData.append("content", data.content);
    formData.append("title", data.title);
    formData.append("communityType", data.communityType);
    data.pictures.forEach((picture) => {
      formData.append("files", picture);
    });

    const response = await httpClient.post("/community/admin", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async updateCommunityById(id: string, data: PutCommunityData) {
    const formData = new FormData();
    formData.append("content", data.content);
    formData.append("title", data.title);
    formData.append("communityType", data.communityType);
    data.pictures.forEach((picture) => {
      formData.append("files", picture);
    });

    const response = await httpClient.put(`/community/admin/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async deleteById(id: string) {
    return await httpClient.delete(`/community/admin/${id}`);
  }
}

export default new CommunityRepository();
