import React from "react";
import Popper from "@mui/material/Popper";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import PopperText from "@component/common/PopperText";
import {
  ChallengeModal,
  challengeInfoModal,
  selectedChallenge,
} from "@store/atom/challenge";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { usePopper } from "@hook/usePopper";
import { ChallengeAdminDto } from "@interface/challenge";

export default function ChallengePopper({ data }: { data: ChallengeAdminDto }) {
  const navigate = useNavigate();
  const { anchorEl, setAnchorEl, open, id, handleClick } = usePopper();
  const setChallengeInfo = useSetRecoilState(challengeInfoModal);
  const setSelectedChallenge = useSetRecoilState(selectedChallenge);

  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClick(event);
    if (open) {
      setChallengeInfo(null);
      setSelectedChallenge(null);
    }
  };

  const handleChallengeInfoModal = () => {
    setAnchorEl(null);
    setSelectedChallenge(data);
    setChallengeInfo(ChallengeModal.UPDATE);
  };

  const handleChallengeParticipant = () => {
    setAnchorEl(null);
    navigate(
      `/challenge/participant?title=챌린지 관리 > ${data.challenge.title} 참가자 관리&id=${data.challenge.id}`
    );
  };

  const handleChallengeMission = () => {
    setAnchorEl(null);
    navigate(
      `/challenge/mission?title=챌린지 관리 > ${data.challenge.title} 미션 관리&id=${data.challenge.id}`
    );
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <PopperText onClick={handleChallengeInfoModal}>챌린지 수정</PopperText>
        <PopperText onClick={handleChallengeParticipant}>
          참여자 관리
        </PopperText>
        <PopperText onClick={handleChallengeMission}>미션 관리</PopperText>
      </Popper>
    </div>
  );
}
