import httpClient from "@api/http";
import { BannerEntity } from "@entity/banner.entity";
import { UpdateBannerOrderAndTitle } from "@interface/banner";

class BannerRepository {
  async getBanner(): Promise<BannerEntity[]> {
    const response = await httpClient.get("/banner/admin");
    return response.data;
  }

  async createBanner(order: number, title: string, imageFile: any) {
    const formData = new FormData();
    formData.append("order", order.toString());
    formData.append("title", title);
    formData.append("file", imageFile);

    const response = await httpClient.post("/banner", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async updateOrderAndBanner({
    id,
    order,
    title,
  }: UpdateBannerOrderAndTitle): Promise<BannerEntity> {
    const response = await httpClient.patch(`/banner/${id}`, { order, title });
    return response.data;
  }

  async deleteBannerById(id: string) {
    await httpClient.delete(`/banner/${id}`);
  }
}

export default new BannerRepository();
