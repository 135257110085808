import TableBody from "@component/common/TableBody";
import TableRow from "@component/common/TableRow";
import TableText from "@component/common/TableText";
import ChallengeParticipantPopper from "./ChallengeParticipantPopper";
import { CHALLENGE_PARTICIPANT_INFO } from "@constant/CHALLENGE_PARTICIPANT_INFO";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { challengeParticipantModal } from "@store/atom/challenge-participant";
import challengeParticipationRepository from "@api/challenge/challenge-participation.repository";
import { ChallengeParticipationAdminDto } from "@interface/challenge-participant";

export default function ChallengeParticipantContent() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const modal = useRecoilValue(challengeParticipantModal);
  const [participants, setParticipants] = useState<
    ChallengeParticipationAdminDto[]
  >([]);

  useEffect(() => {
    if (id) {
      challengeParticipationRepository.findAllByChallengeId(id).then((res) => {
        setParticipants(res);
      });
    }
  }, [modal]);
  return (
    <>
      <TableBody>
        {participants.map((participant, i) => {
          return (
            <TableRow
              key={i}
              children={CHALLENGE_PARTICIPANT_INFO.map(({ COLUMN, WIDTH }) => {
                return (
                  <TableText key={COLUMN} width={WIDTH}>
                    {findParticipantValue(participant, COLUMN)}
                  </TableText>
                );
              })}
              popper={<ChallengeParticipantPopper data={participant} />}
            />
          );
        })}
      </TableBody>
    </>
  );
}

function findParticipantValue(
  data: ChallengeParticipationAdminDto,
  column: string
) {
  switch (column) {
    case "참여자명":
      return data.name;
    case "회원번호":
      return data.memberCode.toString().padStart(8, "0");
    case "미션 달성률":
      return (data.missionSuccessRate * 100).toFixed(1);
    case "중간 순위/최종 순위":
      return `${data.middleRank ?? "-"}/${data.finalRank ?? "-"}`;
    case "중간 점수/최종 점수":
      return `${data.middleScore ?? "-"}/${data.finalScore ?? "-"}`;
    default:
      return "0";
  }
}
