import styled from "styled-components";

interface ImageInputProps {
  marginLeft?: number | null;
  fileInput: React.RefObject<HTMLInputElement>;
  onFileUpload: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ImageInput({
  marginLeft,
  fileInput,
  onFileUpload,
  handleChange,
}: ImageInputProps) {
  return (
    <ImageInputBox marginLeft={marginLeft}>
      <input
        multiple
        accept="image/*"
        onChange={handleChange}
        ref={fileInput}
        style={{ display: "none" }}
        type="file"
      />
      <div
        onClick={onFileUpload}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          border: "1px solid #E0E0E0",
          cursor: "pointer",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "32px",
        }}
      >
        +
      </div>
    </ImageInputBox>
  );
}

const ImageInputBox = styled.div<{
  width?: number | null;
  height?: number | null;
  marginLeft?: number | null;
}>`
  min-width: ${(props) => (props.width ? props.width : 150 + "px")};
  min-height: ${(props) => (props.height ? props.height : 150 + "px")};
  height: ${(props) => (props.height ? props.height : 150 + "px")};

  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
