import MemberProfile from "./MemberProfile";
import { SmallNormalText } from "@component/common/Text";

interface MemberSelectProps {
  id: string;
  profileImage?: string;
  name: string;
}

export default function MemberSelect({
  id,
  profileImage,
  name,
}: MemberSelectProps) {
  return (
    <div
      key={id}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        marginRight: "12px",
      }}
    >
      <MemberProfile src={profileImage} />
      <SmallNormalText style={{ marginTop: "4px" }}>{name}</SmallNormalText>
    </div>
  );
}
