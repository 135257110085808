import TableBody from "@component/common/TableBody";
import ApproveValue from "./ApproveValue";
import { useValue } from "@hook/useValue";
import { useEffect } from "react";
import { GenderType, UserEntity } from "@entity/user.entity";
import { formatDateToYYYYMMDD } from "@util/date";
import userRepository from "@api/user/user.repository";
import { useRecoilValue } from "recoil";
import { approvePopper } from "@store/atom/approve";

export default function ApproveContent() {
  const popper = useRecoilValue(approvePopper);
  const { value: users, setValue: setUsers } = useValue<UserEntity[]>([]);

  useEffect(() => {
    userRepository.getUnapprovedUsers().then((res) => {
      setUsers(res);
    });
  }, [popper]);
  return (
    <>
      <TableBody>
        {users.map(
          ({
            id,
            name,
            email,
            gender,
            birthDate,
            phoneNumber,
            address,
            detailAddress,
            reference,
          }) => {
            return (
              <ApproveValue
                key={id}
                userId={id}
                name={name}
                email={email}
                gender={gender === GenderType.MAN ? "남성" : "여성"}
                birth={
                  birthDate ? birthDate : ""
                }
                phone={phoneNumber}
                address={address + " " + detailAddress}
                reference={reference}
              />
            );
          }
        )}
      </TableBody>
    </>
  );
}
