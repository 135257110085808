import React from "react";
import Popper from "@mui/material/Popper";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import PopperText from "@component/common/PopperText";
import { usePopper } from "@hook/usePopper";
import { useSetRecoilState } from "recoil";
import {
  ChallengeProveModal,
  challengeProveModal,
  selectedProve,
} from "@store/atom/challenge-prove";
import { ChallengeProveListByMissionAdminResponseDto } from "@interface/challenge-prove";
import { MissionType } from "@entity/challenge.entity";

export default function ChallengeProvePopper({
  data,
}: {
  data: ChallengeProveListByMissionAdminResponseDto;
}) {
  const { anchorEl, setAnchorEl, open, id, handleClick } = usePopper();
  const setChallengeProveModal = useSetRecoilState(challengeProveModal);
  const setSelectedProve = useSetRecoilState(selectedProve);

  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClick(event);
    if (open) {
      setChallengeProveModal(null);
      setSelectedProve(null);
    }
  };

  const onProveCheckClick = () => {
    setAnchorEl(null);
    setChallengeProveModal(ChallengeProveModal.PROVE_CHECK);
    setSelectedProve(data.id);
  };

  const onProveManageClick = () => {
    setAnchorEl(null);
    setChallengeProveModal(ChallengeProveModal.PROVE_MANAGE);
    setSelectedProve(data.id);
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleModalClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <PopperText onClick={onProveCheckClick}>제출 확인</PopperText>
        {data.missionType === MissionType.MISSION ? (
          <PopperText onClick={onProveManageClick}>인증후기 관리</PopperText>
        ) : (
          <></>
        )}
      </Popper>
    </div>
  );
}
