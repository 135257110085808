import httpClient from "@api/http";
import {
  ChallengeProveInfoAdminResponseDto,
  ChallengeProveListByMissionAdminResponseDto,
  IChallengeProveCommentDeleteData,
  UpdateChallengeProveRequestAdminDto,
} from "@interface/challenge-prove";

class ChallengeProveRepository {
  async findAllChallengeProveListByAdmin(
    missionId: string
  ): Promise<ChallengeProveListByMissionAdminResponseDto[]> {
    const { data: responseData } = await httpClient.get(
      `/challengeProve/admin?missionId=${missionId}`
    );
    return responseData.map(
      (data: any) =>
        new ChallengeProveListByMissionAdminResponseDto({
          ...data,
          provedDate: new Date(data.provedDate),
        })
    );
  }

  async findChallengeProveInfoAdminResponseDtobyId(id: string) {
    const { data } = await httpClient.get(`/challengeProve/admin/${id}`);
    return new ChallengeProveInfoAdminResponseDto({
      ...data,
      weight: Number(data.weight),
      muscle: Number(data.muscle),
      fat: Number(data.fat),
      fatRate: Number(data.fatRate),
    });
  }

  async updateChallengeProveInfoAdminResponseDto(
    data: UpdateChallengeProveRequestAdminDto
  ) {
    const { data: responseData } = await httpClient.patch(
      `/challengeProve/admin/${data.id}`,
      data
    );

    return responseData;
  }

  async deleteCommentById(deleteData: IChallengeProveCommentDeleteData) {
    try {
      console.log(deleteData);
      await httpClient.delete(
        `/challengeProve/admin/${deleteData.challengeProveId}/comments`,
        { data: { id: deleteData.challengeProveCommentId } }
      );
      console.log(
        `Comment ${deleteData.challengeProveCommentId} deleted successfully.`
      );
    } catch (error) {
      console.error(
        `Failed to delete comment ${deleteData.challengeProveCommentId}:`,
        error
      );
      throw error;
    }
  }
}

export default new ChallengeProveRepository();
