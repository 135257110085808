import styled from "styled-components";

const MemberListBox = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default MemberListBox;
