import httpClient from "@api/http";

export default class AuthRepository {
  async signIn(email: string, password: string) {
    const response = await httpClient.post("/auth/admin/login", {
      email,
      password,
    });

    return response.data;
  }
}
