export interface FoodEntity {
  id: string;
  userId: string;
  memo: string;
  urls: { url: string; key: string }[];
  category: FoodCategory;
  historyDate: Date;
  createdDate: Date;
  updatedDate: Date;
}

export enum FoodCategory {
  BREAKFAST = "BREAKFAST",
  LUNCH = "LUNCH",
  DINNER = "DINNER",
  SNACK = "SNACK",
  WATER = "WATER",
  NUTRIENT = "NUTRIENT",
}
