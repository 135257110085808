import styled from "styled-components";

const TableHeader = styled.thead`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid #f5f5f5;

  width: 100%;
  padding: 8px 4px;
  padding-right: 24px;
`;

export default TableHeader;
