import styled from "styled-components";

export default function TableRow({
  children,
  popper,
  onClick,
}: {
  children: React.ReactNode;
  popper: React.ReactNode | null;
  onClick?: () => void;
}) {
  return (
    <Row onClick={onClick}>
      <RowContent>{children}</RowContent>
      <div style={{ maxWidth: "20px", width: "20px" }}>{popper ?? <></>}</div>
    </Row>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #f5f5f5;

  width: 100%;
  padding: 12px 4px;
`;

const RowContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
