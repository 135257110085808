import styled from "styled-components";

const TableBody = styled.tbody`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  overflow-y: scroll;
`;

export default TableBody;
