import { ChallengeProveStatus, MissionType } from "@entity/challenge.entity";

export class ChallengeProveListByMissionAdminResponseDto {
  constructor({
    id,
    name,
    memberCode,
    status,
    provedDate,
    missionType,
    info,
  }: ChallengeProveListByMissionAdminResponseDto) {
    this.id = id;
    this.name = name;
    this.memberCode = memberCode;
    this.status = status;
    this.provedDate = provedDate;
    this.missionType = missionType;
    this.info = info;
  }

  id: string;
  name: string;
  memberCode: string;
  status: ChallengeProveStatus;
  provedDate: Date;
  missionType: MissionType;
  info: Info;
}

export interface Info {
  weight: number;
  muscle: number;
  fat: number;
  fatRate: number;
  likeCount: number;
  commentCount: number;
}

export class ChallengeProveInfoAdminResponseDto {
  constructor({
    id,
    urls,
    memo,
    status,
    reason,
    weight,
    muscle,
    fat,
    fatRate,
    basicScore,
    delayScore,
    etcScore,
    additionalScore,
    activities,
    comments,
    missionType,
  }: ChallengeProveInfoAdminResponseDto) {
    this.id = id;
    this.urls = urls;
    this.memo = memo;
    this.status = status;
    this.reason = reason;
    this.weight = weight;
    this.muscle = muscle;
    this.fat = fat;
    this.fatRate = fatRate;
    this.basicScore = basicScore;
    this.delayScore = delayScore;
    this.etcScore = etcScore;
    this.additionalScore = additionalScore;
    this.activities = activities;
    this.comments = comments;
    this.missionType = missionType;
  }

  id: string;
  urls: string[] = [];
  memo: string;
  status: ChallengeProveStatus;
  reason: string | null;
  weight: number;
  muscle: number;
  fat: number;
  fatRate: number;
  basicScore: number;
  delayScore: number;
  etcScore: number | string;
  additionalScore: number | string;
  activities: string[];
  comments: ChallengeProveComment[] = [];
  missionType: MissionType;
}

export interface ChallengeProveComment {
  id: string;
  name: string;
  content: string;
  alertEmails: string[];
}
export class UpdateChallengeProveRequestAdminDto {
  constructor({
    id,
    status,
    reason,
    additionalScore,
    etcScore,
  }: UpdateChallengeProveRequestAdminDto) {
    this.id = id;
    this.status = status;
    this.reason = reason;
    this.additionalScore = additionalScore;
    this.etcScore = etcScore;
  }

  id: string;
  status: ChallengeProveStatus;
  reason: string | null;
  additionalScore: number;
  etcScore: number;
}

export interface IChallengeProveCommentDeleteData {
  challengeProveId: string;
  challengeProveCommentId: string;
}
