import styled from "styled-components";

const RowBox = styled.div<{
  justifyContent?: string | null;
  marginTop?: number | null;
  height?: string | null;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};

  width: 100%;
  height: ${(props) => (props.height ? props.height : 150 + "px")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  overflow-x: auto;
`;

export default RowBox;
