import commentRepository from "@api/comment/comment.repository";
import ButtonText from "@component/common/ButtonText";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import { ModalTitle } from "@component/common/ModalText";
import RowBox from "@component/common/RowBox";
import { StandardImage } from "@component/common/StandardImage";
import StandardInput from "@component/common/StandardInput";
import StandardTextArea from "@component/common/StandardTextArea";
import { CommunityData } from "@entity/community.entity";
import { useInput } from "@hook/useInput";
import { qnaDetailModal, qnaOneData } from "@store/atom/qna";
import { useRecoilState, useSetRecoilState } from "recoil";

export default function QNADetailModal() {
  const [qna, setQNA] = useRecoilState<CommunityData | null>(qnaOneData);
  const setQNADetailModal = useSetRecoilState(qnaDetailModal);

  const [comment, setComment, _] = useInput(qna?.comments[0]?.content ?? "");

  const closeModal = () => {
    setQNADetailModal(false);
    setQNA(null);
  };

  const onSave = async () => {
    if (qna && comment.length > 0) {
      await commentRepository.putComment(qna.id, comment);
    }

    setQNADetailModal(false);
  };
  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>상세보기</ModalTitle>
        <ModalComponent
          marginBottom={32}
          description="질문 제목"
          component={<StandardInput disabled value={qna?.title} />}
        />
        <ModalComponent
          marginBottom={32}
          description="질문 내용"
          component={
            <StandardTextArea disabled value={qna?.content} height={120} />
          }
        />
        <ModalComponent
          marginBottom={32}
          description="사진"
          component={
            <RowBox justifyContent="flex-start">
              {qna?.pictures.map((image, index) => {
                return (
                  <StandardImage
                    onClick={() => {
                      var newTab = window.open();
                      if (newTab) {
                        newTab.document.body.innerHTML = `<img src="${image}" height="100%" />`;
                      }
                    }}
                    key={index}
                    src={image}
                    alt="image"
                  />
                );
              })}
            </RowBox>
          }
        />
        <ModalComponent
          marginBottom={32}
          description="답변"
          component={
            <StandardTextArea
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              height={120}
            />
          }
        />
        <RowBox height="auto">
          <ButtonText
            onClick={closeModal}
            color="#8D8D8D"
            backgroundColor="#F5F5F5"
          >
            취소
          </ButtonText>
          <ButtonText
            onClick={onSave}
            color="#FFFFFF"
            backgroundColor="#84C64A"
          >
            저장
          </ButtonText>
        </RowBox>
      </ModalBox>
    </>
  );
}
