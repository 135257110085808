import Container from "@component/common/Container";
import useAuth from "@hook/useAuth";
import ChallengeProveColumn from "@component/challenge-prove/ChallengeProveColumn";
import ChallengeProveOption from "@component/challenge-prove/ChallengeProveOption";
import ChallengeProveContent from "@component/challenge-prove/ChallengeProveContent";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Modal } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  ChallengeProveModal,
  challengeProveModal,
} from "@store/atom/challenge-prove";
import { ChallengeProveCheckModal } from "@component/challenge-prove/modal/ChallengeProveCheckModal";
import { ChallengeProveManageModal } from "@component/challenge-prove/modal/ChallengeProveManageModal";
import { ChallengeProveCommentDeleteModal } from "@component/challenge-prove/modal/ChallengeProveCommentDeleteModal";

export default function ChallengeProve() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title");

  const [proveModal, setProveModal] = useRecoilState(challengeProveModal);
  const { checkAccessToken } = useAuth();

  useEffect(() => {
    checkAccessToken();
  }, []);
  return (
    <>
      <Container
        title={title || "미션 후기 관리"}
        option={<ChallengeProveOption />}
        children={<ChallengeProveContent />}
        column={<ChallengeProveColumn />}
      />
      <Modal
        open={proveModal === ChallengeProveModal.PROVE_CHECK}
        onClose={() => setProveModal(null)}
      >
        <ChallengeProveCheckModal />
      </Modal>
      <Modal
        open={proveModal === ChallengeProveModal.PROVE_MANAGE}
        onClose={() => setProveModal(null)}
      >
        <ChallengeProveManageModal />
      </Modal>
      <Modal
        open={proveModal === ChallengeProveModal.PROVE_COMMENT_DELETE}
        onClose={() => setProveModal(null)}
      >
        <ChallengeProveCommentDeleteModal />
      </Modal>
    </>
  );
}
