import ButtonText from "@component/common/ButtonText";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import { ModalTitle } from "@component/common/ModalText";
import RowBox from "@component/common/RowBox";
import StandardInput from "@component/common/StandardInput";
import { Box } from "@mui/material";
import {
  challengeParticipantModal,
  selectedChallengeParticipant,
} from "@store/atom/challenge-participant";
import { formatDateToYYYYMMDD } from "@util/date";
import { useRecoilState, useSetRecoilState } from "recoil";

export function ChallengeParticipantMemberModal() {
  const [data, setData] = useRecoilState(selectedChallengeParticipant);
  const setChallengeParticipantMemberModal = useSetRecoilState(
    challengeParticipantModal
  );
  const closeModal = () => {
    setChallengeParticipantMemberModal(null);
    setData(null);
  };
  return (
    <ModalBox width={480}>
      <ModalTitle>회원정보 확인</ModalTitle>
      <RowBox height="auto" justifyContent="space-between">
        <ModalComponent
          description="이름"
          component={<StandardInput disabled name="name" value={data?.name} />}
        />
        <Box sx={{ width: "12px" }} />
        <ModalComponent
          description="결제 금액"
          component={
            <StandardInput
              disabled
              name="paymentPrice"
              value={data?.paymentPrice}
            />
          }
        />
      </RowBox>
      <RowBox marginTop={20} height="auto" justifyContent="space-between">
        <ModalComponent
          description="생년월일"
          component={
            <StandardInput disabled name="birthDate" value={data?.birthDate} />
          }
        />
        <Box sx={{ width: "12px" }} />
        <ModalComponent
          description="성별"
          component={
            <StandardInput disabled name="gender" value={data?.gender} />
          }
        />
      </RowBox>
      <RowBox marginTop={20} height="auto" justifyContent="space-between">
        <ModalComponent
          description="회원번호"
          component={
            <StandardInput
              disabled
              name="memberCode"
              value={data?.memberCode.toString().padStart(8, "0")}
            />
          }
        />
        <Box sx={{ width: "12px" }} />
        <ModalComponent
          description="담당코치"
          component={
            <StandardInput disabled name="coach" value={data?.coach} />
          }
        />
      </RowBox>
      <RowBox marginTop={20} height="auto" justifyContent="space-between">
        <ModalComponent
          description="연속 도전 횟수"
          component={
            <StandardInput
              disabled
              name="challengeCountOfContinuity"
              value={data?.challengeCountOfContinuity}
            />
          }
        />
        <Box sx={{ width: "12px" }} />
        <ModalComponent
          description="결제 일시"
          component={
            <StandardInput
              disabled
              name="paymentDate"
              value={formatDateToYYYYMMDD(data!!.paymentDate)}
            />
          }
        />
      </RowBox>
      <RowBox marginTop={20} height="auto" justifyContent="space-between">
        <ModalComponent
          description="등급"
          component={<StandardInput disabled name="role" value={data?.role} />}
        />
      </RowBox>
      <ModalComponent
        marginTop={20}
        description="주소"
        component={
          <StandardInput disabled name="address" value={data?.address} />
        }
      />
      <RowBox height="auto">
        <ButtonText
          style={{ marginTop: "32px" }}
          onClick={closeModal}
          color="#FFFFFF"
          backgroundColor="#84C64A"
        >
          확인
        </ButtonText>
      </RowBox>
    </ModalBox>
  );
}
