import httpClient from "@api/http";
import { ChallengeEntity } from "@entity/challenge.entity";
import {
  ChallengeAdminDto,
  ChallengeCreate,
  UpdateChallengeDto,
} from "@interface/challenge";

class ChallengeRepository {
  async findAllChallenges(): Promise<ChallengeAdminDto[]> {
    const { data } = await httpClient.get("/challenge/admin");
    return data.map((challenge: any) => new ChallengeAdminDto(challenge));
  }

  async createAdmin(
    data: ChallengeCreate
  ): Promise<ChallengeAdminDto> {
    const { data: responseData } = await httpClient.post(
      "/challenge/admin",
      data
    );

    return new ChallengeAdminDto(responseData);
  }

  async updateAdmin(dto: UpdateChallengeDto): Promise<ChallengeEntity> {
    const { data: responseData } = await httpClient.patch(
      `/challenge/admin/${dto.id}`,
      dto
    );

    return responseData;
  }
}

export default new ChallengeRepository();
