import TableColumn from "@component/common/TableColumn";
import TableHeader from "@component/common/TableHeader";
import { POST_INFO } from "@constant/POST_INFO";

export default function PostColumn() {
  return (
    <TableHeader>
      {POST_INFO.map(({ COLUMN, WIDTH }, index) => {
        return (
          <TableColumn key={index} width={WIDTH}>
            {COLUMN}
          </TableColumn>
        );
      })}
    </TableHeader>
  );
}
