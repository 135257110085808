import DateCalendar from "@component/common/DateCalendar";
import ModalBox from "@component/common/ModalBox";
import { ModalTitle } from "@component/common/ModalText";
import RowBox from "@component/common/RowBox";
import TabText from "@component/common/TabText";
import Text from "@component/common/Text";
import HEALTH_TAB from "@constant/HEALTH_TAB";
import { useValue } from "@hook/useValue";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";
import SignUpTable from "../SignUpTable";
import { ActivityColumn, BodyColumn, MealColumn } from "@interface/signup";
import { SIGN_UP_INFO_COLUMN } from "@constant/SIGN_UP_INFO_COLUMN";
import { useEffect, useState } from "react";
import bodyRepository from "@api/body/body.repository";
import { selectedUserData } from "@store/atom/signUp";
import { formatDateToYYYYMMDD } from "@util/date";
import { useRecoilValue } from "recoil";
import foodRepository from "@api/food/food.repository";
import { FoodCategory, FoodEntity } from "@entity/food.entity";
import activityRepository from "@api/activity/activity.repository";
import { ActivityType } from "@entity/activity.entity";

export default function SignUpHealthModal() {
  const selectedUser = useRecoilValue(selectedUserData);
  const { value: tab, onChangeValue: onChangeTab } = useValue<string>(
    HEALTH_TAB.BODY
  );
  const { value: startDate, onChangeValue: onChangeStartDate } =
    useValue<Dayjs | null>(null);
  const { value: endDate, onChangeValue: onChangeEndDate } =
    useValue<Dayjs | null>(null);
  const getColumn = (tab: string) => {
    switch (tab) {
      case HEALTH_TAB.BODY:
        return SIGN_UP_INFO_COLUMN.BODY;
      case HEALTH_TAB.MEAL:
        return SIGN_UP_INFO_COLUMN.MEAL;
      case HEALTH_TAB.ACTIVITY:
        return SIGN_UP_INFO_COLUMN.ACTIVITY;
      default:
        return SIGN_UP_INFO_COLUMN.BODY;
    }
  };

  const [data, setData] = useState<
    BodyColumn[] | MealColumn[] | ActivityColumn[]
  >([]);

  useEffect(() => {
    if (selectedUser) {
      if (tab === HEALTH_TAB.BODY) {
        bodyRepository.findAllByUserId(selectedUser.id).then((res) => {
          const newBody = res.map((body) => {
            return {
              date: formatDateToYYYYMMDD(new Date(body.historyDate)),
              weight: body.weight,
              fat: body.fat,
              fatRate: body.fatRate,
              muscle: body.muscle,
            };
          });
          setData(newBody);
        });
      }
      if (tab === HEALTH_TAB.MEAL) {
        foodRepository.findAllByUserId(selectedUser.id).then((res) => {
          const newMeal = foodsIntoMealData(res);
          setData(newMeal);
        });
      }

      if (tab === HEALTH_TAB.ACTIVITY) {
        activityRepository.findAllByUserId(selectedUser.id).then((res) => {
          const newActivity: ActivityColumn[] = res.map((activity) => {
            let time = 0;
            if (activity.endDate && activity.startDate) {
              const endDate = dayjs(activity.endDate);
              time = endDate.diff(activity.startDate, "minute");
            }
            return {
              date: formatDateToYYYYMMDD(new Date(activity.historyDate)),
              picture: activity.pictureUrl ?? "",
              name: activity.name,
              memo: activity.memo ?? "",
              time: `${time}분`,
              result:
                activity.type !== ActivityType.NORMAL
                  ? activity.isCompleted
                    ? "O"
                    : "X"
                  : "O",
            };
          });
          setData(newActivity);
        });
      }
    }
  }, [selectedUser, tab]);

  const onSearch = () => {
    if (selectedUser && startDate && endDate && startDate.isBefore(endDate)) {
      if (tab === HEALTH_TAB.BODY) {
        bodyRepository
          .findAllByUserIdAndDateBetween(
            selectedUser.id,
            startDate.format("YYYY-MM-DD"),
            endDate.format("YYYY-MM-DD")
          )
          .then((res) => {
            const newBody = res.map((body) => {
              return {
                date: formatDateToYYYYMMDD(new Date(body.historyDate)),
                weight: body.weight,
                fat: body.fat,
                fatRate: body.fatRate,
                muscle: body.muscle,
              };
            });
            setData(newBody);
          });
      }
      if (tab === HEALTH_TAB.MEAL) {
        foodRepository
          .findAllByUserIdAndDateBetween(
            selectedUser.id,
            startDate.format("YYYY-MM-DD"),
            endDate.format("YYYY-MM-DD")
          )
          .then((res) => {
            const newMeal = foodsIntoMealData(res);
            setData(newMeal);
          });
      }
      if (tab === HEALTH_TAB.ACTIVITY) {
        activityRepository
          .findAllByUserIdAndDateBetween(
            selectedUser.id,
            startDate.format("YYYY-MM-DD"),
            endDate.format("YYYY-MM-DD")
          )
          .then((res) => {
            const newActivity: ActivityColumn[] = res.map((activity) => {
              let time = 0;
              if (activity.endDate && activity.startDate) {
                const endDate = dayjs(activity.endDate);
                time = endDate.diff(activity.startDate, "minute");
              }
              return {
                date: formatDateToYYYYMMDD(new Date(activity.historyDate)),
                picture: activity.pictureUrl ?? "",
                name: activity.name,
                memo: activity.memo ?? "",
                time: `${time}분`,
                result:
                  activity.type !== ActivityType.NORMAL
                    ? activity.isCompleted
                      ? "O"
                      : "X"
                    : "O",
              };
            });
            setData(newActivity);
          });
      }
    }
  };

  return (
    <>
      <ModalBox width={1400}>
        <ModalTitle>건강정보</ModalTitle>
        <RowBox
          style={{ borderBottom: "1px solid #F5F5F5" }}
          justifyContent="flex-start"
          marginTop={0}
          height="auto"
        >
          <TabText
            onClick={() => onChangeTab(HEALTH_TAB.BODY)}
            active={tab === HEALTH_TAB.BODY}
          >
            신체
          </TabText>
          <TabText
            onClick={() => onChangeTab(HEALTH_TAB.MEAL)}
            active={tab === HEALTH_TAB.MEAL}
            style={{ marginLeft: "36px" }}
          >
            식단
          </TabText>
          <TabText
            onClick={() => onChangeTab(HEALTH_TAB.ACTIVITY)}
            active={tab === HEALTH_TAB.ACTIVITY}
            style={{ marginLeft: "36px" }}
          >
            활동
          </TabText>
        </RowBox>
        <RowBox justifyContent="flex-start" marginTop={16} height="auto">
          <DateCalendar
            value={startDate?.toString()}
            onChange={(value) => onChangeStartDate(dayjs(value))}
            label="시작 날짜"
          />
          <Text
            style={{
              marginLeft: "16px",
              marginRight: "16px",
              paddingTop: "10px",
            }}
            width="auto"
          >
            ~
          </Text>
          <DateCalendar
            value={endDate?.toString()}
            onChange={(value) => onChangeEndDate(dayjs(value))}
            label="마감 날짜"
          />
          <SelectionButton onClick={onSearch} style={{ marginLeft: "16px" }}>
            조회
          </SelectionButton>
        </RowBox>
        <SignUpTable columns={getColumn(tab)} rows={data} />
      </ModalBox>
    </>
  );
}

const SelectionButton = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #84c64a;
  color: #ffffff;
  font-size: 16px;
`;

function foodsIntoMealData(res: FoodEntity[]) {
  const newMeal: MealColumn[] = [];
  res.map((meal) => {
    const date = formatDateToYYYYMMDD(new Date(meal.historyDate));
    const index = newMeal.findIndex((meal) => meal.date === date);
    if (index === -1) {
      newMeal.push({
        date,
        breakfast: meal.category === FoodCategory.BREAKFAST ? meal : null,
        lunch: meal.category === FoodCategory.LUNCH ? meal : null,
        dinner: meal.category === FoodCategory.DINNER ? meal : null,
        snack: meal.category === FoodCategory.SNACK ? meal : null,
        water: meal.category === FoodCategory.WATER ? meal : null,
        nutrient: meal.category === FoodCategory.NUTRIENT ? meal : null,
      });
    } else {
      switch (meal.category) {
        case FoodCategory.BREAKFAST:
          newMeal[index].breakfast = meal;
          break;
        case FoodCategory.LUNCH:
          newMeal[index].lunch = meal;
          break;
        case FoodCategory.DINNER:
          newMeal[index].dinner = meal;
          break;
        case FoodCategory.SNACK:
          newMeal[index].snack = meal;
          break;
        case FoodCategory.WATER:
          newMeal[index].water = meal;
          break;
        case FoodCategory.NUTRIENT:
          newMeal[index].nutrient = meal;
          break;
        default:
          break;
      }
    }
  });
  return newMeal;
}
