import styled from "styled-components";

const TableBoard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 90%;
  margin-top: 20px;
  padding: 0px 2.5%;
  background-color: #ffffff;
  border-radius: 8px;

  overflow: hidden;
`;

export default TableBoard;
