import { ModalTitle } from "@component/common/ModalText";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import StandardInput from "@component/common/StandardInput";
import BannerImageInput, { BannerImage } from "./BannerImageInput";
import RowBox from "@component/common/RowBox";
import ButtonText from "@component/common/ButtonText";
import { BannerEntity } from "@entity/banner.entity";
import { useDictInput } from "@hook/useDictInput";
import { useState } from "react";

interface BannerModalProps {
  title: string;
  data?: BannerEntity | null;
  onCanceled: () => void;
  onSaved: (order: number, title: string, file?: any) => void;
}

export default function BannerModal({
  title,
  data,
  onCanceled,
  onSaved,
}: BannerModalProps) {
  const [banner, setBanner, onBannerChange] = useDictInput<
    BannerEntity | null | undefined
  >(data);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };
  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>{title}</ModalTitle>
        <ModalComponent
          marginBottom={32}
          description="배너 순번"
          component={
            <StandardInput
              name="order"
              value={banner ? banner.order : ""}
              onChange={onBannerChange}
            />
          }
        />
        <ModalComponent
          marginBottom={32}
          description="배너 관리용 제목"
          component={
            <StandardInput
              name="title"
              value={banner ? banner.title : ""}
              onChange={onBannerChange}
            />
          }
        />
        <ModalComponent
          marginBottom={32}
          description="이미지(335*170)"
          component={
            banner && banner.imageUrl ? (
              <BannerImage alt="배너 이미지" src={banner.imageUrl} />
            ) : (
              <BannerImageInput onImageChange={handleFileChange} />
            )
          }
        />
        <RowBox height="auto" marginTop={0}>
          <ButtonText
            onClick={onCanceled}
            color="#8D8D8D"
            backgroundColor="#F5F5F5"
          >
            취소
          </ButtonText>
          <ButtonText
            onClick={() => {
              if (banner && selectedFile) {
                onSaved(banner.order, banner.title, selectedFile);
              }
            }}
            color="#FFFFFF"
            backgroundColor="#84C64A"
          >
            저장
          </ButtonText>
        </RowBox>
      </ModalBox>
    </>
  );
}
