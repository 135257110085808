import { IChallengeProveCommentDeleteData } from "@interface/challenge-prove";
import { atom } from "recoil";

export enum ChallengeProveModal {
  PROVE_CHECK = "PROVE_CHECK",
  PROVE_MANAGE = "PROVE_MANAGE",
  PROVE_COMMENT_DELETE = "PROVE_COMMENT_DELETE",
}

export const challengeProveModal = atom<ChallengeProveModal | null>({
  key: "challengeProveModal",
  default: null,
});

export const selectedProve = atom<string | null>({
  key: "selectedProve",
  default: null,
});

export const challengeProveCommentDeleteData =
  atom<IChallengeProveCommentDeleteData | null>({
    key: "challengeProveCommentDeleteData",
    default: null,
  });
