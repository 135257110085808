import { FoodEntity } from "@entity/food.entity";
import { UserEntity } from "@entity/user.entity";
import { MemberData } from "@interface/user";
import { atom } from "recoil";

export const signUpUpdateModal = atom({
  key: "signUpUpdateModal",
  default: false,
});

export const signUpInfoModal = atom({
  key: "signUpInfoModal",
  default: false,
});

export const signUpHistoryModal = atom({
  key: "signUpHistoryModal",
  default: false,
});

export const signUpMessageModal = atom({
  key: "signUpMessageModal",
  default: false,
});

export const signUpTotalMessageModal = atom({
  key: "signUpTotalMessageModal",
  default: false,
});

export const signUpMemberSelectModal = atom({
  key: "signUpMemberSelectModal",
  default: false,
});

export const signUpOneMessageModal = atom({
  key: "signUpOneMessageModal",
  default: false,
});

export const signUpModifyPasswordModal = atom({
  key: "signUpModifyPasswordModal",
  default: false,
});

export const selectedUserData = atom<UserEntity | null>({
  key: "selectedUserData",
  default: null,
});

export const selectedUserListData = atom<MemberData[]>({
  key: "selectedUserListData",
  default: [],
});

export const userListData = atom<UserEntity[]>({
  key: "userListData",
  default: [],
});

export const selectedFoodData = atom<FoodEntity | null>({
  key: "selectedFoodData",
  default: null,
});
