import styled from "styled-components";

export const StandardImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  & + & {
    margin-left: 16px;
  }
`;

export const StandardVideo = styled.video`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  & + & {
    margin-left: 16px;
  }
`;