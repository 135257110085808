import challengeParticipationRepository from "@api/challenge/challenge-participation.repository";
import OptionBox from "@component/common/OptionBox";
import SearchBar from "@component/common/SearchBar";
import { useInput } from "@hook/useInput";
import useRecoilModal from "@hook/useRecoilModal";
import { challengeParticipantMessageModal } from "@store/atom/challenge-participant";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

export default function ChallengeParticipantOption() {
  const [search, setSearch, onChangeSearch] = useInput("");
  const { openModal } = useRecoilModal(challengeParticipantMessageModal);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const onClickSearch = () => {
    // userRepository.searchUserByKeyword(search).then((res) => {
    //   setUserList(res);
    //   setSearch("");
    // });
  };

  const onDownload = () => {
    if (id) {
      challengeParticipationRepository.downloadChallengeParticipations(id);
    }
  };
  return (
    <OptionBox>
      <SearchBar
        value={search}
        placeholder="참여자명을 입력해주세요."
        onChangeValue={onChangeSearch}
        onClickSearch={onClickSearch}
      />
      <SendMessageAndDataDownloadButton onClick={openModal}>
        메시지 보내기
      </SendMessageAndDataDownloadButton>
      <SendMessageAndDataDownloadButton onClick={onDownload}>
        데이터
        <br />
        다운로드
      </SendMessageAndDataDownloadButton>
    </OptionBox>
  );
}

const SendMessageAndDataDownloadButton = styled.button`
  width: 120px;
  height: 40px;

  background-color: #ffffff;
  border: 1px solid #84c64a;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  color: #84c64a;

  cursor: pointer;
  margin-left: 20px;
`;
