import styled from "styled-components";

const TabText = styled.p<{ active: boolean }>`
  font-size: 20px;
  font-weight: 450;
  color: ${(props) => (props.active ? "#323232" : "#A4A6B3")};
  cursor: pointer;
  padding-bottom: 8px;
  border-bottom: ${(props) =>
    props.active ? "2px solid #323232" : "2px solid #FFFFFF"};

  & + & {
    margin-left: 24px;
  }
`;

export default TabText;
