import { useLocation } from "react-router-dom";
import plus from "./plus-button.png";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { bannerOneData, bannerRegisterModal } from "@store/atom/banner";
import { postModal } from "@store/atom/post";

export default function PlusButton() {
  const { pathname } = useLocation();
  const setBanner = useSetRecoilState(bannerOneData);
  const [bannerModal, setBannerModal] = useRecoilState(bannerRegisterModal);
  const [post, setPost] = useRecoilState(postModal);
  return (
    <img
      alt="plus-button"
      src={plus}
      onClick={() => {
        if (pathname === "/banner") {
          setBannerModal(!bannerModal);
          setBanner(null);
        } else if (pathname === "/post") {
          setPost(!post);
        }
      }}
      style={{
        width: 60,
        height: 60,

        position: "absolute",
        bottom: "10%",
        right: "8%",
        cursor: "pointer",
      }}
    />
  );
}
