import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import PopperText from "@component/common/PopperText";
import { useRecoilState, useSetRecoilState } from "recoil";
import { postModal, postDeleteModal, postOneData } from "@store/atom/post";
import { PostValueProps } from "@interface/post";
import { usePopper } from "@hook/usePopper";

export default function PostPopper({ post }: PostValueProps) {
  const { anchorEl, setAnchorEl, open, id, handleClick } = usePopper();

  const setPost = useSetRecoilState(postOneData);
  const [postUpdate, setPostUpdate] = useRecoilState(postModal);
  const [postDelete, setPostDelete] = useRecoilState(postDeleteModal);

  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClick(event);
    if (open) {
      setPostUpdate(false);
      setPostDelete(false);
      setPost(null);
    }
  };

  const handlePostUpdateModal = () => {
    setPostUpdate(!postUpdate);
    setAnchorEl(null);
    setPost(post);
  };

  const handlePostDeleteModal = () => {
    setPostDelete(!postDelete);
    setAnchorEl(null);
    setPost(post);
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleModalClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <PopperText onClick={handlePostUpdateModal}>수정</PopperText>
        <PopperText onClick={handlePostDeleteModal}>삭제</PopperText>
      </Popper>
    </div>
  );
}
