import styled from "styled-components";
import { ModalDescription } from "./ModalText";

interface ModalInputProps {
  minHeight?: number | null;
  marginTop?: number | null;
  marginBottom?: number | null;
  description: string;
  component: React.ReactNode;
}

export default function ModalComponent({
  minHeight,
  marginTop,
  marginBottom,
  description,
  component,
}: ModalInputProps) {
  return (
    <ColumnBox
      minHeight={minHeight}
      marginTop={marginTop ?? 0}
      marginBottom={marginBottom ?? 0}
    >
      <ModalDescription>{description}</ModalDescription>
      <Box>{component}</Box>
    </ColumnBox>
  );
}

const ColumnBox = styled.div<{
  marginTop: number;
  marginBottom: number;
  minHeight?: number | null;
}>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow: hidden;

  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  min-height: ${(props) => (props.minHeight ? props.minHeight + "px" : "auto")};
`;

const Box = styled.div`
  width: 100%;
  min-height: 42px;
  overflow-y: hidden;
  overflow-x: auto;
`;
