import TableRow from "@component/common/TableRow";
import TableText from "@component/common/TableText";
import { QNA_INFO } from "@constant/QNA_INFO";
import { QNAValueProps } from "@interface/qna";
import QNAPopper from "./QNAPopper";
import { formatDateToYYYYMMDD } from "@util/date";
import { CommunityData } from "@entity/community.entity";

export default function QNAValue({ qna }: QNAValueProps) {
  return (
    <TableRow
      children={QNA_INFO.map(({ WIDTH }, index) => {
        return (
          <TableText width={WIDTH}>
            {String(QNATableContent(index, qna))}
          </TableText>
        );
      })}
      popper={<QNAPopper qna={qna} />}
    />
  );
}

function QNATableContent(
  index: number,
  { comments, title, username, memberCode, createdDate }: CommunityData
) {
  switch (index) {
    case 0:
      return comments.length > 0 ? "답변 완료" : "답변 대기";
    case 1:
      return title;
    case 2:
      return username;
    case 3:
      return Number(memberCode).toString().padStart(8, "0");
    case 4:
      return formatDateToYYYYMMDD(new Date(createdDate));
    default:
      return null;
  }
}
