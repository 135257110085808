import TableBody from "@component/common/TableBody";
import BannerValue from "./BannerValue";
import PlusButton from "@asset/icon/PlusButton";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  bannerDeleteModal,
  bannerListData,
  bannerOneData,
  bannerRegisterModal,
  bannerUpdateModal,
} from "@store/atom/banner";
import { useEffect } from "react";
import bannerRepository from "@api/banner/banner.repository";
import { BannerEntity } from "@entity/banner.entity";

export default function BannerContent() {
  const [bannerList, setBannerList] =
    useRecoilState<BannerEntity[]>(bannerListData);
  const bannerRegister = useRecoilValue(bannerRegisterModal);
  const bannerUpdate = useRecoilValue(bannerUpdateModal);
  const bannerDelete = useRecoilValue(bannerDeleteModal);

  const banner = useRecoilValue(bannerOneData);

  useEffect(() => {
    if (
      bannerList.length === 0 ||
      !banner ||
      !bannerRegister ||
      !bannerUpdate
    ) {
      bannerRepository.getBanner().then((res) => {
        setBannerList(res);
      });
    }
  }, [banner, bannerRegister, bannerUpdate, bannerDelete]);
  return (
    <>
      <TableBody>
        {bannerList.map((banner, i) => (
          <BannerValue key={i} banner={banner} />
        ))}
      </TableBody>
      <PlusButton />
    </>
  );
}
