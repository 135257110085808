import { KEY_ACCESS_TOKEN } from "@api/http";

class UserContextRepository {
  // 쿠키 설정 함수
  setCookie(name: string, value: string, days: number) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  // 쿠키 가져오는 함수
  getCookie(name: string) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // 쿠키 삭제 함수
  deleteCookie(name: string) {
    document.cookie = name + "=; Max-Age=-99999999;";
  }

  // 액세스 토큰 저장 (쿠키 사용)
  saveAccessToken(accessToken: string) {
    this.setCookie(KEY_ACCESS_TOKEN, accessToken, 30); // 30일 동안 쿠키 유지
  }

  // 액세스 토큰 가져오기 (쿠키 사용)
  getAccessToken() {
    return this.getCookie(KEY_ACCESS_TOKEN);
  }

  // 액세스 토큰 삭제 (쿠키 사용)
  removeAccessToken() {
    this.deleteCookie(KEY_ACCESS_TOKEN);
  }
}

export default new UserContextRepository();
