import ButtonText from "./ButtonText";
import styled from "styled-components";

interface MultiButtonProps {
  onSubClick: () => void;
  onMainClick: () => void;
  sub: string;
  main: string;
  marginTop: number | null;
}

export default function MultiButton({
  onMainClick,
  onSubClick,
  sub,
  main,
  marginTop,
}: MultiButtonProps) {
  return (
    <>
      <RowBox marginTop={marginTop ? marginTop : 32} height="150px">
        <ButtonText
          onClick={onSubClick}
          color="#8D8D8D"
          backgroundColor="#F5F5F5"
        >
          {sub}
        </ButtonText>
        <ButtonText
          onClick={onMainClick}
          color="#FFFFFF"
          backgroundColor="#84C64A"
        >
          {main}
        </ButtonText>
      </RowBox>
    </>
  );
}

const RowBox = styled.div<{
  justifyContent?: string | null;
  marginTop?: number | null;
  height?: string | null;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};

  width: 100%;
  min-height: 60px;
  height: ${(props) => (props.height ? props.height : 150 + "px")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  overflow-x: auto;
`;
