import httpClient from "@api/http";
import { FoodEntity } from "@entity/food.entity";

class FoodRepository {
  async findAllByUserId(userId: string): Promise<FoodEntity[]> {
    const response = await httpClient.get(`/food/admin?userId=${userId}`);
    return response.data;
  }

  async findAllByUserIdAndDateBetween(
    userId: string,
    startDate: string,
    endDate: string
  ): Promise<FoodEntity[]> {
    const response = await httpClient.get(
      `/food/admin/${userId}?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  }
}

export default new FoodRepository();
