import FilterAltIcon from "@mui/icons-material/FilterAlt";
import styled from "styled-components";

interface FilterButtonProps {
  onClickFilter: () => void;
}

export default function FilterButton({ onClickFilter }: FilterButtonProps) {
  return (
    <FilterButtonBox onClick={onClickFilter}>
      <FilterAltIcon sx={{ fontSize: 18, color: "#808080" }} />
      <FilterText>Filter</FilterText>
    </FilterButtonBox>
  );
}

const FilterButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  width: 100px;
  height: 40px;

  margin-left: 20px;
`;

const FilterText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-left: 5px;
`;
