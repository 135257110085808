import MenuContainer from "@component/menu/MenuContainer";
import Logo from "@asset/icon/Logo";
import MenuBox from "./MenuBox";
import { useLocation, useNavigate } from "react-router-dom";
import { MENU_TEXTS } from "@constant/MENU_TEXTS";

export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <MenuContainer>
      <Logo />
      {MENU_TEXTS.map(({ TITLE, PATH }, index) => (
        <MenuBox
          key={index}
          text={TITLE}
          isActive={location.pathname.includes(PATH)}
          onClick={() => navigate("/" + PATH)}
        />
      ))}
    </MenuContainer>
  );
}
