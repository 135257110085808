import ImageInput from "@component/common/ImageInput";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import ChallengeMissionComponent from "./ChallengeMissionComponent";
import MultiButton from "@component/common/MultiButton";
import RowBox from "@component/common/RowBox";
import StandardInput from "@component/common/StandardInput";
import StandardTextArea from "@component/common/StandardTextArea";
import useImageInput from "@hook/useImageInput";
import challengeRepository from "@api/challenge/challenge.repository";
import challengeMissionRepository from "@api/challenge/challenge-mission.repository";
import { ModalTitle } from "@component/common/ModalText";
import { StandardImage } from "@component/common/StandardImage";
import { useDictInput } from "@hook/useDictInput";
import { Box } from "@mui/material";
import {
  ChallengeModal,
  challengeInfoModal,
  selectedChallenge,
} from "@store/atom/challenge";
import { useRecoilState, useRecoilValue } from "recoil";
import { useState } from "react";
import {
  ChallengeAdminDto,
  ChallengeCreate,
  ChallengeMission,
  UpdateChallengeDto,
  UpdateChallengeMissionRequestDto,
} from "@interface/challenge";
import { formatDateToYYYYMMDDTHHMM } from "@util/date";
import { MissionType } from "@entity/challenge.entity";
import challengePictureRepository from "@api/challenge/challenge-picture.repository";

export default function ChallengeInfoModal() {
  const { images, setImages, fileInput, onFileUpload, handleFileChange } =
    useImageInput();
  const [deleteImages, setDeleteImages] = useState<string[]>([]);
  const [challengeInfo, setChallengeInfo] =
    useRecoilState<ChallengeModal | null>(challengeInfoModal);

  const challenge = useRecoilValue<ChallengeAdminDto | null>(selectedChallenge);
  const [missions, setMissions] = useState<ChallengeMission[]>(
    challenge?.missions
      .filter((mission) => mission.missionType === MissionType.MISSION)
      .map((mission) => {
        return {
          id: mission.id,
          title: mission.title,
          description: mission.description ?? "",
          startDate: formatDateToYYYYMMDDTHHMM(new Date(mission.startDate)),
          endDate: formatDateToYYYYMMDDTHHMM(new Date(mission.endDate)),
          missionType: MissionType[mission.missionType].toString(),
        };
      }) || []
  );

  const [deleteMissionsList, setDeleteMissionList] = useState<string[]>([]);

  const onMissionAdd = () => {
    const newMission = [...missions];
    newMission.push({
      id: null,
      title: "",
      description: "",
      startDate: "",
      endDate: "",
      missionType: MissionType.MISSION.toString(),
    });
    setMissions(newMission);
  };

  const onMissionDelete = (index: number) => {
    const newMission = [...missions];
    const deletes = newMission.splice(index, 1);
    if (deletes[0].id) {
      setDeleteMissionList([...deleteMissionsList, deletes[0].id]);
    }
    setMissions(newMission);
  };

  const onMissionChange = (
    index: number,
    key: keyof ChallengeMission,
    value: string
  ) => {
    const newMission = [...missions];
    newMission[index][key] = value;
    setMissions(newMission);
  };

  const [data, setData, onChange] = useDictInput({
    title: challenge?.challenge.title || "",
    paymentStartDate: challenge
      ? formatDateToYYYYMMDDTHHMM(
          new Date(challenge.challenge.paymentStartDate)
        )
      : "",
    paymentEndDate: challenge
      ? formatDateToYYYYMMDDTHHMM(new Date(challenge.challenge.paymentEndDate))
      : "",
    startDate: challenge
      ? formatDateToYYYYMMDDTHHMM(new Date(challenge.challenge.startDate))
      : "",
    endDate: challenge
      ? formatDateToYYYYMMDDTHHMM(new Date(challenge.challenge.endDate))
      : "",
    price: challenge?.challenge.price || "",
    pictures: challenge?.pictures || [],
    description: challenge?.challenge.description || "",
  });

  const [checkPeriod, setCheckPeriod, onChangeCheckPeriod] = useDictInput(
    getMissionCheckPeriods(challenge || null)
  );

  const onCreate = async () => {
    const basicMissions = changeCheckPeriodIntoMission(checkPeriod, null);
    if (basicMissions.length === 0) {
      return;
    }

    const additionalMissions = missions.map((mission) => {
      return {
        ...mission,
        startDate: new Date(mission.startDate),
        endDate: new Date(mission.endDate),
        missionType: MissionType.MISSION,
      };
    });

    const request: ChallengeCreate = {
      ...data,
      paymentStartDate: new Date(data.paymentStartDate),
      paymentEndDate: new Date(data.paymentEndDate),
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      price: Number(data.price),
      missions: [...additionalMissions, ...basicMissions],
    };

    if (!validateChallengeData(request)) {
      return;
    }

    const { challenge } = await challengeRepository.createAdmin(request);
    await Promise.all(
      images.map(async (image) => {
        const { url } = await challengePictureRepository.addChallengePicture(
          challenge.id,
          image.name
        );
        await challengePictureRepository.uploadChallengePicturePresignedUrl(
          url,
          image
        );
      })
    );
  };

  const onUpdate = async () => {
    if (challenge === null) return;

    const challengeId = challenge.challenge.id;

    const updateChallenge = new UpdateChallengeDto({
      ...data,
      id: challengeId,
      paymentStartDate: new Date(data.paymentStartDate),
      paymentEndDate: new Date(data.paymentEndDate),
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      price: Number(data.price),
    });

    await challengeRepository.updateAdmin(updateChallenge);

    const basicMissions = changeCheckPeriodIntoMission(
      checkPeriod,
      challenge
    ).filter(
      (mission) => mission.challengeId !== null
    ) as UpdateChallengeMissionRequestDto[];

    if (basicMissions.length === 0) {
      return;
    }

    const additionalMissions = missions.map((mission) => {
      return {
        ...mission,
        challengeId: challenge.challenge.id,
        startDate: new Date(mission.startDate),
        endDate: new Date(mission.endDate),
        missionType: MissionType.MISSION,
      };
    });

    await Promise.all(
      deleteMissionsList.map(async (id) => {
        return await challengeMissionRepository.deleteChallengeMission(id);
      })
    );

    await challengeMissionRepository.updateChallengeMission([
      ...basicMissions,
      ...additionalMissions,
    ]);

    if (images.length > 0) {
      await Promise.all(
        images.map(async (image) => {
          const { url } = await challengePictureRepository.addChallengePicture(
            challengeId,
            image.name
          );
          await challengePictureRepository.uploadChallengePicturePresignedUrl(
            url,
            image
          );
        })
      );
    }

    if (deleteImages.length > 0) {
      await challengePictureRepository.deleteChallengePictures(
        challengeId,
        deleteImages
      );
    }
  };

  return (
    <ModalBox width={1200} style={{ maxHeight: "90vh" }}>
      <ModalTitle>
        {challengeInfo === ChallengeModal.CREATE
          ? "챌린지 개설하기"
          : challengeInfo === ChallengeModal.UPDATE
          ? "챌린지 수정하기"
          : ""}
      </ModalTitle>
      <RowBox
        style={{
          width: "100%",
          height: "90vh",
          overflowY: "auto",
        }}
        height="auto"
        justifyContent="space-between"
      >
        <ColumnBox style={{ maxHeight: "100%" }}>
          <ModalComponent
            description="이름"
            component={
              <StandardInput
                placeholder="챌린지명"
                name="title"
                value={data.title}
                onChange={onChange}
              />
            }
          />
          <ModalComponent
            marginTop={20}
            description="결제 가능 기간"
            component={
              <RowBox height="auto" justifyContent="space-between">
                <StandardInput
                  type="datetime-local"
                  name="paymentStartDate"
                  value={data.paymentStartDate}
                  onChange={onChange}
                />
                <Box
                  sx={{
                    width: "12px",
                    margin: "8px",
                  }}
                >
                  ~
                </Box>
                <StandardInput
                  type="datetime-local"
                  name="paymentEndDate"
                  value={data.paymentEndDate}
                  onChange={onChange}
                />
              </RowBox>
            }
          />
          <ModalComponent
            marginTop={20}
            description="챌린지 진행 기간"
            component={
              <RowBox height="auto" justifyContent="space-between">
                <StandardInput
                  type="datetime-local"
                  name="startDate"
                  value={data.startDate}
                  onChange={onChange}
                />
                <Box
                  sx={{
                    width: "12px",
                    margin: "8px",
                  }}
                >
                  ~
                </Box>
                <StandardInput
                  type="datetime-local"
                  name="endDate"
                  value={data.endDate}
                  onChange={onChange}
                />
              </RowBox>
            }
          />
          <ModalComponent
            marginTop={20}
            description="가격 (원)"
            component={
              <StandardInput
                name="price"
                value={data.price}
                onChange={onChange}
              />
            }
          />
          <ModalComponent
            marginTop={20}
            description="사진 또는 영상"
            component={
              <RowBox justifyContent="flex-start">
                {data.pictures.map(({ key, url }, index) => {
                  return (
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "8px",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <button
                        onClick={() => {
                          setDeleteImages([...deleteImages, key]);
                          setData({
                            ...data,
                            pictures: data.pictures.filter(
                              (picture) => picture.key !== key
                            ),
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          backgroundColor: "white",
                          width: "50px",
                          height: "30px",
                          borderRadius: "8px",
                        }}
                      >
                        삭제
                      </button>
                      <StandardImage
                        onClick={() => {
                          var newTab = window.open();
                          if (newTab) {
                            newTab.document.body.innerHTML = `<img src="${url}" width="100%" />`;
                          }
                        }}
                        key={index}
                        src={url}
                        alt="image"
                      />
                    </div>
                  );
                })}
                {images.map((image, index) => {
                  const url = URL.createObjectURL(image);
                  return (
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        marginLeft: "16px",
                        borderRadius: "8px",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <button
                        onClick={() => {
                          setImages(images.filter((_, i) => i !== index));
                        }}
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          backgroundColor: "white",
                          width: "50px",
                          height: "30px",
                          borderRadius: "8px",
                        }}
                      >
                        삭제
                      </button>
                      <StandardImage key={index} src={url} alt="image" />{" "}
                    </div>
                  );
                })}
                <ImageInput
                  marginLeft={
                    data.pictures.length > 0 || images.length > 0 ? 16 : 0
                  }
                  fileInput={fileInput}
                  onFileUpload={onFileUpload}
                  handleChange={handleFileChange}
                />
              </RowBox>
            }
          />
          <ModalComponent
            marginTop={20}
            description="내용"
            component={
              <StandardTextArea
                placeholder="내용을 입력해주세요."
                height={120}
                name="description"
                value={data.description}
                onChange={(e) => {
                  setData({
                    ...data,
                    description: e.target.value,
                  });
                }}
              />
            }
          />
        </ColumnBox>
        <ColumnBox style={{ overflowY: "auto" }}>
          <ModalComponent
            minHeight={60}
            description="최초 점검 기간"
            component={
              <RowBox height="auto" justifyContent="space-between">
                <StandardInput
                  type="datetime-local"
                  name="firstCheckStartDate"
                  value={checkPeriod.firstCheckStartDate}
                  onChange={onChangeCheckPeriod}
                />
                <Box
                  sx={{
                    width: "12px",
                    margin: "8px",
                  }}
                >
                  ~
                </Box>
                <StandardInput
                  type="datetime-local"
                  name="firstCheckEndDate"
                  value={checkPeriod.firstCheckEndDate}
                  onChange={onChangeCheckPeriod}
                />
              </RowBox>
            }
          />
          <ModalComponent
            minHeight={60}
            marginTop={20}
            description="중간 점검 기간 (1차 제출)"
            component={
              <RowBox height="auto" justifyContent="space-between">
                <StandardInput
                  type="datetime-local"
                  name="middleFirstCheckStartDate"
                  value={checkPeriod.middleFirstCheckStartDate}
                  onChange={onChangeCheckPeriod}
                />
                <Box
                  sx={{
                    width: "12px",
                    margin: "8px",
                  }}
                >
                  ~
                </Box>
                <StandardInput
                  type="datetime-local"
                  name="middleFirstCheckEndDate"
                  value={checkPeriod.middleFirstCheckEndDate}
                  onChange={onChangeCheckPeriod}
                />
              </RowBox>
            }
          />
          <ModalComponent
            minHeight={60}
            marginTop={20}
            description="중간 점검 기간 (2차 지연 제출)"
            component={
              <RowBox height="auto" justifyContent="space-between">
                <StandardInput
                  type="datetime-local"
                  name="middleSecondCheckStartDate"
                  value={checkPeriod.middleSecondCheckStartDate}
                  onChange={onChangeCheckPeriod}
                />
                <Box
                  sx={{
                    width: "12px",
                    margin: "8px",
                  }}
                >
                  ~
                </Box>
                <StandardInput
                  type="datetime-local"
                  name="middleSecondCheckEndDate"
                  value={checkPeriod.middleSecondCheckEndDate}
                  onChange={onChangeCheckPeriod}
                />
              </RowBox>
            }
          />
          <ModalComponent
            minHeight={60}
            marginTop={20}
            description="최종 점검 기간 (1차 제출)"
            component={
              <RowBox height="auto" justifyContent="space-between">
                <StandardInput
                  type="datetime-local"
                  name="finalFirstCheckStartDate"
                  value={checkPeriod.finalFirstCheckStartDate}
                  onChange={onChangeCheckPeriod}
                />
                <Box
                  sx={{
                    width: "12px",
                    margin: "8px",
                  }}
                >
                  ~
                </Box>
                <StandardInput
                  type="datetime-local"
                  name="finalFirstCheckEndDate"
                  value={checkPeriod.finalFirstCheckEndDate}
                  onChange={onChangeCheckPeriod}
                />
              </RowBox>
            }
          />
          <ModalComponent
            minHeight={60}
            marginTop={20}
            description="최종 점검 기간 (2차 지연 제출)"
            component={
              <RowBox height="auto" justifyContent="space-between">
                <StandardInput
                  type="datetime-local"
                  name="finalSecondCheckStartDate"
                  value={checkPeriod.finalSecondCheckStartDate}
                  onChange={onChangeCheckPeriod}
                />
                <Box
                  sx={{
                    width: "12px",
                    margin: "8px",
                  }}
                >
                  ~
                </Box>
                <StandardInput
                  type="datetime-local"
                  name="finalSecondCheckEndDate"
                  value={checkPeriod.finalSecondCheckEndDate}
                  onChange={onChangeCheckPeriod}
                />
              </RowBox>
            }
          />
          <RowBox
            style={{ minHeight: "18px" }}
            marginTop={20}
            height="auto"
            justifyContent="flex-start"
          >
            <SemiTitle>세부 미션 설정하기</SemiTitle>
            <AddIcon
              onClick={onMissionAdd}
              sx={{ color: "#323232", cursor: "pointer", fontSize: "18px" }}
            />
          </RowBox>
          {missions.map((mission, index) => {
            return (
              <ChallengeMissionComponent
                key={index}
                index={index}
                mission={mission}
                onMissionDelete={onMissionDelete}
                onMissionChange={onMissionChange}
              />
            );
          })}
        </ColumnBox>
      </RowBox>
      <MultiButton
        sub="취소"
        main="저장"
        marginTop={32}
        onSubClick={() => setChallengeInfo(null)}
        onMainClick={() => {
          if (challengeInfo === ChallengeModal.CREATE) {
            onCreate();
            setChallengeInfo(null);
          } else if (challengeInfo === ChallengeModal.UPDATE) {
            try {
              onUpdate().then(() => {
                setChallengeInfo(null);
              });
            } catch {
              setChallengeInfo(null);
            }
          } else {
            setChallengeInfo(null);
          }
        }}
      />
    </ModalBox>
  );
}

function changeCheckPeriodIntoMission(
  checkPeriod: {
    firstCheckStartDate: string;
    firstCheckEndDate: string;
    middleFirstCheckStartDate: string;
    middleFirstCheckEndDate: string;
    middleSecondCheckStartDate: string;
    middleSecondCheckEndDate: string;
    finalFirstCheckStartDate: string;
    finalFirstCheckEndDate: string;
    finalSecondCheckStartDate: string;
    finalSecondCheckEndDate: string;
  },
  challenge?: ChallengeAdminDto | null
) {
  const missions = [];
  const {
    firstCheckStartDate,
    firstCheckEndDate,
    middleFirstCheckStartDate,
    middleFirstCheckEndDate,
    middleSecondCheckStartDate,
    middleSecondCheckEndDate,
    finalFirstCheckStartDate,
    finalFirstCheckEndDate,
    finalSecondCheckStartDate,
    finalSecondCheckEndDate,
  } = checkPeriod;

  if (
    !firstCheckStartDate ||
    !firstCheckEndDate ||
    !middleFirstCheckStartDate ||
    !middleFirstCheckEndDate ||
    !middleSecondCheckStartDate ||
    !middleSecondCheckEndDate ||
    !finalFirstCheckStartDate ||
    !finalFirstCheckEndDate ||
    !finalSecondCheckStartDate ||
    !finalSecondCheckEndDate
  ) {
    alert("점검 기간을 모두 입력해주세요.");
    return [];
  }

  if (firstCheckStartDate && firstCheckEndDate) {
    missions.push({
      id: findMissionId(challenge || null, MissionType.CHECK_FIRST),
      challengeId: challenge?.challenge.id || null,
      title: "최초 점검",
      description: "최초 점검",
      startDate: new Date(firstCheckStartDate),
      endDate: new Date(firstCheckEndDate),
      missionType: MissionType.CHECK_FIRST,
    });
  }
  if (middleFirstCheckStartDate && middleFirstCheckEndDate) {
    missions.push({
      id: findMissionId(challenge || null, MissionType.CHECK_MIDDLE_1),
      challengeId: challenge?.challenge.id || null,
      title: "중간 점검 (1차 제출)",
      description: "중간 점검 (1차 제출)",
      startDate: new Date(middleFirstCheckStartDate),
      endDate: new Date(middleFirstCheckEndDate),
      missionType: MissionType.CHECK_MIDDLE_1,
    });
  }
  if (middleSecondCheckStartDate && middleSecondCheckEndDate) {
    missions.push({
      id: findMissionId(challenge || null, MissionType.CHECK_MIDDLE_2),
      challengeId: challenge?.challenge.id || null,
      title: "중간 점검 (2차 지연 제출)",
      description: "중간 점검 (2차 지연 제출)",
      startDate: new Date(middleSecondCheckStartDate),
      endDate: new Date(middleSecondCheckEndDate),
      missionType: MissionType.CHECK_MIDDLE_2,
    });
  }
  if (finalFirstCheckStartDate && finalFirstCheckEndDate) {
    missions.push({
      id: findMissionId(challenge || null, MissionType.CHECK_LAST_1),
      challengeId: challenge?.challenge.id || null,
      title: "최종 점검 (1차 제출)",
      description: "최종 점검 (1차 제출)",
      startDate: new Date(finalFirstCheckStartDate),
      endDate: new Date(finalFirstCheckEndDate),
      missionType: MissionType.CHECK_LAST_1,
    });
  }
  if (finalSecondCheckStartDate && finalSecondCheckEndDate) {
    missions.push({
      id: findMissionId(challenge || null, MissionType.CHECK_LAST_2),
      challengeId: challenge?.challenge.id || null,
      title: "최종 점검 (2차 지연 제출)",
      description: "최종 점검 (2차 지연 제출)",
      startDate: new Date(finalSecondCheckStartDate),
      endDate: new Date(finalSecondCheckEndDate),
      missionType: MissionType.CHECK_LAST_2,
    });
  }
  return missions;
}

function findMissionId(challenge: ChallengeAdminDto | null, type: MissionType) {
  if (!challenge) return null;

  const { missions } = challenge;
  const mission = missions.find((mission) => mission.missionType === type);
  return mission?.id || null;
}

function getMissionCheckPeriods(challenge: ChallengeAdminDto | null): {
  firstCheckStartDate: string;
  firstCheckEndDate: string;
  middleFirstCheckStartDate: string;
  middleFirstCheckEndDate: string;
  middleSecondCheckStartDate: string;
  middleSecondCheckEndDate: string;
  finalFirstCheckStartDate: string;
  finalFirstCheckEndDate: string;
  finalSecondCheckStartDate: string;
  finalSecondCheckEndDate: string;
} {
  if (!challenge) {
    return {
      firstCheckStartDate: "",
      firstCheckEndDate: "",
      middleFirstCheckStartDate: "",
      middleFirstCheckEndDate: "",
      middleSecondCheckStartDate: "",
      middleSecondCheckEndDate: "",
      finalFirstCheckStartDate: "",
      finalFirstCheckEndDate: "",
      finalSecondCheckStartDate: "",
      finalSecondCheckEndDate: "",
    };
  }

  const { missions } = challenge;
  const data = {
    firstCheckStartDate: "",
    firstCheckEndDate: "",
    middleFirstCheckStartDate: "",
    middleFirstCheckEndDate: "",
    middleSecondCheckStartDate: "",
    middleSecondCheckEndDate: "",
    finalFirstCheckStartDate: "",
    finalFirstCheckEndDate: "",
    finalSecondCheckStartDate: "",
    finalSecondCheckEndDate: "",
  };
  missions.forEach((mission) => {
    const { startDate, endDate, missionType } = mission;
    const start = formatDateToYYYYMMDDTHHMM(new Date(startDate));
    const end = formatDateToYYYYMMDDTHHMM(new Date(endDate));
    switch (missionType) {
      case MissionType.CHECK_FIRST:
        data.firstCheckStartDate = start;
        data.firstCheckEndDate = end;
        break;
      case MissionType.CHECK_MIDDLE_1:
        data.middleFirstCheckStartDate = start;
        data.middleFirstCheckEndDate = end;
        break;
      case MissionType.CHECK_MIDDLE_2:
        data.middleSecondCheckStartDate = start;
        data.middleSecondCheckEndDate = end;
        break;
      case MissionType.CHECK_LAST_1:
        data.finalFirstCheckStartDate = start;
        data.finalFirstCheckEndDate = end;
        break;
      case MissionType.CHECK_LAST_2:
        data.finalSecondCheckStartDate = start;
        data.finalSecondCheckEndDate = end;
        break;
    }
  });
  return data;
}

function validateChallengeData(data: ChallengeCreate) {
  const {
    title,
    paymentStartDate,
    paymentEndDate,
    startDate,
    endDate,
    price,
    description,
  } = data;
  if (!title) {
    alert("챌린지 이름을 입력해주세요.");
    return false;
  }
  if (!paymentStartDate) {
    alert("결제 가능 기간을 입력해주세요.");
    return false;
  }
  if (!paymentEndDate) {
    alert("결제 가능 기간을 입력해주세요.");
    return false;
  }
  if (!startDate) {
    alert("챌린지 시작 기간을 입력해주세요.");
    return false;
  }
  if (!endDate) {
    alert("챌린지 종료 기간을 입력해주세요.");
    return false;
  }
  if (!price) {
    alert("가격을 입력해주세요.");
    return false;
  }
  if (!description) {
    alert("내용을 입력해주세요.");
    return false;
  }
  return true;
}

const ColumnBox = styled.div`
  width: 48%;
  height: 750px;
  max-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const SemiTitle = styled.p`
  font-size: 16px;
  color: #323232;

  overflow: hidden;
`;
