import { RecoilState, useRecoilState } from "recoil";

interface UseRecoilModal {
  modal: boolean;
  handleModal: () => void;
  openModal: () => void;
  closeModal: () => void;
}

export default function useRecoilModal(
  recoilAtom: RecoilState<boolean>
): UseRecoilModal {
  const [modal, setModal] = useRecoilState(recoilAtom);
  const handleModal = () => {
    setModal(!modal);
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };
  return {
    modal,
    handleModal,
    openModal,
    closeModal,
  };
}
