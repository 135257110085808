import challengeMissionRepository from "@api/challenge/challenge-mission.repository";
import ButtonText from "@component/common/ButtonText";
import ModalBox from "@component/common/ModalBox";
import { ModalTitle } from "@component/common/ModalText";
import RowBox from "@component/common/RowBox";
import { ChallengeProveStatus } from "@entity/challenge.entity";
import { MissionComponentData } from "@interface/challenge-participant";
import {
  challengeParticipantModal,
  selectedChallengeParticipant,
} from "@store/atom/challenge-participant";
import { formatDateToYYYYMMDDHHMM } from "@util/date";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";

export function ChallengeParticipantMissionModal() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const challengeId = searchParams.get("id");
  const [participant, setParticipant] = useRecoilState(
    selectedChallengeParticipant
  );
  const setChallengeParticipantMissionModal = useSetRecoilState(
    challengeParticipantModal
  );
  const closeModal = () => {
    setChallengeParticipantMissionModal(null);
  };

  const [missions, setMissions] = useState<MissionComponentData[]>([]);
  useEffect(() => {
    if (!participant || !challengeId) return;

    challengeMissionRepository
      .findMissionStatusByChallengeIdAndUserId(challengeId, participant.id)
      .then((res) => {
        setMissions(res);
      });
  }, []);
  return (
    <ModalBox width={480}>
      <ModalTitle>상세 미션 참여 내역</ModalTitle>
      <RowBox marginTop={8} height="auto" justifyContent="space-between">
        <ColumnTitle>미션 제목</ColumnTitle>
        <ColumnTitle>진행 상태</ColumnTitle>
      </RowBox>
      {missions.map((mission, index) => {
        return <MissionContent key={index} {...mission} />;
      })}
      <RowBox height="auto">
        <ButtonText
          style={{ marginTop: "32px" }}
          onClick={closeModal}
          color="#FFFFFF"
          backgroundColor="#84C64A"
        >
          확인
        </ButtonText>
      </RowBox>
    </ModalBox>
  );
}

function MissionContent({
  startDate,
  endDate,
  title,
  status,
}: MissionComponentData) {
  let start = new Date(startDate);
  let end = new Date(endDate);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",

        width: "100%",
        height: "auto",

        paddingTop: "16px",
        paddingBottom: "16px",
        borderBottom: "1px solid #F5F5F5",
      }}
    >
      <ContentDate style={{ marginBottom: "12px" }}>
        {formatDateToYYYYMMDDHHMM(start, "-")} ~{" "}
        {formatDateToYYYYMMDDHHMM(end, "-")}
      </ContentDate>
      <RowBox height="auto" justifyContent="space-between">
        <ContentText>{title}</ContentText>
        <ContentText>{decideProveStatus(status)}</ContentText>
      </RowBox>
    </div>
  );
}

function decideProveStatus(status: ChallengeProveStatus) {
  switch (status) {
    case ChallengeProveStatus.NOT_STARTED:
      return "진행 전";
    case ChallengeProveStatus.PENDING:
      return "승인 대기";
    case ChallengeProveStatus.REJECTED:
      return "반려";
    case ChallengeProveStatus.SUCCESS:
      return "성공";
    case ChallengeProveStatus.FAIL:
      return "실패";
  }
}

const ColumnTitle = styled.div`
  font-size: 18px;
  font-weight: medium;
  color: #323232;
`;

const ContentText = styled.p`
  font-size: 14px;
  color: #323232;
`;

const ContentDate = styled.p`
  font-size: 12px;
  color: #666666;
`;
