import { useNavigate } from "react-router-dom";

export default function Logo() {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/");
  }
  return (
    <img
      onClick={onClick}
      alt="logo"
      src={require("./logo.png")}
      style={{
        width: "150px",
        height: "auto",
        cursor: "pointer",
        marginLeft: 32,
        marginBottom: 20,
      }}
    />
  );
}
