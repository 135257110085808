export interface UserEntity {
  id: string;
  email: string;
  password: string;
  name: string;
  nickname: string;
  birthDate?: string | null;
  phoneNumber: string;
  address: string;
  detailAddress: string;
  profileImage?: string;
  height?: number;
  gender: GenderType;
  role: Role;
  memberCode: number;
  coach: string | null;
  reference: string | null;
  createdDate: Date;
  updatedDate: Date;
}

export enum GenderType {
  NONE = "NONE",
  MAN = "MAN",
  WOMAN = "WOMAN",
}

export enum Role {
  USER_BANNED = "USER_BANNED",
  USER_UNAPPROVED = "USER_UNAPPROVED",
  USER = "USER",
  USER_APPROVED = "USER_APPROVED",
  COACH = "COACH",
  ADMIN = "ADMIN",
}

export class UpdateUserAdminDto {
  constructor(data: UpdateUserAdminDto) {
    this.id = data.id;
    this.name = data.name;
    this.gender = data.gender;
    this.role = data.role;
    this.height = data.height;
  }

  id: string;
  name: string;
  gender: GenderType;
  role: Role;
  height: number;
}
