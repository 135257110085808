import ButtonText from "@component/common/ButtonText";
import ModalBox from "@component/common/ModalBox";
import ModalComponent from "@component/common/ModalComponent";
import { ModalTitle } from "@component/common/ModalText";
import RowBox from "@component/common/RowBox";
import StandardInput from "@component/common/StandardInput";
import StandardTextArea from "@component/common/StandardTextArea";
import { postModal, postOneData } from "@store/atom/post";
import { useRecoilState, useSetRecoilState } from "recoil";
import ImageInput from "@component/common/ImageInput";
import useImageInput from "@hook/useImageInput";
import { StandardImage } from "@component/common/StandardImage";
import { SelectChangeEvent } from "@mui/material";
import Dropdown from "@component/common/Dropdown";
import { CommunityData, CommunityType } from "@entity/community.entity";
import { useDictInput } from "@hook/useDictInput";
import communityRepository from "@api/community/community.repository";

export default function PostModal({ title }: { title: string }) {
  const { images, fileInput, onFileUpload, handleFileChange } = useImageInput();
  const [post, setPost] = useRecoilState<CommunityData | null>(postOneData);
  const setPostModal = useSetRecoilState(postModal);

  const [data, setData, onChangeData] = useDictInput({
    id: post ? post.id : "",
    username: post ? post.username : "",
    title: post ? post.title : "",
    content: post ? post.content : "",
    communityType: post ? post.communityType : "",
    likeCount: post ? post.likeCount : 0,
    scrapCount: post ? post.scrapCount : 0,
    pictures: post ? post.pictures : [],
    createdDate: post ? post.createdDate : "",
  });

  const onCanceled = () => {
    setPostModal(false);
    setPost(null);
  };

  const onSaved = async () => {
    if (
      data.communityType.length > 0 &&
      data.title.length > 0 &&
      data.content.length > 0
    ) {
      if (!post) {
        await communityRepository.createCommunity({
          ...data,
          communityType: data.communityType as CommunityType,
          pictures: images,
        });
      } else {
        await communityRepository.updateCommunityById(data.id, {
          ...data,
          communityType: data.communityType as CommunityType,
          pictures: images,
        });
      }
    }
    setPostModal(false);
    setPost(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setData({
      ...data,
      communityType: event.target.value as string,
    });
  };
  return (
    <>
      <ModalBox width={480}>
        <ModalTitle>{post ? title : "글 생성하기"}</ModalTitle>
        <ModalComponent
          marginBottom={32}
          description="게시판명"
          component={
            <Dropdown
              key={data.communityType}
              options={Object.keys(CommunityType)}
              value={data.communityType}
              onChange={handleChange}
            />
          }
        />
        <ModalComponent
          marginBottom={32}
          description="제목"
          component={
            <StandardInput
              name="title"
              value={data.title}
              onChange={onChangeData}
            />
          }
        />
        <ModalComponent
          marginBottom={32}
          description="내용"
          component={
            <StandardTextArea
              name="content"
              value={data.content}
              onChange={(e) => {
                setData({
                  ...data,
                  content: e.target.value,
                });
              }}
              height={120}
            />
          }
        />
        <ModalComponent
          marginBottom={32}
          description="이미지"
          component={
            <RowBox justifyContent="flex-start">
              {data.pictures.map((image, index) => {
                return <StandardImage key={index} src={image} alt="image" />;
              })}
              {images.map((image, index) => {
                const url = URL.createObjectURL(image);
                return <StandardImage key={index} src={url} alt="image" />;
              })}
              <ImageInput
                marginLeft={
                  data.pictures.length > 0 || images.length > 0 ? 16 : 0
                }
                fileInput={fileInput}
                onFileUpload={onFileUpload}
                handleChange={handleFileChange}
              />
            </RowBox>
          }
        />
        <RowBox height="auto">
          <ButtonText
            onClick={onCanceled}
            color="#8D8D8D"
            backgroundColor="#F5F5F5"
          >
            취소
          </ButtonText>
          <ButtonText
            onClick={onSaved}
            color="#FFFFFF"
            backgroundColor="#84C64A"
          >
            저장
          </ButtonText>
        </RowBox>
      </ModalBox>
    </>
  );
}
