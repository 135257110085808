import TableColumn from "@component/common/TableColumn";
import TableHeader from "@component/common/TableHeader";
import { CHALLENGE_PROVE_INFO } from "@constant/CHALLENGE_PROVE_INFO";

export default function ChallengeProveColumn() {
  return (
    <TableHeader>
      {CHALLENGE_PROVE_INFO.map(({ COLUMN, WIDTH }, index) => {
        return (
          <TableColumn key={index} width={WIDTH}>
            {COLUMN}
          </TableColumn>
        );
      })}
    </TableHeader>
  );
}
