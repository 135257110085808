import styled from "styled-components";

const StandardTextArea = styled.textarea<{ height?: number | null }>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : 40)}px;
  padding: 8px 12px;

  border: 1px solid #e5e5e5;
  border-radius: 4px;

  font-size: 12px;
  color: #323232;
  outline: none;
`;

export default StandardTextArea;
