import styled from "styled-components";

interface BannerImageInputProps {
  onImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function BannerImageInput({
  onImageChange,
}: BannerImageInputProps) {
  return (
    <>
      <ImageInputBox>
        <label htmlFor="file">사진첨부</label>
        <div>+</div>
        <input
          onChange={onImageChange}
          name="file"
          className="hidden"
          type="file"
          accept="image/*"
        />
      </ImageInputBox>
    </>
  );
}

const ImageInputBox = styled.form`
  width: 100%;
  height: 100px;
  padding: 0 12px;

  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 100px;
  padding: 0 12px;

  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
`;
