import { ChallengeParticipationAdminDto } from "@interface/challenge-participant";
import { atom } from "recoil";

export enum ChallengeParticipantModal {
  MEMBER = "MEMBER",
  MISSION = "MISSION",
}

export const challengeParticipantModal = atom<ChallengeParticipantModal | null>(
  {
    key: "challengeParticipantModal",
    default: null,
  }
);

export const selectedChallengeParticipant =
  atom<ChallengeParticipationAdminDto | null>({
    key: "selectedChallengeParticipant",
    default: null,
  });

export const challengeParticipantMessageModal = atom<boolean>({
  key: "challengeParticipantMessageModal",
  default: false,
});
